<template>
  <a-modal
    v-model="modelVisible"
    :title="title"
    @onCancel="handleCancel"
    @ok="handleChange"
    dialogClass="batch_advertiser_modal"
  >
    <div class="add_advertiser_wrapper">
      <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout" v-if="isEdit === 1">
        <a-form-model-item label="应用" prop="advertiserName">
          <span>{{ query.appName }}</span>
        </a-form-model-item>
        <a-form-model-item label="广告位" prop="advertiserName">
          <span>{{ query.placeName }}</span>
        </a-form-model-item>
        <a-form-model-item label="广告平台" prop="advertiserName">
          <span>{{ query.platName }}</span>
        </a-form-model-item>
        <a-form-model-item label="账号名称" prop="advertiserName">
          <span>{{ query.accountName }}</span>
        </a-form-model-item>
        <a-form-model-item label="应用ID" prop="advertiserName">
          <span>{{ query.platAppId }}</span>
        </a-form-model-item>
        <a-form-model-item label="APPKey" prop="appKey" v-if="showAppKey(query.platId)">
          <a-input v-model.trim="query.appKey" placeholder="请输入APPKey" />
        </a-form-model-item>
        <!-- accessType ===2 自定义 不可修改 -->
        <a-form-model-item
          :label="getLabel(query.platId)"
          prop="platPlaceId"
          :rules="[{ required: true, message: '请输入' + getLabel(query.platId), trigger: 'blur' }]"
        >
          <a-input
            :disabled="query.accessType === '2'"
            v-model.trim="query.platPlaceId"
            :placeholder="`请输入` + getLabel(query.platId)"
          />
        </a-form-model-item>
        <a-form-model-item
          v-if="query.accessType === '2'"
          label="参数"
          prop="ext"
          :rules="[{ required: true, validator: extValidator, trigger: 'blur' }]"
        >
          <a-input type="textarea" placeholder="参数必须符合JSON格式" v-model.trim="query.ext" />
        </a-form-model-item>
        <a-form-model-item label="广告版位ID" prop="slotId" v-if="query.platId === 11">
          <a-input v-model.trim="query.slotId" placeholder="请输入广告版位ID" />
        </a-form-model-item>
        <a-form-model-item label="广告源名称" prop="name">
          <a-input v-model.trim="query.name" placeholder="请输入广告源名称" />
        </a-form-model-item>
        <a-form-model-item label="竞价类型" prop="advertiserName">
          <span>{{ getAdvertiserName(query.settleType) }}</span>
        </a-form-model-item>
        <a-form-model-item label="自动价格" prop="isAutoSorting">
          <a-switch :checked="isAutoSorting === '1'" @change="handleCheckSts(isAutoSorting)" />
        </a-form-model-item>
        <a-form-model-item label="是否兜底" prop="isBottom">
          <span>{{ query.isBottom === 0 ? '否' : '是' }}</span>
        </a-form-model-item>
      </a-form-model>
      <div class="batchModel" v-if="isEdit === 2">
        <a-icon class="icon" type="exclamation-circle" />
        <p>此操作将删除该广告源，确认删除？</p>
      </div>
      <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout" v-if="isEdit === 3">
        <a-form-model-item label="应用" prop="advertiserName">
          <span>{{ query.name }}</span>
        </a-form-model-item>
        <a-form-model-item label="广告平台" prop="advertiserName">
          <span>{{ query.platName }}</span>
        </a-form-model-item>
        <a-form-model-item label="账号名称" prop="advertiserName">
          <span v-if="query.platAccountName">{{ query.platAccountName }}</span>
          <span v-else>{{ query.accountName }}</span>
        </a-form-model-item>
        <a-form-model-item
          label="应用ID"
          prop="platAppId"
          :rules="[{ required: true, message: '请输入应用ID', trigger: 'blur' }]"
        >
          <a-input v-model.trim="query.platAppId" placeholder="请输入应用ID" />
        </a-form-model-item>
        <div class="batchModel" style="padding: 20px 50px">
          <a-icon class="icon" type="exclamation-circle" />
          <p>注意：修改应用ID后，该应用下所有和该广告平台账户相关的广告源配置都将修改。</p>
        </div>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { getUpdateAdsource, updatePlatAppId, getDeleteAdsource } from '@/api/advertisement'
import { mapState } from 'vuex'
import { showAppKey } from '@/utils/autoLabel'
export default {
  data () {
    return {
      loading: false,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      query: {
        isAutoSorting: '0'
      },
      rules: {
        appKey: [{ required: true, message: '请输入APPKey', trigger: 'blur' }],
        slotId: [{ required: true, message: '请输入广告位ID', trigger: 'blur' }],
        name: [{ required: true, message: '请输入广告源名称', trigger: 'blur' }]
      },
      title: '',
      isAutoSorting: '',
      oldPlatAppId: ''
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Number,
      default: 0
    },
    childSourceInfo: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    childSourceInfo: {
      handler (val) {
        this.query = JSON.parse(JSON.stringify(val))
        this.isAutoSorting = this.query.isAutoSorting
        this.oldPlatAppId = this.query.platAppId
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    const list = {
      1: '编辑广告源',
      2: '提示',
      3: '编辑应用ID'
    }
    this.title = list[this.isEdit]
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token
    }),
    modelVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    showAppKey,
    // 参数JSON的校验函数
    extValidator (rule, value, callback) {
      if (!value) {
        return callback(new Error('请输入参数'))
      } else {
        try {
          if (JSON.parse(value)) return callback()
        } catch (error) {
          return callback(new Error('请输入正确的JSON格式'))
        }
      }
    },
    getLabel (data) {
      return data === 7 || data === 11 ? '广告单元ID' : '广告位ID'
    },
    // 竞价类型
    getAdvertiserName (record) {
      const list = {
        0: '固价',
        1: '服务端竞价',
        2: '客户端竞价'
      }
      return list[record]
    },
    // 点击开关
    handleCheckSts (record) {
      this.isAutoSorting = record === '1' ? '0' : '1'
      this.query.isAutoSorting = this.isAutoSorting
    },
    // 取消弹窗
    handleCancel () {
      this.modelVisible = false
    },
    handleChange () {
      if (this.isEdit === 1) {
        this.handleSubmit()
      } else if (this.isEdit === 2) {
        this.handleDe()
      } else {
        this.handleEdit()
      }
    },
    // 修改应用ID
    async handleEdit () {
      const { appId = '', platId = '', platAppId = '', platAccountId = '' } = this.query
      const res = await updatePlatAppId({
        appId,
        platId,
        platAccountId,
        oldPlatAppId: this.oldPlatAppId,
        platAppId
      })
      if (res.code === 200) {
        // this.query = {}
        this.$message.success('修改成功!')
        this.$emit('changeEdits')
        this.handleCancel()
      }
    },
    // 删除确定
    async handleDe () {
      const res = await getDeleteAdsource({
        id: this.query.id
      })
      if (res.code === 200) {
        this.query = {}
        this.$emit('changeEdit')
        this.handleCancel()
      }
    },
    // 点击确定
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        // 编辑
        if (valid) {
          const {
            id = '',
            name = '',
            platPlaceId = '',
            isAutoSorting = '',
            appKey = '',
            platId = '',
            ext = ''
          } = this.query
          const res = await getUpdateAdsource({
            id,
            name,
            platId,
            platPlaceId,
            isAutoSorting,
            ext,
            appKey: showAppKey(platId) ? appKey : undefined
          })
          if (res.code === 200) {
            this.query = {}
            this.$emit('changeEdit')
            this.handleCancel()
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.batch_advertiser_modal {
  .ant-modal-body {
    .add_advertiser_wrapper {
      max-height: 60vh;
      overflow-y: auto;
      padding: 20px;
      background-color: #fff;
      border-radius: 5px;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        background: fade(@primary-color, 20%);
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
      .batchModel {
        padding: 30px;
        display: flex;
        align-items: center;
      }
      .icon {
        color: #fa8b15;
        font-size: 22px;
        margin-right: 20px;
      }
      p {
        margin-bottom: 0;
        line-height: 24px;
      }
    }
  }
}
</style>
