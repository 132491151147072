export const stsOptions = [
  {
    label: '所有未删除',
    value: '4'
  },
  {
    label: '启用中',
    value: '0'
  },
  {
    label: '暂停中',
    value: '1'
  },
  {
    label: '到达计划日限额',
    value: '2'
  },
  {
    label: '已删除',
    value: '3'
  }
]
export const stsColumns = {
  title: '开关',
  key: 'sts',
  scopedSlots: { customRender: 'sts' },
  dataIndex: 'sts',
  checked: false,
  fixed: true,
  width: 80
}
export const actiColumns = {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: true,
  checked: false,
  scopedSlots: { customRender: 'action' },
  width: 120
}
export const adPlanNameColumns = {
  title: '广告计划名称',
  key: 'adPlanName',
  width: 220,
  dataIndex: 'adPlanName',
  fixed: true,
  checked: false,
  scopedSlots: { customRender: 'adPlanName' }
}
export const actionColumns = [
  {
    title: '广告计划名称',
    width: 220,
    dataIndex: 'adPlanName',
    scopedSlots: { customRender: 'adPlanName' },
    key: 'adPlanName',
    titles: 'adPlanNames',
    checked: false,
    fixed: true
  },
  {
    title: '开关',
    key: 'sts',
    scopedSlots: { customRender: 'sts' },
    dataIndex: 'sts',
    checked: false,
    fixed: true,
    width: 80
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    fixed: true,
    checked: false,
    scopedSlots: { customRender: 'action' },
    width: 120
  }
]
export const columns = [
  {
    title: '状态',
    key: 'stsDesc',
    dataIndex: 'stsDesc',
    scopedSlots: { customRender: 'stsDesc' },
    checked: false,
    width: 130
  },
  {
    width: 150,
    key: 'budget',
    title: '限额(元/天)',
    checked: false,
    sorter: true,
    slots: { title: 'budgetTitle' },
    scopedSlots: { customRender: 'budget' },
    dataIndex: 'budget'
  },
  {
    width: 150,
    title: '曝光量',
    key: 'impress',
    checked: false,
    sorter: true,
    disabled: true,
    dataIndex: 'impress'
  },
  {
    title: '点击量',
    width: 150,
    key: 'click',
    checked: false,
    sorter: true,
    summary: true,
    dataIndex: 'click'
  },
  {
    title: '点击率',
    width: 150,
    key: 'clickRate',
    checked: false,
    sorter: true,
    dataIndex: 'clickRate',
    customRender: (text, record, index) => {
      return record.clickRate + '%'
    }
  },
  {
    width: 200,
    key: 'ecpm',
    title: '千元展示均价(元)',
    slots: { title: 'ecpmTitle' },
    checked: false,
    sorter: true,
    dataIndex: 'ecpm'
  },
  {
    width: 150,
    key: 'ecpc',
    title: '点击均价(元)',
    checked: false,
    sorter: true,
    slots: { title: 'ecpcTitle' },
    dataIndex: 'ecpc'
  },
  {
    title: '花费',
    width: 150,
    key: 'income',
    checked: true,
    sorter: true,
    dataIndex: 'income'
  }
]

export const arrAttributes = [
  {
    title: '广告计划名称',
    width: 220,
    dataIndex: 'adPlanName',
    checked: false,
    key: 'adPlanName',
    scopedSlots: { customRender: 'adPlanName' },
    isChecked: false,
    disabled: true,
    titles: 'adPlanNames',
    fixed: true
  },
  {
    title: '状态',
    key: 'stsDesc',
    checked: false,
    dataIndex: 'stsDesc',
    scopedSlots: { customRender: 'stsDesc' },
    isChecked: false,
    width: 130
  },
  {
    width: 150,
    title: '限额(元/天)',
    key: 'budget',
    isChecked: false,
    checked: false,
    sorter: true,
    slots: { title: 'budgetTitle' },
    scopedSlots: { customRender: 'budget' },
    dataIndex: 'budget'
  },
  {
    width: 150,
    title: '曝光量',
    key: 'impress',
    checked: false,
    sorter: true,
    disabled: true,
    dataIndex: 'impress'
  },
  {
    title: '点击量',
    width: 150,
    key: 'click',
    checked: false,
    sorter: true,
    summary: true,
    dataIndex: 'click'
  },
  {
    title: '点击率',
    width: 150,
    key: 'clickRate',
    checked: false,
    sorter: true,
    dataIndex: 'clickRate',
    customRender: (text, record, index) => {
      return record.clickRate + '%'
    }
  },
  {
    slots: { title: 'ecpmTitle' },
    width: 200,
    title: '千元展示均价(元)',
    key: 'ecpm',
    checked: true,
    sorter: true,
    dataIndex: 'ecpm'
  },
  {
    width: 150,
    key: 'ecpc',
    checked: false,
    sorter: true,
    title: '点击均价(元)',
    slots: { title: 'ecpcTitle' },
    dataIndex: 'ecpc'
  },
  {
    title: '花费',
    width: 150,
    key: 'income',
    checked: false,
    sorter: true,
    dataIndex: 'income'
  }
]

export const basicAttributes = [
  {
    title: '广告计划名称',
    width: 220,
    dataIndex: 'adPlanName',
    checked: false,
    key: 'adPlanName',
    scopedSlots: { customRender: 'adPlanName' },
    disabled: true,
    titles: 'adPlanNames',
    fixed: true
  },
  {
    title: '状态',
    key: 'stsDesc',
    dataIndex: 'stsDesc',
    scopedSlots: { customRender: 'stsDesc' },
    checked: false,
    width: 130
  },
  {
    width: 150,
    key: 'budget',
    title: '限额(元/天)',
    checked: false,
    sorter: true,
    slots: { title: 'budgetTitle' },
    scopedSlots: { customRender: 'budget' },
    dataIndex: 'budget'
  }
]
export const disAttributes = [
  {
    width: 150,
    title: '曝光量',
    key: 'impress',
    checked: false,
    sorter: true,
    disabled: true,
    dataIndex: 'impress'
  },
  {
    title: '点击量',
    width: 150,
    key: 'click',
    checked: false,
    sorter: true,
    summary: true,
    dataIndex: 'click'
  },
  {
    title: '点击率',
    width: 150,
    key: 'clickRate',
    checked: false,
    sorter: true,
    dataIndex: 'clickRate',
    customRender: (text, record, index) => {
      return record.clickRate + '%'
    }
  },
  {
    width: 200,
    key: 'ecpm',
    title: '千元展示均价(元)',
    slots: { title: 'ecpmTitle' },
    checked: true,
    sorter: true,
    dataIndex: 'ecpm'
  },
  {
    width: 150,
    key: 'ecpc',
    checked: false,
    title: '点击均价(元)',
    slots: { title: 'ecpcTitle' },
    sorter: true,
    dataIndex: 'ecpc'
  },
  {
    title: '花费',
    width: 150,
    key: 'income',
    checked: false,
    sorter: true,
    dataIndex: 'income'
  }
]
