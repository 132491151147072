<template>
  <div class="ad-creative-form-container" style="--ad--creative--width: 550px">
    <div class="ad-creative-label">
      <span class="weight_label" style="padding-top: 0; padding-bottom: 0">{{ isAdd ? '新增' : '编辑' }}广告</span>
    </div>
    <div class="form_item">
      <span class="form_item_label">广告名称</span>
      <div>
        <a-form-model ref="nameform" :model="nameform" v-bind="layout">
          <a-form-model-item prop="name" :rules="[{ required: true, message: '请输入广告名称', trigger: 'blur' }]">
            <a-input
              v-model.trim="nameform.name"
              placeholder="请输入广告名称"
              style="width: calc(var(--ad--creative--width) + 20px)"
            ></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
    <div class="form_item">
      <span class="form_item_label">展现形式</span>
      <div>
        <a-radio @click="changePosition(1)" :checked="position === 1"> 开屏 </a-radio>
        <a-radio @click="changePosition(3)" :checked="position === 3"> 插屏 </a-radio>
        <a-radio @click="changePosition(5)" :checked="position === 5"> 激励视频 </a-radio>
        <a-radio @click="changePosition(9)" :checked="position === 9"> 信息流 </a-radio>
        <a-radio @click="changePosition(2)" :checked="position === 2"> 横幅 </a-radio>
      </div>
    </div>
    <div class="form_item" style="margin-bottom: 0">
      <span class="form_item_label">创意类型</span>
      <div class="creative_type_radio">
        <!-- 激励视频不显示 -->
        <a-radio @click="changeCreativeType(1)" :checked="creativetype === 1" v-show="position !== 5">
          <div class="creative_type_radio_item" :class="{ active: creativetype === 1 }">
            <span
            >图片类型（{{
              adcreativeform.adPlanCreativeList.filter((item) => item.creativityType === 1).length
            }}）</span
            >
          </div>
        </a-radio>
        <!-- 横幅不显示 -->
        <a-radio @click="changeCreativeType(2)" :checked="creativetype === 2" v-show="position !== 2">
          <div class="creative_type_radio_item" :class="{ active: creativetype === 2 }">
            <span
            >视频类型（{{
              adcreativeform.adPlanCreativeList.filter((item) => item.creativityType === 2).length
            }}）</span
            >
          </div>
        </a-radio>
      </div>
    </div>
    <div class="form_item">
      <span
        class="form_item_label"
        style="color: #999"
      >创意个数 {{ adcreativeform.adPlanCreativeList.length }}/10</span
      >
      <div class="tabs_wrapper">
        <div class="tabs_header">
          <a-tabs size="small" v-model="activeKey">
            <template v-for="(item, index) in adcreativeform.adPlanCreativeList">
              <a-tab-pane v-if="item.creativityType === creativetype" :key="index">
                <div slot="tab" class="tab_pane_item">
                  <span class="name">创意{{ index + 1 }}</span>
                  <span class="close" @click.stop="deleteCreative(index)"><a-icon type="close" /></span>
                </div>
              </a-tab-pane>
            </template>
          </a-tabs>
          <div class="add">
            <span
              @click="addNewCreative"
            ><a-icon type="plus-circle" theme="filled" style="margin-right: 5px" />添加创意（{{
              adcreativeform.adPlanCreativeList.length
            }}/10）</span
            >
          </div>
        </div>
        <a-form-model :key="formKey" ref="adcreativeform" :model="adcreativeform" v-bind="layout">
          <div
            class="creative_item_wrapper"
            v-for="(item, index) in adcreativeform.adPlanCreativeList"
            :key="index"
            v-show="activeKey === index"
          >
            <div class="form_item_label form_item_label_list">
              <span>创意名称</span>
            </div>
            <a-form-model-item
              :prop="`adPlanCreativeList[${index}].name`"
              :rules="[{ required: true, message: '请输入创意名称', trigger: 'blur' }]"
            >
              <a-input
                style="width: var(--ad--creative--width)"
                v-model.trim="item.name"
                placeholder="请输入创意名称"
                allowClear
              ></a-input>
            </a-form-model-item>
            <!-- 头像 -->
            <!-- 开屏，横幅不显示 -->
            <div v-if="![1, 2].includes(item.positionId)">
              <div class="form_item_label form_item_label_list">
                <span>头像</span>
              </div>
              <div v-if="item.iconUrl" class="el_image_box">
                <el-image
                  style="height: 200px; max-width: var(--ad--creative--width); padding: 1px 0"
                  :src="adplanResourceUrl + item.iconUrl"
                  :preview-src-list="[adplanResourceUrl + item.iconUrl]"
                >
                </el-image>
                <div class="modal">
                  <div class="wrapper">
                    <!-- 2 头像 1 图片 -->
                    <a-icon class="deleteIcon" type="delete" @click="() => handleCloseImg(2, index)" />
                    <a-icon
                      class="deleteIcon"
                      type="eye"
                      @click="() => handlePreviewImg(adplanResourceUrl + item.iconUrl, 1)"
                    />
                  </div>
                </div>
              </div>
              <a-form-model-item
                :prop="`adPlanCreativeList[${index}].iconUrl`"
                :rules="[
                  {
                    required: true,
                    validator: (rule, value, callback) => {
                      iconUrlValidator(rule, value, callback, index)
                    },
                    trigger: ['change']
                  }
                ]"
              >
                <a-upload
                  v-if="!item.iconUrl"
                  name="file"
                  class="uploader"
                  list-type="picture-card"
                  style="width: var(--ad--creative--width); height: 200px"
                  :action="uploadUrl"
                  :show-upload-list="false"
                  :before-upload="
                    (e) => {
                      beforeIconUpload(e, index)
                    }
                  "
                  :customRequest="customRequest"
                  :data="{ type: 2, index }"
                >
                  <span class="ant-upload-text"><a-icon :type="iconloading ? 'loading' : 'plus'" />Upload</span>
                </a-upload>
                <!-- <a-input v-model.trim="item.iconUrl"></a-input> -->
              </a-form-model-item>
              <div class="upload_grey_text">图片：1:1，jpg,jpeg,png，小于100KB</div>
            </div>
            <!-- 图片 -->
            <!-- 除激励视频，其他的样式视频类型都不显示 -->
            <div v-if="item.creativityType === 1 || item.positionId === 5">
              <div class="form_item_label form_item_label_list">
                <span>图片</span>
              </div>
              <div v-if="item.imageUrl" class="el_image_box">
                <el-image
                  style="height: 200px; max-width: var(--ad--creative--width); padding: 1px 0"
                  :src="adplanResourceUrl + item.imageUrl"
                  :preview-src-list="[adplanResourceUrl + item.imageUrl]"
                >
                </el-image>
                <div class="modal">
                  <div class="wrapper">
                    <!-- 2 头像 1 图片 -->
                    <a-icon class="deleteIcon" type="delete" @click="() => handleCloseImg(1, index)" />
                    <a-icon
                      class="deleteIcon"
                      type="eye"
                      @click="() => handlePreviewImg(adplanResourceUrl + item.imageUrl, 1)"
                    />
                  </div>
                </div>
              </div>
              <a-form-model-item
                :prop="`adPlanCreativeList[${index}].imageUrl`"
                :rules="[
                  {
                    required: true,
                    validator: (rule, value, callback) => {
                      imageUrlValidator(rule, value, callback, index)
                    },
                    trigger: ['change']
                  }
                ]"
              >
                <a-upload
                  v-if="!item.imageUrl"
                  name="file"
                  class="uploader"
                  list-type="picture-card"
                  style="width: var(--ad--creative--width); height: 200px"
                  :action="uploadUrl"
                  :show-upload-list="false"
                  :before-upload="beforeImageUpload"
                  :customRequest="customRequest"
                  :data="{ type: 1, index }"
                >
                  <span class="ant-upload-text"><a-icon :type="imgloading ? 'loading' : 'plus'" />Upload</span>
                </a-upload>
              </a-form-model-item>
              <div class="upload_grey_text">
                {{
                  item.positionId !== 2
                    ? '图片：16:9或9:16，jpg,jpeg,png，小于300KB'
                    : '图片：640x100px，jpg,jpeg,png，小于300KB'
                }}
              </div>
            </div>
            <!-- 视频 -->
            <!-- 只有视频类型才显示 -->
            <div v-if="item.creativityType === 2">
              <div class="form_item_label form_item_label_list">
                <span>视频</span>
              </div>
              <div v-if="item.videoUrl" class="el_image_box">
                <video
                  style="height: 200px; max-width: var(--ad--creative--width); padding: 1px 0"
                  :src="adplanResourceUrl + item.videoUrl"
                ></video>
                <div class="modal">
                  <div class="wrapper">
                    <a-icon class="deleteIcon" type="delete" @click.stop="item.videoUrl = ''" />
                    <a-icon
                      class="deleteIcon"
                      type="eye"
                      @click.stop="() => handlePreviewImg(adplanResourceUrl + item.videoUrl, 2)"
                    />
                  </div>
                </div>
              </div>
              <a-form-model-item
                :prop="`adPlanCreativeList[${index}].imageUrl`"
                :rules="[
                  {
                    required: true,
                    validator: (rule, value, callback) => {
                      videoUrlValidator(rule, value, callback, index)
                    },
                    trigger: ['change']
                  }
                ]"
              >
                <a-upload
                  v-if="!item.videoUrl"
                  name="file"
                  list-type="picture-card"
                  class="uploader"
                  style="width: var(--ad--creative--width); height: 200px"
                  :action="uploadUrl"
                  :show-upload-list="false"
                  :before-upload="beforeVideoUpload"
                  :customRequest="customRequest"
                  :data="{ type: 3, index }"
                >
                  <div>
                    <a-icon :type="videoloading ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">Upload</div>
                  </div>
                </a-upload>
              </a-form-model-item>
              <div class="upload_grey_text">
                {{
                  item.positionId === 1
                    ? '视频：建议上传720*1280px，mp4，小于51200KB'
                    : item.positionId === 3
                      ? '视频：建议上传720*1280px，mp4，小于51200KB'
                      : item.positionId === 5
                        ? '视频：建议上传720*1280px，mp4，小于10M'
                        : item.positionId === 9
                          ? '视频：建议上传1280*720px，mp4，小于51200KB'
                          : '--'
                }}
              </div>
            </div>
            <!-- 创意标题 -->
            <!-- 开屏，横幅不显示 -->
            <div v-if="![1, 2].includes(item.positionId)">
              <div class="form_item_label form_item_label_list">
                <span>创意标题</span>
              </div>
              <a-form-model-item
                :prop="`adPlanCreativeList[${index}].title`"
                :rules="[{ required: true, message: '请输入创意标题', trigger: 'blur' }]"
              >
                <a-input
                  style="width: var(--ad--creative--width)"
                  v-model.trim="item.title"
                  placeholder="请输入创意标题"
                  allowClear
                ></a-input>
              </a-form-model-item>
            </div>
            <!-- 创意描述 -->
            <!-- 开屏，横幅不显示 -->
            <div v-if="![1, 2].includes(item.positionId)">
              <div class="form_item_label form_item_label_list">
                <span>创意描述</span>
              </div>
              <a-form-model-item
                :prop="`adPlanCreativeList[${index}].description`"
                :rules="[{ required: true, message: '请输入创意描述', trigger: 'blur' }]"
              >
                <a-input
                  style="width: var(--ad--creative--width)"
                  v-model.trim="item.description"
                  placeholder="请输入创意描述"
                  allowClear
                ></a-input>
              </a-form-model-item>
            </div>
            <!-- 按钮文案 -->
            <!-- 开屏，横幅不显示 -->
            <div v-if="![1, 2].includes(item.positionId)">
              <div class="form_item_label form_item_label_list">
                <span>按钮文案</span>
              </div>
              <a-form-model-item
                :prop="`adPlanCreativeList[${index}].buttonText`"
                :rules="[{ required: true, message: '请输入按钮文案', trigger: 'blur' }]"
              >
                <a-input
                  style="width: var(--ad--creative--width)"
                  v-model.trim="item.buttonText"
                  placeholder="请输入按钮文案"
                  allowClear
                ></a-input>
              </a-form-model-item>
            </div>
          </div>
        </a-form-model>
        <div
          class="tab_empty"
          v-if="adcreativeform.adPlanCreativeList.filter((item) => item.creativityType === creativetype).length === 0"
        >
          <span
            @click="addNewCreative"
          ><a-icon type="plus-circle" theme="filled" style="margin-right: 5px" />添加创意</span
          >
        </div>
      </div>
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img v-if="previewType === 1" alt="example" style="width: 100%; max-height: 600px" :src="previewImage" />
      <video v-else :src="previewImage" controls style="width: 100%; max-height: 600px"></video>
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { uploadFile } from '@/api/advertisement'
import { addAdCreative, getAdByAdId, updateAdCreative } from '@/api/advertisementStep'
export default {
  inject: ['currentAdPlanId', 'changePlanId', 'currentAdGroupId', 'changeGroupId', 'changeLoading'],
  data () {
    return {
      layout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 }
      },
      steps: 1,
      isAdd: false,
      position: 1,
      formKey: new Date().getTime(),
      creativetype: 1,
      adcreativeform: {
        adPlanCreativeList: []
      },
      nameform: {
        name: undefined
      },
      activeKey: 0,
      // 图片上传及预览
      uploadUrl: process.env.VUE_APP_API_BASE_URL + '/adPlan/uploadFile', // 素材上传地址
      previewImage: '', // 预览地址
      previewVisible: false, // 预览弹窗
      previewType: 1, // 预览素材类型 1 图片素材 2 视频素材
      imgloading: false, // 图片loading
      iconloading: false, // 头像loading
      videoloading: false, // 视频loading
      // 编辑
      sts: undefined,
      editAdId: undefined,
      adGroupId: undefined
    }
  },
  watch: {
    $route: {
      async handler (val) {
        this.changeLoading(true)
        this.steps = val && val.query && +val.query.steps
        this.isAdd = val.name === 'addadsteps'
        if (!this.isAdd) {
          this.editAdId = val && val.query && val.query.adId
          await this.initAdCreativeInfo()
        }
        this.$nextTick(() => {
          this.changeLoading(false)
        })
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token
    }),
    ...mapGetters(['adplanResourceUrl'])
  },
  mounted () {},
  methods: {
    deleteCreative (i) {
      this.adcreativeform.adPlanCreativeList.splice(i, 1)
      if (this.activeKey === i || i < this.activeKey) {
        this.activeKey = this.adcreativeform.adPlanCreativeList.findIndex(
          (item) => item.creativityType === this.creativetype
        )
      }
    },
    changePosition (i) {
      if (this.position === i) {
        return
      }
      if (this.adcreativeform.adPlanCreativeList.length > 0) {
        const _this = this
        this.$confirm({
          title: '提示',
          content: '切换展示形式将会清空当前所有创意?',
          onOk () {
            _this.adcreativeform.adPlanCreativeList = []
            _this.position = i
            if (i === 5) {
              _this.creativetype = 2
            } else {
              _this.creativetype = 1
            }
            // 激励视频
          },
          onCancel () {}
        })
      } else {
        this.position = i
        if (i === 5) {
          this.creativetype = 2
        } else {
          this.creativetype = 1
        }
      }
    },
    // 校验表单并自动跳转至未填表单
    ListValdate () {
      let valid = true
      let index = 0
      this.adcreativeform.adPlanCreativeList.forEach((item, c) => {
        // 图片
        if (item.creativityType === 1) {
          if (
            // 开屏
            item.positionId === 1 &&
            !(item.name && item.imageUrl)
          ) {
            valid = false
            index = c
          } else if (
            // 插屏
            item.positionId === 3 &&
            !(item.name && item.iconUrl && item.imageUrl && item.title && item.description && item.buttonText)
          ) {
            valid = false
            index = c
          } else if (
            // 信息流
            item.positionId === 9 &&
            !(item.name && item.iconUrl && item.imageUrl && item.title && item.description && item.buttonText)
          ) {
            valid = false
            index = c
          } else if (
            // 横幅
            item.positionId === 2 &&
            !(item.name && item.imageUrl)
          ) {
            valid = false
            index = c
          }
        } else if (item.creativityType === 2) {
          // 视频
          if (
            // 开屏
            item.positionId === 1 &&
            !(item.name && item.videoUrl)
          ) {
            valid = false
            index = c
          } else if (
            // 插屏
            item.positionId === 3 &&
            !(item.name && item.iconUrl && item.videoUrl && item.title && item.description && item.buttonText)
          ) {
            valid = false
            index = c
          } else if (
            // 激励视频
            item.positionId === 5 &&
            !(
              item.name &&
              item.iconUrl &&
              item.imageUrl &&
              item.videoUrl &&
              item.title &&
              item.description &&
              item.buttonText
            )
          ) {
            valid = false
            index = c
          } else if (
            // 信息流
            item.positionId === 9 &&
            !(item.name && item.iconUrl && item.videoUrl && item.title && item.description && item.buttonText)
          ) {
            valid = false
            index = c
          }
        }
      })
      if (!valid) {
        this.$message.warning('请完成当前创意详情填写！')
        this.activeKey = index
        this.$nextTick(() => {
          this.$refs.adcreativeform.validate(async (valid, msg) => {})
        })
      }
      return {
        valid,
        index
      }
    },
    changeCreativeType (i) {
      if (this.creativetype === i) {
        return
      }
      const { valid } = this.ListValdate()
      if (valid) {
        this.creativetype = i
        this.activeKey = this.adcreativeform.adPlanCreativeList.findIndex((item) => item.creativityType === i)
      }
    },
    addNewCreative () {
      if (this.adcreativeform.adPlanCreativeList.length === 10) {
        return this.$message.error('创意数量已达上限！')
      }
      this.$set(this.adcreativeform.adPlanCreativeList, this.adcreativeform.adPlanCreativeList.length, {
        creativityType: this.creativetype,
        positionId: this.position,
        name: undefined,
        title: undefined,
        description: undefined,
        buttonText: undefined,
        imageUrl: undefined,
        imageWidth: undefined,
        imageHeight: undefined,
        imageSize: undefined,
        iconUrl: undefined,
        iconWidth: undefined,
        iconHeight: undefined,
        iconSize: undefined,
        videoUrl: undefined,
        videoWidth: undefined,
        videoHeight: undefined,
        videoSize: undefined,
        videoDuration: undefined,
        sts: '0'
      })
      this.activeKey = this.adcreativeform.adPlanCreativeList.length - 1
    },
    // type 1 图片 2 头像
    handleCloseImg (type, index) {
      if (type === 1) {
        this.adcreativeform.adPlanCreativeList[index].imageUrl = undefined
      } else if (type === 2) {
        this.adcreativeform.adPlanCreativeList[index].iconUrl = undefined
      }
      this.formKey = new Date().getTime()
    },
    // type 1 图片素材 2 视频素材
    handlePreviewImg (src, type) {
      this.previewImage = src
      this.previewVisible = true
      this.previewType = type
    },
    // 取消预览
    handleCancel () {
      this.previewVisible = false
    },
    // 自定义上传方法
    customRequest (options) {
      const { filename, file, data, onSuccess } = options
      // 定义上传的文件
      const formData = new FormData()
      formData.append(filename, file)
      formData.append('type', data.type)
      this.uploadFileFn(data, formData)
      onSuccess()
    },
    // 上传文件
    async uploadFileFn (querytData, formData) {
      const { type, index } = querytData
      if (type === 1) {
        this.imgloading = true
      } else if (type === 2) {
        this.iconloading = true
      } else if (type === 3) {
        this.videoloading = true
      }
      const res = await uploadFile(formData)
      const tempData = res.data
      if (res.code === 200 && res.data) {
        if (type === 1) {
          this.adcreativeform.adPlanCreativeList[index].imageUrl = tempData.fileUrl
          this.adcreativeform.adPlanCreativeList[index].imageWidth = tempData.width
          this.adcreativeform.adPlanCreativeList[index].imageHeight = tempData.height
          this.adcreativeform.adPlanCreativeList[index].imageSize = tempData.kbSize
        } else if (type === 2) {
          this.adcreativeform.adPlanCreativeList[index].iconUrl = tempData.fileUrl
          this.adcreativeform.adPlanCreativeList[index].iconWidth = tempData.width
          this.adcreativeform.adPlanCreativeList[index].iconHeight = tempData.height
          this.adcreativeform.adPlanCreativeList[index].iconSize = tempData.kbSize
        } else if (type === 3) {
          this.adcreativeform.adPlanCreativeList[index].videoUrl = tempData.fileUrl
          this.adcreativeform.adPlanCreativeList[index].videoWidth = tempData.width
          this.adcreativeform.adPlanCreativeList[index].videoHeight = tempData.height
          this.adcreativeform.adPlanCreativeList[index].videoSize = tempData.kbSize
          this.adcreativeform.adPlanCreativeList[index].videoDuration = tempData.duration
        }
      }
      this.imgloading = false
      this.iconloading = false
      this.videoloading = false
      this.formKey = new Date().getTime()
    },
    // 图片上传
    // 图片上传---before
    beforeImageUpload (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
      if (!isJpgOrPng) {
        this.$message.error('请选择上传 JPG JPEG PNG 格式的图片!')
      }
      const isLt300 = file.size / 1024 < 301
      if (!isLt300) {
        this.$message.error('文件大小不能超过300kb!')
      }
      return isJpgOrPng && isLt300
    },
    // 图片上传
    // 图片上传---必填校验
    imageUrlValidator (rule, value, callback, index) {
      if (this.imgloading) {
        return true
      } else if (this.adcreativeform.adPlanCreativeList[index].imageUrl) {
        return true
      } else {
        return callback(new Error('请上传图片'))
      }
    },
    // 头像上传
    // 头像上传---before
    beforeIconUpload (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
      if (!isJpgOrPng) {
        this.$message.error('请选择上传 JPG JPEG PNG 格式的图片!')
      }
      const isLt200 = file.size / 1024 < 201
      if (!isLt200) {
        this.$message.error('文件大小不能超过200kb!')
      }
      return isJpgOrPng && isLt200
    },
    // 头像上传
    // 头像上传---头像必填校验
    iconUrlValidator (rule, value, callback, index) {
      if (this.iconloading) {
        return true
      } else if (value) {
        return true
      } else {
        return callback(new Error('请上传头像'))
      }
    },
    // 视频上传
    // 视频上传---视频必填校验
    videoUrlValidator (rule, value, callback, index) {
      if (this.videoloading) {
        return true
      } else if (value) {
        return true
      } else {
        return callback(new Error('请上传视频'))
      }
    },
    beforeVideoUpload (file) {},
    handleSubmitAdCreative () {
      return new Promise(async (resolve) => {
        if (this.adcreativeform.adPlanCreativeList.length === 0) {
          this.$message.warning('至少创建一个广告创意！')
          return
        }
        const { valid } = this.ListValdate()
        if (valid) {
          const query = this.getsubmitQuery()
          this.$refs.nameform.validate(async (v, msg) => {
            if (v) {
              if (this.isAdd) {
                const resp = await addAdCreative(query)
                this.changeLoading(true)
                resolve(resp)
                setTimeout(() => {
                  this.changeLoading(false)
                }, 1000)
              } else {
                this.changeLoading(true)
                const resp = await updateAdCreative(query)
                resolve(resp)
                setTimeout(() => {
                  this.changeLoading(false)
                }, 1000)
              }
            }
          })
        }
      })
    },
    getsubmitQuery () {
      const query = {
        name: this.nameform.name,
        sts: !this.isAdd ? this.sts : '0',
        id: !this.isAdd ? this.editAdId : undefined,
        adGroupId: this.isAdd ? this.currentAdGroupId() : this.adGroupId,
        adPlanCreativeList: this.adcreativeform.adPlanCreativeList
      }
      return query
    },
    async initAdCreativeInfo () {
      const resp = await getAdByAdId({ id: this.editAdId })
      const { ad, adPlanCreativeList } = resp.data
      this.nameform.name = ad.name
      this.adGroupId = ad.adGroupId
      this.creativetype = adPlanCreativeList[0] ? adPlanCreativeList[0].creativityType : 1
      this.position = adPlanCreativeList[0] ? adPlanCreativeList[0].positionId : 1
      adPlanCreativeList.forEach((item, i) => {
        this.$set(this.adcreativeform.adPlanCreativeList, i, item)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ad-creative-form-container {
  .ad-creative-label {
    margin: 10px 0 20px 0;
    .weight_label {
      font-size: 16px;
      color: #333;
      font-weight: 600;
      vertical-align: top;
      margin-right: 10px;
      padding: 0 15px;
    }
  }
  .form_item {
    padding-left: 15px;
    margin-bottom: 20px;
    .form_item_label {
      margin-bottom: 10px;
      display: inline-block;
      &.form_item_label_list {
        margin: 10px 0;
      }
    }
  }

  .creative_type_radio {
    margin-top: -10px;
    margin-bottom: 0;
    .creative_type_radio_item {
      border: 1px solid #e6e6e6;
      height: 37px;
      width: 121px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      &.active {
        border-color: @primary-color;
        background: rgba(65, 95, 255, 0.06);
      }
    }
  }
  .tabs_wrapper {
    min-height: 500px;
    border-radius: 10px;
    border: 1px solid #e6e6e6;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .tabs_header {
      height: 37px;
      background-color: #fafafa;
      display: flex;
      justify-content: space-between;
      //   align-items: center;
      .tab_pane_item {
        .name {
          margin-right: 5px;
        }
        .close {
          cursor: pointer;
          &:hover {
            color: #999;
          }
        }
      }
      .add {
        padding: 0 20px;
        font-size: 12px;
        color: @primary-color;
        line-height: 37px;
        cursor: pointer;
      }
    }
    .creative_item_wrapper {
      padding: 0 20px;

      .el_image_box {
        background: #fafafa;
        width: var(--ad--creative--width);
        height: 200px;
        border: 1px dashed #ccc;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          .modal {
            opacity: 1;
          }
        }
        .modal {
          opacity: 0;
          background: rgba(0, 0, 0, 0.1);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          .wrapper {
            .deleteIcon {
              margin: 5px;
              cursor: pointer;
              color: #333;
              font-size: 20px;
              &:hover {
                color: @primary-color;
              }
            }
          }
        }
      }
      .upload_grey_text {
        width: var(--ad--creative--width);
        background: #f2f2f2;
        border-radius: 5px;
        padding: 12px 10px;
        line-height: 1.5;
        color: #b2b2b2;
        text-align: left;
        font-size: 13px;
        margin: 20px 0;
      }
    }
    .tab_empty {
      flex: 1 1 auto;
      height: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        cursor: pointer;
        color: @primary-color;
      }
    }
  }
}
::v-deep.ad-creative-form-container {
  .creative_type_radio span.ant-radio {
    opacity: 0;
  }
  .uploader {
    .ant-upload {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
}
</style>
