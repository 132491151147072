<template>
  <div class="sonfig_container">
    <div class="sonfig_left">
      <a-menu v-model="current" mode="inline" type="inner" @click="handleClickMenu">
        <a-menu-item key="3" v-if="!show"> 联盟广告主</a-menu-item>
        <a-menu-item key="1"> 直客广告主 </a-menu-item>
        <a-menu-item key="2"> 广告代理商 </a-menu-item>
      </a-menu>
    </div>
    <div class="sonfig_right">
      <Advertiser v-if="current[0] === '1'" />
      <Agent v-if="current[0] === '2'" />
      <Alliance v-if="current[0] === '3'" />
    </div>
  </div>
</template>

<script>
import Advertiser from './Advertiser'
import Agent from './Agent'
import Alliance from './Alliances'
import PlacesModal from '../components/placesModal'
import { mapState } from 'vuex'
export default {
  name: 'Sonfig',
  components: { Advertiser, Agent, PlacesModal, Alliance },
  data () {
    return {
      current: ['1']
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0]
    }),
    show () {
      return this.role === 'AGENT'
    }
  },
  created () {
    this.current = [this.show ? '1' : '3']
  },
  methods: {
    handleClickMenu ({ key }) {
      this.current = [key]
    }
  }
}
</script>

<style lang="less" scoped>
.sonfig_container {
  padding: 0 10px;
  margin-bottom: 10px;
  display: flex;
  height: calc(100% - 130px);
  .sonfig_left {
    max-width: 200px;
    min-width: 180px;
    margin-right: 10px;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    padding: 20px 0;
    font-weight: 600;
  }
  .sonfig_right {
    height: 100%;
    flex-grow: 1;
    overflow: hidden;
    position: relative;
  }
  ::v-deep .ant-menu-item {
    border-left: 3px solid #fff;
  }
  ::v-deep .ant-menu-item.ant-menu-item-selected::after {
    border-right: none;
  }
  ::v-deep .ant-menu-item.ant-menu-item-selected {
    border-left: 3px solid;
  }
  ::v-deep .ant-table-row {
    height: 60px;
  }
}
</style>
