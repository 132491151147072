<template>
  <div id="step_ad_group" class="step-ad-group-container">
    <a-tabs v-if="+steps === 2" :activeKey="activeKey" @tabClick="tabClick" :animated="false">
      <a-tab-pane :key="1" :tab="`${isAdd ? '添加' : '编辑'}广告组`" :force-render="true">
        <AdGroupForm ref="AdGroupForm" />
      </a-tab-pane>
      <a-tab-pane v-if="isAdd && steps === 2" :key="2" tab="选择已有广告组" :force-render="true">
        <div class="select_group">
          <span style="font-size: 14px; font-weight: 600; margin-right: 20px">广告组名称</span>
          <a-select
            @change="changeSelectGroupId"
            @focus="getAdGroupList"
            style="width: 400px"
            placeholder="请选择已有广告组"
            v-model="selectGroupId"
            allowClear
          >
            <a-select-option v-for="item in adgroupList" :key="item.id" :value="item.id">{{
              item.name
            }}</a-select-option>
          </a-select>
        </div>
      </a-tab-pane>
    </a-tabs>
    <AdGroupViewVue
      v-if="isAdd && selectGroupId && activeKey === 2 && selectGroupInfo.adPlanGroup"
      :adGroupInfo="selectGroupInfo"
    />
  </div>
</template>

<script>
import AdGroupForm from './AdGroupForm'
import AdGroupViewVue from './AdGroupView.vue'
import { getAdGroupList, getDirectionalByGroupId } from '@/api/advertisementStep'
export default {
  inject: ['currentAdPlanId', 'changePlanId', 'currentAdGroupId', 'changeGroupId'],
  components: { AdGroupForm, AdGroupViewVue },
  data () {
    return {
      // tab值
      activeKey: 1,
      // 当前步骤
      steps: 1,
      // 是否是添加
      isAdd: false,
      // 广告组下拉列表
      adgroupList: [],
      // 选中的已创建的广告组
      selectGroupId: undefined,
      selectGroupInfo: {}
    }
  },
  watch: {
    $route: {
      handler (val) {
        // 获取当前步骤
        this.steps = val && val.query && +val.query.steps
        // 刷新页面处理
        // 第二步，显示form表单
        this.steps && this.steps === 2 && (this.activeKey = 1)
        // 第三步，显示详情
        this.steps && this.steps === 3 && (this.activeKey = 2)
        // 获取当前是否是添加状态
        this.isAdd = val.name === 'addadsteps'
        // 编辑状态获取计划ID
        if (this.isAdd) {
          !this.selectGroupId && (this.selectGroupId = val && val.query && val.query.adGroupId)
          this.selectGroupId && this.changeSelectGroupId(this.selectGroupId)
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async changeSelectGroupId (id) {
      this.changeGroupId(id)
      const resp = await getDirectionalByGroupId({ id })
      this.selectGroupInfo = resp.data
    },
    async getAdGroupList () {
      const resp = await getAdGroupList([this.currentAdPlanId()])
      this.adgroupList = resp.data
    },
    // teb切换事件
    tabClick (e) {
      this.activeKey = e
      // 切换到存在已创建tab，传给父级，改变按钮
      this.$emit('handleIsSelect', e === 2)
    },
    // 调用子组件form表单的提交方法
    handleSubmitAdGroup () {
      return new Promise(async (resolve) => {
        const res = await this.$refs.AdGroupForm.handleSubmitAdGroup()
        if (this.isAdd) {
          res.code === 200 && (this.activeKey = 2)
          res.code === 200 && (this.selectGroupId = res.data.id)
          res.code === 200 && this.changeSelectGroupId(res.data.id)
        }
        // 抛出给父级组件
        resolve(res)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.step-ad-group-container {
  .select_group {
    display: flex;
    height: 40px;
    line-height: 36px;
    margin: 30px 0;
  }
}
</style>
