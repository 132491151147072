<template>
  <div id="step_ad_creative" class="step-ad-creative-container">
    <a-tabs v-if="+steps === 3" :activeKey="activeKey">
      <a-tab-pane :key="1" :tab="`${isAdd ? '添加' : '编辑'}广告`" :force-render="true">
        <AdCreativeFormVue ref="AdCreativeForm" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import AdCreativeFormVue from './AdCreativeForm.vue'
export default {
  inject: ['currentAdPlanId', 'changePlanId', 'currentAdGroupId', 'changeGroupId'],
  components: { AdCreativeFormVue },
  data () {
    return {
      // tab值
      activeKey: 1,
      // 当前步骤
      steps: 1,
      // 是否是添加
      isAdd: false
      // 广告组下拉列表
    }
  },
  watch: {
    $route: {
      handler (val) {
        // 获取当前步骤
        this.steps = val && val.query && +val.query.steps
        // 获取当前是否是添加状态
        this.isAdd = val.name === 'addadsteps'
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 调用子组件form表单的提交方法
    handleSubmitAdGroup () {
      return new Promise(async (resolve) => {
        const res = await this.$refs.AdCreativeForm.handleSubmitAdCreative()
        // 抛出给父级组件
        resolve(res)
      })
    }
  }
}
</script>
