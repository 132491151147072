<template>
  <a-modal
    v-model="addPlaceVisible"
    :title="this.names"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    dialogClass="batch_advertiser_modal"
  >
    <div class="add_advertiser_wrapper">
      <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout">
        <a-form-model-item label="广告主名称" prop="advertiserName" v-if="this.isAdd === 1 || this.isAdd === 2">
          <a-input v-model.trim="query.advertiserName" autocomplete="off" placeholder="请输入广告主名称" />
        </a-form-model-item>
        <a-form-model-item label="代理商名称" prop="agentName" v-if="this.isAdd === 3 || this.isAdd === 4">
          <a-input v-model.trim="query.agentName" autocomplete="off" placeholder="请输入代理商名称" />
        </a-form-model-item>
        <a-form-model-item label="广告主账号" prop="accountNo" v-if="this.isAdd === 1 || this.isAdd === 2">
          <a-input v-model.trim="query.accountNo" autocomplete="off" placeholder="请输入广告主账号" />
        </a-form-model-item>
        <a-form-model-item label="代理商账号" prop="accountNo" v-if="this.isAdd === 3 || this.isAdd === 4">
          <a-input v-model.trim="query.accountNo" autocomplete="off" placeholder="请输入代理商账号" />
        </a-form-model-item>
        <a-form-model-item label="密码" prop="password" v-if="this.isAdd === 1 || this.isAdd === 3">
          <a-input
            ref="password"
            v-model.trim="query.password"
            :type="isShow ? '' : 'password'"
            autocomplete="new-password"
            placeholder="请输入密码"
          >
            <a-icon style="cursor: pointer" @click="changeIsShow" slot="suffix" type="eye" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="公司全称" prop="advertiserCompany" v-if="this.isAdd === 1 || this.isAdd === 2">
          <a-input v-model.trim="query.advertiserCompany" autocomplete="off" placeholder="请输入公司全称" />
        </a-form-model-item>
        <a-form-model-item label="公司全称" prop="agentCompany" v-if="this.isAdd === 3 || this.isAdd === 4">
          <a-input v-model.trim="query.agentCompany" autocomplete="off" placeholder="请输入公司全称" />
        </a-form-model-item>
        <a-form-model-item
          label="联系人"
          prop="companyContact"
          v-if="this.isAdd === 1 || this.isAdd === 2 || this.isAdd === 3 || this.isAdd === 4"
        >
          <a-input v-model.trim="query.companyContact" autocomplete="off" placeholder="请输入联系人" />
        </a-form-model-item>
        <a-form-model-item label="联系电话" prop="contactPhone">
          <a-input v-model.trim="query.contactPhone" autocomplete="off" placeholder="请输入联系电话" />
        </a-form-model-item>
        <a-form-model-item label="所属代理" prop="sysAgent" v-if="this.isAdd === 1 || this.isAdd === 2">
          <a-select v-model="query.sysAgent" placeholder="请选择所属代理">
            <a-select-option v-for="item in sysAgent" :key="item.id" :value="item.id">{{
              item.agentName
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- <a-form-model-item label="客户经理" prop="businessUser">
        <a-select v-model="query.businessUser" placeholder="请选择客户经理" @change="changeUser">
          <a-select-option v-for="item in businessUser" :key="item.id" :value="item.id">{{
            item.name
          }}</a-select-option>
        </a-select>
      </a-form-model-item> -->
        <a-form-model-item label="所属行业" prop="advertiserIndustry" v-if="this.isAdd === 1 || this.isAdd === 2">
          <a-cascader
            :fieldNames="fieldNames"
            v-model="query.advertiserIndustrys"
            :options="industry"
            placeholder="请选择所属行业"
            @change="changeTradeId"
            :allowClear="false"
          />
        </a-form-model-item>
        <a-form-model-item label="广告分类" prop="advertiserCategory" v-if="this.isAdd === 1 || this.isAdd === 2">
          <a-select v-model="query.advertiserCategory" placeholder="请选择广告分类" @change="changeAdvertiser">
            <a-select-option value="1"> 品牌 </a-select-option>
            <a-select-option value="2"> 效果 </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="营业执照" prop="iconUrl">
          <a-upload
            :action="uploadUrl"
            name="file"
            list-type="picture-card"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            @change="handleChange"
            :headers="header"
            :multiple="true"
          >
            <!-- <img v-if="imagesUrl" :src="imagesUrl" alt="图标" style="width: 120px" /> -->
            <!-- <img v-if="query.iconUrl" :src="resourceUrl + query.iconUrl" alt="图标" style="width: 120px" /> -->
            <div v-for="item in this.imagesPush" :key="item">
              <img v-if="item" :src="resourceUrl + item" alt="营业执照" style="width: 120px" />
            </div>
            <div>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import {
  tradeList,
  businessUserList,
  agentList,
  advertiserAdd,
  advertiserUpdate,
  agentListAdd,
  agentListUpdate
} from '@/api/system'
import { mapState } from 'vuex'
import { Encrypt } from '@/utils/jsencrypt'
export default {
  data () {
    // 校验新密码
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('新密码不能为空！请重新输入'))
      } else {
        if (value.length < 8) {
          callback(new Error('密码不低于8位'))
        } else {
          callback()
        }
      }
    }
    return {
      imagesPush: [],
      tps: 1,
      datts: [],
      defectSearchForm: {},
      imagesUrl: '',
      url: '...',
      fileList: [],
      sysAgent: [],
      businessUser: [],
      industry: [],
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      pid: [],
      uploadUrl: process.env.VUE_APP_API_BASE_URL + 'common/upload',
      loading: false,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      query: {},
      isShow: false,
      rules: {
        appId: [{ required: true, message: '请选择应用', trigger: 'change' }],
        advertiserName: [{ required: true, message: '请输入广告主名称', trigger: 'blur' }],
        companyContact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        agentName: [{ required: true, message: '请输入代理商名称', trigger: 'blur' }],
        accountNo: [
          {
            required: true,
            message: '请填写广告主账号',
            trigger: 'blur'
          },
          {
            message: '广告主账号必须为邮箱格式',
            trigger: 'blur',
            pattern: /^[A-Za-zd0-9_-]+([-_.][A-Za-zd0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
          }
        ],
        password: [{ required: true, trigger: 'blur', validator: validatePass }],
        advertiserCompany: [{ required: true, message: '请输入公司全称', trigger: 'blur' }],
        agentCompany: [{ required: true, message: '请输入公司全称', trigger: 'blur' }],
        contactPhone: [
          {
            required: true,
            message: '请填写联系方式',
            trigger: 'blur'
          },
          {
            message: '手机号无效',
            trigger: 'blur',
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
          }
        ],
        businessUser: [{ required: true, message: '请选择客户经理', trigger: 'change' }],
        advertiserIndustry: [{ required: true, message: '请选择所属行业', trigger: 'change' }],
        advertiserCategory: [{ required: true, message: '请选择广告分类', trigger: 'change' }]
      },
      appList: [],
      positionList: [],
      templateList: [],
      names: ''
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Number,
      default: 0
    },
    appId: {
      default: '',
      type: String
    },
    placeInfo: {
      default: () => ({}),
      type: Object
    },
    supplier: {
      type: Number,
      default: 0
    }
  },
  watch: {
    placeInfo: {
      handler (val) {
        if (this.isAdd === 2) {
          this.names = '修改广告主'
          this.query = JSON.parse(JSON.stringify(val))
          if (!val.sysAgent) {
          } else {
            this.query.sysAgent = val.sysAgent * 1
          }

          this.query.businessUser = val.businessUser * 1
          const advertiserIndustry = []
          advertiserIndustry.push(val.pid * 1)
          advertiserIndustry.push(val.advertiserIndustry * 1)
          this.query.advertiserIndustrys = advertiserIndustry
          this.query.iconUrl = val.businessLicense
          if (!val.businessLicense) {
          } else {
            this.imagesPush = val.businessLicense.split(',')
          }
        } else if (this.isAdd === 1) {
          this.query = {}
          this.names = '添加广告主'
        } else if (this.isAdd === 3) {
          this.names = '添加代理商'
        } else if (this.isAdd === 4) {
          this.names = '修改代理商'
          this.query = JSON.parse(JSON.stringify(val))
          this.query.businessUser = val.businessUser * 1
          this.query.iconUrl = val.businessLicense
          this.imagesPush = val.businessLicense.split(',')
        }
      },
      deep: true,
      immediate: true
    },
    appId: {
      handler (val) {
        if (val) {
          this.query.appId = val
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.getIndustrySelect()
    this.getBusinessUserList()
    this.getAgentList()
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      resourceUrl: (state) => state.autoweb.resourceUrl
    }),
    header () {
      return {
        Authorization: this.token
      }
    },
    addPlaceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    changeIsShow () {
      this.isShow = !this.isShow
      this.$refs.password.focus()
    },
    // 所属行业
    async getIndustrySelect () {
      const res = await tradeList()
      if (res.code === 200) {
        this.industry = res.data
      }
    },
    // 经理
    async getBusinessUserList () {
      const res = await businessUserList()
      if (res.code === 200) {
        this.businessUser = res.data
      }
    },
    // 所属代理
    async getAgentList () {
      const res = await agentList()
      if (res.code === 200) {
        this.sysAgent = res.data
      }
    },
    changeAdvertiser (val) {},
    changeUser (val) {},
    changeTradeId (val) {
      this.query.advertiserIndustry = val[1] * 1
      if (val.length > 0) {
        this.query.tradePid = val[0]
        this.query.tradeId = val[1]
      }
    },
    handleCancel () {
      this.isAdd = ''
      this.addPlaceVisible = false
    },
    handleSubmit () {
      const password = Encrypt(this.query.password)
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.isAdd) {
            if (this.isAdd === 1) {
              const query = {
                businessLicense: this.query.iconUrl,
                advertiserName: this.query.advertiserName,
                advertiserCompany: this.query.advertiserCompany,
                contactPhone: this.query.contactPhone,
                sysAgent: this.query.sysAgent,
                businessUser: this.query.businessUser,
                advertiserIndustry: this.query.advertiserIndustry,
                advertiserCategory: this.query.advertiserCategory,
                accountNo: this.query.accountNo,
                password: password,
                companyContact: this.query.companyContact
              }
              const resp = await advertiserAdd(query)
              if (resp.code === 200) {
                this.$message.success('添加成功!')
                this.$emit('changeAppInfo', 'add')
              }
            } else if (this.isAdd === 3) {
              const query = {
                businessLicense: this.query.iconUrl,
                agentName: this.query.agentName,
                agentCompany: this.query.agentCompany,
                contactPhone: this.query.contactPhone,
                businessUser: this.query.businessUser,
                accountNo: this.query.accountNo,
                password: password,
                companyContact: this.query.companyContact
              }
              const resp = await agentListAdd(query)
              if (resp.code === 200) {
                this.$message.success('添加成功!')
                this.$emit('changeAppInfo', 'add')
              }
            } else if (this.isAdd === 2) {
              const query = {
                businessLicense: this.query.iconUrl,
                advertiserName: this.query.advertiserName,
                advertiserCompany: this.query.advertiserCompany,
                contactPhone: this.query.contactPhone,
                sysAgent: this.query.sysAgent,
                businessUser: this.query.businessUser,
                advertiserIndustry: this.query.advertiserIndustry,
                advertiserCategory: this.query.advertiserCategory,
                accountNo: this.query.accountNo,
                password: password,
                id: this.query.id,
                companyContact: this.query.companyContact
              }
              const resp = await advertiserUpdate(query)
              if (resp.code === 200) {
                this.$message.success('修改成功!')
              }
            } else if (this.isAdd === 4) {
              const query = {
                businessLicense: this.query.iconUrl,
                agentName: this.query.agentName,
                agentCompany: this.query.agentCompany,
                contactPhone: this.query.contactPhone,
                businessUser: this.query.businessUser,
                accountNo: this.query.accountNo,
                password: password,
                id: this.query.id,
                companyContact: this.query.companyContact
              }
              const resp = await agentListUpdate(query)
              if (resp.code === 200) {
                this.$message.success('修改成功!')
              }
            }
          }
          this.$emit('changePlace')
          this.addPlaceVisible = false
        } else {
          return false
        }
      })
    },

    // selfUpload(file){
    // 	this.datts = file.file
    // },
    handleChange (info) {
      // if (info.file.status === 'uploading') {
      //   this.loading = true
      //   return
      // }
      // if (info.file.status === 'done') {
      //   this.query.iconUrl = info.file.response.data
      //   this.imagesUrl = info.file.response.data
      //   this.loading = false
      // }
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        this.imagesPush.push(info.file.response.data)
        this.query.iconUrl = this.imagesPush.join(',')
        this.loading = false
      }
    },

    beforeUpload (file) {
      this.datts.push(file)
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('请上传图片文件！')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('请上传小于2M的图片!')
      }
      return isJpgOrPng && isLt2M
    }
  }
}
</script>

<style lang="less" scoped>
// ::v-deep {
  // .ant-modal-body {
    // background-color: #eee;
  // }
// }
.batch_advertiser_modal {
  .ant-modal-body {
    // background-color: #eee;
    .add_advertiser_wrapper {
      height: 60vh;
      overflow-y: auto;
      padding: 20px;
      background-color: #fff;
      border-radius: 5px;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        background: fade(@primary-color, 20%);
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
}
</style>
