<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-26 11:35:40
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 11:32:18
 * @FilePath: /mediatom-web/src/components/AccountModal/PlatForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="plat-account-form-container" v-if="+platId === 1061">
    <a-form-model-item prop="isAutoCreate">
      <m-tips
        slot="label"
        :content="`百青藤支持通过API接口自动创建代码位，开启后在${title}创建百青藤广告源时，可以通过API在百青藤后台同步创建代码位。`"
        title="自动创建广告源"
        :width="200"
      ></m-tips>
      <a-radio-group name="radioGroup" v-model="formquery.isAutoCreate">
        <a-radio :value="1"> 是 </a-radio>
        <a-radio :value="0"> 否 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <template v-if="+formquery.isAutoCreate === 1">
      <a-form-model-item :rules="[{ required: true, message: '请输入AccessKey', trigger: 'blur' }]" prop="mid" label="AccessKey">
        <a-input v-model.trim="formquery.mid" placeholder="请输入Accesskey"></a-input>
      </a-form-model-item>
      <a-form-model-item prop="signKey" label="公钥" v-if="!isAdd">
        <div class="input-box">
          <a-input v-model.trim="formquery.signKey" disabled></a-input>
          <a-button v-if="!isAdd" type="link" @click="copyId(formquery.signKey)">复制</a-button>
        </div>
        <a-alert
          style="font-size: 12px; margin-top: 10px; border-radius: 5px"
          message="️需要将公钥配置到百度联盟后台，若没有配置权限，请联系百度商务对接人开通。"
          banner
        />
      </a-form-model-item>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { copy } from '@/utils/string'
export default {
  data () {
    return {
      formqueryTemp: {
        isAutoCreate: 0,
        mid: undefined,
        signKey: undefined
      }
    }
  },
  created () {
    this.$set(this.formquery, 'isAutoCreate', this.query.isAutoCreate || 0)
    this.$set(this.formquery, 'mid', this.query.mid || undefined)
    this.$set(this.formquery, 'signKey', this.query.signKey || undefined)
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('update:query', formquery)
      }
    },
    ...mapState({
      title: (state) => state.autoweb.title,
      // 角色
      role: (state) => state.user.roles[0],
      adminUserId: (state) => state.user.adminUserId,
      moneyType: (state) => (+state.user.cur === 1 ? '$' : '¥')
    })
  },
  props: {
    query: {
      default: () => ({
      }),
      type: Object
    },
    isAdd: {
      default: false,
      type: Boolean
    },
    platId: {
      default: 0,
      type: [Number, String]
    }
  },
  methods: {
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.input-box{
  display: flex;
  flex-wrap: nowrap;
}
</style>
