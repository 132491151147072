<template>
  <div class="ad-group-form-container">
    <a-form-model ref="adgroupform" :model="adgroupform" v-bind="layout">
      <a-form-model-item>
        <span class="weight_label" style="padding-top: 0; padding-bottom: 0">目标详情</span>
      </a-form-model-item>
      <!-- 交互类型 -->
      <div class="form_item">
        <span class="form_item_label">交互类型</span>
        <a-form-model-item prop="actionType">
          <a-radio-group v-model="adgroupform.actionType">
            <a-radio :value="1"> 网页打开 </a-radio>
            <a-radio :value="2"> 下载 </a-radio>
            <a-radio :value="3"> 唤醒 </a-radio>
            <a-radio :value="4"> 微信小程序 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </div>
      <!-- 微信小程序ID -->
      <div class="form_item" v-if="adgroupform.actionType === 4">
        <span class="form_item_label">微信小程序ID</span>
        <a-form-model-item
          prop="wxMiniProId"
          :rules="[{ required: true, message: '请输入微信小程序ID', trigger: 'blur' }]"
        >
          <a-input
            style="width: 400px"
            v-model.trim="adgroupform.wxMiniProId"
            placeholder="请输入微信小程序ID"
            allowClear
          ></a-input>
        </a-form-model-item>
      </div>
      <!-- 微信小程序路径 -->
      <div class="form_item" v-if="adgroupform.actionType === 4">
        <span class="form_item_label">微信小程序路径</span>
        <a-form-model-item
          prop="putTargetUrl"
          :rules="[{ required: true, message: '请输入微信小程序路径', trigger: 'blur' }]"
        >
          <a-input
            style="width: 400px"
            v-model.trim="adgroupform.putTargetUrl"
            placeholder="请输入微信小程序路径"
            allowClear
          ></a-input>
        </a-form-model-item>
      </div>
      <!-- 投放目标地址 -->
      <div class="form_item" v-else>
        <span class="form_item_label">投放目标地址</span>
        <a-form-model-item
          prop="putTargetUrl"
          :rules="[{ required: true, message: '请输入投放目标地址', trigger: 'blur' }]"
        >
          <a-input
            style="width: 400px"
            v-model.trim="adgroupform.putTargetUrl"
            placeholder="请输入投放目标地址"
            allowClear
          ></a-input>
        </a-form-model-item>
      </div>
      <!-- 唤醒地址 -->
      <div class="form_item" v-if="adgroupform.actionType === 3">
        <span class="form_item_label">唤醒地址</span>
        <a-form-model-item prop="deeplinkUrl" :rules="[{ required: true, message: '请输入唤醒地址', trigger: 'blur' }]">
          <a-input
            style="width: 400px"
            v-model.trim="adgroupform.deeplinkUrl"
            placeholder="请输入唤醒地址"
            allowClear
          ></a-input>
        </a-form-model-item>
      </div>
      <!-- 展示检测URL -->
      <div class="form_item">
        <span
          class="form_item_label"
        >展示检测URL<a-popover>
          <template slot="content">
            <div style="display: flex; justify-content: space-between; width: 280px">
              <div style="color: #61627e">
                <p style="color: #919399; font-weight: 700">已支持的占位符</p>
                <p>__IMEI__</p>
                <p>__OAID__</p>
                <p>__IDFA__</p>
                <p>__IDFV__</p>
                <p>__OS__</p>
                <p>__IP__</p>
                <p>__UA__</p>
                <p>__PACKAGE_NAME__</p>
                <p>__MAC__</p>
              </div>
              <div style="padding-left: 10px; color: #61627e">
                <p style="color: #919399; font-weight: 700">说明</p>
                <p>安卓设备的IMEI</p>
                <p>安卓设备的OAID</p>
                <p>iOS设备的IDFA</p>
                <p>设备的IDFV</p>
                <p>操作系统信息</p>
                <p>设备的IP地址</p>
                <p>用户的UA标识</p>
                <p>应用的包名</p>
                <p>设备的MAC地址</p>
              </div>
            </div>
          </template>
          <a-icon
            type="info-circle"
            style="color: rgba(0, 0, 0, 0.45); margin-left: 5px; font-size: 12px; cursor: pointer"
          /> </a-popover
        ></span>
        <!-- :rules="[{ required: true, message: '请输入展示检测URL', trigger: 'blur' }]" -->
        <a-form-model-item prop="impressTrackUrl">
          <a-input
            style="width: 400px"
            v-model.trim="adgroupform.impressTrackUrl"
            placeholder="请输入展示检测URL"
            allowClear
          ></a-input>
        </a-form-model-item>
      </div>
      <!-- 点击检测URL -->
      <div class="form_item">
        <span
          class="form_item_label"
        >点击检测URL<a-popover>
          <template slot="content">
            <div style="display: flex; justify-content: space-between; width: 280px">
              <div style="color: #61627e">
                <p style="color: #919399; font-weight: 700">已支持的占位符</p>
                <p>__IMEI__</p>
                <p>__OAID__</p>
                <p>__IDFA__</p>
                <p>__IDFV__</p>
                <p>__OS__</p>
                <p>__IP__</p>
                <p>__UA__</p>
                <p>__PACKAGE_NAME__</p>
                <p>__MAC__</p>
              </div>
              <div style="padding-left: 10px; color: #61627e">
                <p style="color: #919399; font-weight: 700">说明</p>
                <p>安卓设备的IMEI</p>
                <p>安卓设备的OAID</p>
                <p>iOS设备的IDFA</p>
                <p>设备的IDFV</p>
                <p>操作系统信息</p>
                <p>设备的IP地址</p>
                <p>用户的UA标识</p>
                <p>应用的包名</p>
                <p>设备的MAC地址</p>
              </div>
            </div>
          </template>
          <a-icon
            type="info-circle"
            style="color: rgba(0, 0, 0, 0.45); margin-left: 5px; font-size: 12px; cursor: pointer"
          /> </a-popover
        ></span>
        <!-- :rules="[{ required: true, message: '请输入点击检测URL', trigger: 'blur' }]" -->
        <a-form-model-item prop="clickTrackUrl">
          <a-input
            style="width: 400px"
            v-model.trim="adgroupform.clickTrackUrl"
            placeholder="请输入点击检测URL"
            allowClear
          ></a-input>
        </a-form-model-item>
      </div>
      <!-- 媒体选择 -->
      <a-form-model-item style="margin-bottom: 10px">
        <span class="weight_label" style="padding-top: 0; padding-bottom: 0">媒体选择</span>
      </a-form-model-item>
      <div class="form_item" style="border-bottom: 1px solid #e6e6e6">
        <a-radio-group v-if="showAlliancePlace" style="margin-bottom: 10px" v-model="meidatype" @change="onChangeMedia">
          <a-radio-button style="border-radius: 5px;" value="0">自有媒体</a-radio-button>
          <!-- <a-radio-button value="1">联盟媒体</a-radio-button> -->
        </a-radio-group>
        <a-form-model-item prop="placeIdList" :rules="[{ required: true, message: '请选择媒体', trigger: 'change' }]">
          <div class="media_wrapper">
            <div class="app_box">
              <div class="box_header">
                <span>应用</span>
              </div>
              <div class="search-box">
                <a-input size="small" v-model.trim="mediaSearchKey" placeholder="请输入应用名称/ID"></a-input>
              </div>
              <div class="checked_box">
                <div class="checked_item">
                  <a-checkbox
                    :indeterminate="indeterminateAllMedia"
                    :checked="checkAllMedia"
                    @change="onCheckAllMediaChange"
                    style="font-size: 12px"
                  >
                    全部
                  </a-checkbox>
                </div>
                <div class="checked_item app_checked_item" v-for="(item) in mediaListComp" :key="item.appId">
                  <a-checkbox
                    :checked="item.checkAll"
                    :indeterminate="item.indeterminate"
                    @change="handleCheckedAppItemAll(item)"
                  >
                  </a-checkbox>
                  <div
                    :class="{ desc: true, active: currentAppId === item.appId }"
                    @click="handleClickAppMedia(item)"
                  >
                    <span class="name">{{ item.appName }}</span>
                    <span><a-icon type="right" /></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="place_box">
              <div class="box_header">
                <span>广告位</span>
              </div>
              <div class="checked_box">
                <div class="checked_item">
                  <a-checkbox
                    v-if="currentPlaceList.length > 0"
                    :indeterminate="indeterminatePlace"
                    :checked="checkAllPlace"
                    @change="CheckAllPlaceChange"
                    style="font-size: 12px"
                  >
                    全部
                  </a-checkbox>
                </div>
                <div
                  class="checked_item app_checked_item"
                  :title="item.placeName"
                  v-for="item in currentPlaceList"
                  :key="item.placeId"
                >
                  <a-checkbox
                    style="font-size: 12px; position: relative"
                    :checked="item.checked"
                    @change="checkPlaceItem(item)"
                  >
                    {{ item.placeName }}
                  </a-checkbox>
                </div>
              </div>
            </div>
            <div class="hasChecked_box">
              <div class="hasChecked_header">
                <span>已选广告位{{ adgroupform.placeIdList.length }}项</span>
                <span class="clear_all" @click="handleClearAllMedia">清除全部</span>
              </div>
              <div class="checked_list">
                <div
                  class="checked_item"
                  v-for="item in allPlaceList"
                  :key="item.placeId"
                  v-show="adgroupform.placeIdList.includes(item.placeId)"
                >
                  <span class="name">{{ item.placeName }}</span>
                  <span class="close" @click="handleClearPlaceItem(item.placeId)"><a-icon type="close" /></span>
                </div>
              </div>
            </div>
          </div>
        </a-form-model-item>
      </div>
      <!-- 定向设置 -->
      <a-form-model-item style="margin-bottom: 10px">
        <span class="weight_label" style="padding-top: 0; padding-bottom: 0">定向设置</span>
      </a-form-model-item>
      <!-- 定向设置---地域 -->
      <div class="form_item">
        <div style="margin-bottom: 10px">地域</div>
        <a-radio-group style="margin-bottom: 10px" v-model="areaType">
          <a-radio-button value="0">不限</a-radio-button>
          <a-radio-button value="1">按行政区域划分</a-radio-button>
        </a-radio-group>
        <a-form-model-item
          v-if="areaType === '1'"
          prop="areas"
          :rules="[{ required: areaType === '1', message: '请选择区域', trigger: 'change' }]"
        >
          <div class="area_wrapper">
            <div class="country_box">
              <div class="box_header">国家</div>
              <div class="check_list">
                <div class="country_item" v-for="item in areaList" :key="item.value">
                  <a-checkbox
                    style="font-size: 12px"
                    :checked="checkAllCountry"
                    :indeterminate="indeterminateAllCountry"
                    @change="checkAllAreaChecked"
                  >
                  </a-checkbox>
                  <div class="name">
                    <span>{{ item.label }}</span>
                    <span><a-icon type="right" /></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="province_box">
              <div class="box_header">省份</div>
              <div class="check_list">
                <div class="province_item">
                  <a-checkbox
                    :indeterminate="indeterminateAllCountry"
                    :checked="checkAllCountry"
                    @change="checkAllAreaChecked"
                    style="font-size: 12px"
                  >
                    全部
                  </a-checkbox>
                </div>
                <div class="province_item" v-for="(item, index) in provinceList" :key="item.value">
                  <a-checkbox
                    :checked="item.checked"
                    :indeterminate="item.indeterminate"
                    @change="handleCheckedProviceItemAll(item, index)"
                  >
                  </a-checkbox>
                  <div
                    :class="{ desc: true, active: currentProvinceValue === item.value }"
                    @click="handleClickProviceItemLabel(item, index)"
                  >
                    <span class="name">{{ item.label }}</span>
                    <span><a-icon type="right" /></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="city_box">
              <div class="box_header">城市</div>
              <div class="check_list">
                <div class="city_item">
                  <a-checkbox
                    v-if="currentCityeList.length > 0"
                    :indeterminate="indeterminateCity"
                    :checked="checkAllCity"
                    @change="CheckAllCityChange"
                    style="font-size: 12px"
                  >
                    全部
                  </a-checkbox>
                </div>
                <div class="city_item" v-for="item in currentCityeList" :key="item.value">
                  <a-checkbox style="font-size: 12px" :checked="item.checked" @change="checkCityItem(item)">
                    {{ item.label }}
                  </a-checkbox>
                </div>
              </div>
            </div>
            <div class="checked_area_box">
              <div class="box_header">
                <span>已选{{ adgroupform.areas.length }}项</span>
                <span class="close" @click="handleClearAllCity">清除全部</span>
              </div>
              <div class="check_list">
                <div class="check_item" v-for="(item, index) in adgroupform.areas" :key="index">
                  <span>
                    {{ getAreasLabel(item) }}
                  </span>
                  <span class="close" @click="handleDeleteCityItem(item, index)"><a-icon type="close" /></span>
                </div>
              </div>
            </div>
          </div>
        </a-form-model-item>
      </div>
      <!-- 定向设置---平台 -->
      <div class="form_item">
        <div style="margin-bottom: 10px">平台</div>
        <a-form-model-item prop="platform">
          <a-radio-group v-model="adgroupform.platform">
            <a-radio :value="-1"> 不限 </a-radio>
            <a-radio value="0"> IOS </a-radio>
            <a-radio value="1"> 安卓 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </div>
      <!-- 定向设置---设备类型 -->
      <div class="form_item">
        <div style="margin-bottom: 10px">设备类型</div>
        <a-form-model-item prop="equipment">
          <a-radio-group v-model="adgroupform.equipment">
            <a-radio :value="-1"> 不限 </a-radio>
            <a-radio value="0"> 智能手机 </a-radio>
            <a-radio value="1"> 平板 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </div>
      <!-- 定向设置---网络 -->
      <div class="form_item">
        <div style="margin-bottom: 10px">网络</div>
        <a-form-model-item prop="network">
          <div class="network_wrapper">
            <div class="check_item all_check_item" :class="{ active: adgroupform.network.length === 0 }">
              <a-checkbox :checked="adgroupform.network.length === 0" @change="handleChangeNetwork"> 不限 </a-checkbox>
            </div>
            <a-checkbox-group class="checked_group" v-model="adgroupform.network">
              <div class="check_item" :class="{ active: adgroupform.network.includes('1') }">
                <a-checkbox value="1"> Wi-Fi </a-checkbox>
              </div>
              <div class="check_item" :class="{ active: adgroupform.network.includes('2') }">
                <a-checkbox value="2"> 2G </a-checkbox>
              </div>
              <div class="check_item" :class="{ active: adgroupform.network.includes('3') }">
                <a-checkbox value="3"> 3G </a-checkbox>
              </div>
              <div class="check_item" :class="{ active: adgroupform.network.includes('4') }">
                <a-checkbox value="4"> 4G </a-checkbox>
              </div>
              <div class="check_item" :class="{ active: adgroupform.network.includes('5') }">
                <a-checkbox value="5"> 5G </a-checkbox>
              </div>
            </a-checkbox-group>
          </div>
        </a-form-model-item>
      </div>
      <!-- 定向设置---运营商 -->
      <div class="form_item">
        <div style="margin-bottom: 10px">运营商</div>
        <a-form-model-item prop="operator">
          <div class="operator_wrapper">
            <div class="check_item all_check_item" :class="{ active: adgroupform.operator.length === 0 }">
              <a-checkbox :checked="adgroupform.operator.length === 0" @change="handleChangeOperator">
                不限
              </a-checkbox>
            </div>
            <a-checkbox-group class="checked_group" v-model="adgroupform.operator">
              <div class="check_item" :class="{ active: adgroupform.operator.includes('0') }">
                <a-checkbox :value="'0'"> 移动 </a-checkbox>
              </div>
              <div class="check_item" :class="{ active: adgroupform.operator.includes('1') }">
                <a-checkbox :value="'1'"> 联通 </a-checkbox>
              </div>
              <div class="check_item" :class="{ active: adgroupform.operator.includes('2') }">
                <a-checkbox :value="'2'"> 电信 </a-checkbox>
              </div>
            </a-checkbox-group>
          </div>
        </a-form-model-item>
      </div>
      <!-- 定向设置---RTA -->
      <div class="form_item" v-if="role === 'SUPER_ADMIN'">
        <div style="margin-bottom: 10px">RTA类型</div>
        <a-form-model-item prop="rta">
          <a-radio-group class="rta-ardio-group" v-model="adgroupform.rta">
            <a-radio-button :value="0"> 不限 </a-radio-button>
            <a-radio-button :value="7"> 淘宝 </a-radio-button>
            <a-radio-button :value="8"> 优酷 </a-radio-button>
            <a-radio-button :value="12"> 百度 </a-radio-button>
            <a-radio-button :value="13"> UC </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <div style="margin-bottom: 10px" v-if="+adgroupform.rta !== 0">RTA广告位ID</div>
        <a-form-model-item v-if="+adgroupform.rta !== 0" prop="rtaPlaceId">
          <a-input style="width: 400px" placeholder="请输入RTA广告位ID" v-model.trim="adgroupform.rtaPlaceId" />
        </a-form-model-item>
        <div style="margin-bottom: 10px" v-if="+adgroupform.rta !== 0">RTA任务ID</div>
        <a-form-model-item v-if="+adgroupform.rta !== 0" prop="rtaId">
          <a-input style="width: 400px" placeholder="请输入RTAID" v-model.trim="adgroupform.rtaId" />
        </a-form-model-item>
      </div>
      <!-- 定向设置---安装列表 -->
      <div class="form_item">
        <div style="margin-bottom: 10px">安装列表</div>
        <a-radio-group style="margin-bottom: 10px" v-model="installType">
          <a-radio-button value="0">不限</a-radio-button>
          <a-radio-button value="1">按应用划分</a-radio-button>
        </a-radio-group>
        <a-radio-group style="display: block; margin: 5px" v-model="installIsClude" v-if="installType === '1'">
          <a-radio :value="0"> 定向已安装 </a-radio>
          <a-radio :value="1"> 排除已安装 </a-radio>
        </a-radio-group>
        <a-form-model-item
          v-if="installType === '1'"
          prop="installApps"
          :rules="[{ required: installType === '1', message: '请选择安装列表', trigger: 'change' }]"
        >
          <div class="install_wrapper">
            <div class="list_box">
              <div class="box_header">
                <span>安装列表</span>
              </div>
              <div class="box_list">
                <div class="install_item">
                  <a-checkbox
                    style="font-size: 12px"
                    :indeterminate="indeterminateInstallApp"
                    :checked="checkAllInstallApp"
                    @change="handleCheckInstallApp"
                  >
                    全部
                  </a-checkbox>
                </div>
                <div class="install_item" v-for="item in installAppList" :key="item.id">
                  <a-checkbox
                    style="font-size: 12px"
                    :checked="adgroupform.installApps.includes(item.id)"
                    @change="checkInstallAppItem($event, item)"
                  >
                    {{ item.name }}
                  </a-checkbox>
                </div>
              </div>
            </div>
            <div class="check_box">
              <div class="box_header">
                <span>已选应用{{ adgroupform.installApps.length }}项</span>
                <span class="clear_all" @click="handleClearAllInstallApp">清除全部</span>
              </div>
              <div class="box_list">
                <div
                  class="checked_item"
                  v-for="(item, index) in installAppList"
                  :key="item.id"
                  v-show="adgroupform.installApps.includes(item.id)"
                >
                  <span>{{ item.name }}</span>
                  <span class="close" @click="handleDeleteInstallAppItem(index)"><a-icon type="close" /></span>
                </div>
              </div>
            </div>
          </div>
        </a-form-model-item>
      </div>
      <!-- 定向设置---手机品牌 -->
      <div class="form_item">
        <div style="margin-bottom: 10px">手机品牌</div>
        <a-radio-group style="margin-bottom: 10px" v-model="phoneType">
          <a-radio-button value="0">不限</a-radio-button>
          <a-radio-button value="1">按手机品牌划分</a-radio-button>
        </a-radio-group>
        <a-form-model-item
          v-if="phoneType === '1'"
          prop="phoneBrands"
          :rules="[{ required: installType === '1', message: '请选择手机品牌', trigger: 'change' }]"
        >
          <div class="install_wrapper">
            <div class="list_box">
              <div class="box_header">
                <span>手机品牌</span>
              </div>
              <div class="box_list">
                <div class="install_item">
                  <a-checkbox
                    style="font-size: 12px"
                    :indeterminate="indeterminatePhoneBrand"
                    :checked="checkAllPhoneBrand"
                    @change="handleCheckPhoneBrand"
                  >
                    全部
                  </a-checkbox>
                </div>
                <div class="install_item" v-for="item in phoneBrandList" :key="item.id">
                  <a-checkbox
                    style="font-size: 12px"
                    :checked="adgroupform.phoneBrands.includes(item.name)"
                    @change="checkPhoneBrandItem($event, item)"
                  >
                    {{ item.name }}
                  </a-checkbox>
                </div>
              </div>
            </div>
            <div class="check_box">
              <div class="box_header">
                <span>已选品牌{{ adgroupform.phoneBrands.length }}项</span>
                <span class="clear_all" @click="handleClearAllPhoneBrand">清除全部</span>
              </div>
              <div class="box_list">
                <div
                  class="checked_item"
                  v-for="(item, index) in phoneBrandList"
                  :key="item.name"
                  v-show="adgroupform.phoneBrands.includes(item.name)"
                >
                  <span>{{ item.name }}</span>
                  <span class="close" @click="handleDeletePhoneBrandItem(index)"><a-icon type="close" /></span>
                </div>
              </div>
            </div>
          </div>
        </a-form-model-item>
      </div>
      <!-- 排期与出价 -->
      <a-form-model-item style="margin-bottom: 10px">
        <span class="weight_label" style="padding-top: 0; padding-bottom: 0">排期与出价</span>
      </a-form-model-item>
      <!-- 排期与出价---投放时段 -->
      <div class="form_item">
        <div style="margin-bottom: 10px">投放时段</div>
        <a-radio-group style="margin-bottom: 10px" v-model="timeStepType">
          <a-radio-button value="0">不限</a-radio-button>
          <a-radio-button value="1">指定时段</a-radio-button>
        </a-radio-group>
        <a-form-model-item v-show="timeStepType === '1'" prop="phoneBrands">
          <div class="time_step_wrapper">
            <div class="time_step_header">
              <div class="time_step_header_items">
                <div class="time_step_header_item">
                  <div></div>
                  <span>已选</span>
                </div>
                <div class="time_step_header_item">
                  <div></div>
                  <span>可选</span>
                </div>
              </div>
            </div>
            <div class="time_step_title">
              <div class="time_step_title_left"></div>
              <div class="time_step_title_right">
                <div class="time_step_title_right_top">
                  <div class="time_step_title_right_top_item">00:00 - 12:00</div>
                  <div class="time_step_title_right_top_item">12:00 - 24:00</div>
                </div>
                <div class="time_step_title_right_bottom">
                  <div v-for="item in 24" :key="item">{{ item - 1 }}</div>
                </div>
              </div>
            </div>
            <div class="time_step_content">
              <div class="time_step_content_left">
                <div class="week_day">星期一</div>
                <div class="week_day">星期二</div>
                <div class="week_day">星期三</div>
                <div class="week_day">星期四</div>
                <div class="week_day">星期五</div>
                <div class="week_day">星期六</div>
                <div class="week_day">星期日</div>
              </div>
              <div
                class="time_step_content_right"
                @mousemove="dragMousemove"
                @mouseup="dragMouseUp"
                @mouseleave="dragMouseUp"
              >
                <div class="time_step_content_right_box" :key="stepTimeKey">
                  <div class="time_step_day" v-for="(item, index) in adgroupform.timeStepArr" :key="index">
                    <div
                      class="time_step_hour"
                      @mousedown="timeStepMouseDown(index, c, child, $event)"
                      @mouseup="timeStepMouseUp(index, c, child)"
                      :class="{ active: child === 1 }"
                      v-for="(child, c) in item"
                      :key="c"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="time_step_footer">
              <span>可拖动鼠标选择时间段</span>
              <span class="clear_step_time" @click="initStepTime">清除</span>
            </div>
          </div>
        </a-form-model-item>
      </div>
      <!-- 排期与出价---投放日期 -->
      <div class="form_item">
        <div style="margin-bottom: 10px">投放日期</div>
        <a-radio-group style="display: block; margin: 10px 5px" v-model="dateIsClude">
          <a-radio :value="2">从今天起长时间投放</a-radio>
          <a-radio :value="0">设置开始和结束时间</a-radio>
        </a-radio-group>
        <a-form-model-item
          v-if="dateIsClude === 0"
          prop="rangePickerDate"
          :rules="[{ required: true, message: '请选择投放日期', trigger: 'change' }]"
        >
          <a-range-picker
            format="YYYY-MM-DD"
            :placeholder="['开始日期', '结束日期']"
            @change="ChangeDate"
            :value="adgroupform.rangePickerDate"
            :allowClear="true"
            :disabledDate="disabledDate"
          >
          </a-range-picker>
        </a-form-model-item>
      </div>
      <!-- 排期与出价---日限额 -->
      <div class="form_item">
        <div style="margin-bottom: 10px">日限额</div>
        <a-radio-group style="margin-bottom: 10px" v-model="dayBudgetType">
          <a-radio-button value="0">不限</a-radio-button>
          <a-radio-button value="1">指定金额</a-radio-button>
        </a-radio-group>
        <a-form-model-item
          prop="dayBudget"
          v-if="dayBudgetType === '1'"
          :rules="[{ required: true, message: '请输入日限额', trigger: 'blur' }]"
        >
          <a-input
            style="width: 400px"
            @change="changedayBudget"
            v-model.trim="adgroupform.dayBudget"
            placeholder="请输入日限额"
            addon-after="元/天"
          ></a-input>
        </a-form-model-item>
      </div>
      <!-- 排期与出价---出价方式 -->
      <div class="form_item">
        <div style="margin-bottom: 10px">出价方式</div>
        <a-form-model-item prop="bidType" :rules="[{ required: true, message: '请选择出价方式', trigger: 'change' }]">
          <a-select style="width: 400px" placeholder="请选择出价方式" v-model="adgroupform.bidType" allowClear>
            <a-select-option :value="0">CPM</a-select-option>
          </a-select>
        </a-form-model-item>
      </div>
      <!-- 排期与出价---出价 -->
      <div class="form_item">
        <div style="margin-bottom: 10px">出价</div>
        <a-form-model-item prop="bidPrice" :rules="[{ required: true, message: '请输入出价', trigger: 'blur' }]">
          <a-input
            style="width: 400px"
            @change="changebidPrice"
            v-model.trim="adgroupform.bidPrice"
            placeholder="请输入出价"
          ></a-input>
        </a-form-model-item>
      </div>
      <!-- 排期与出价---广告组名称 -->
      <div class="form_item">
        <div style="margin-bottom: 10px">广告组名称</div>
        <a-form-model-item prop="name" :rules="[{ required: true, message: '请输入广告组名称', trigger: 'blur' }]">
          <a-input style="width: 400px" v-model.trim="adgroupform.name" placeholder="请输入广告组名称"></a-input>
        </a-form-model-item>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import {
  getAppPlaceTree,
  getDirectionalInstallAppList,
  getPhoneBrandList,
  addAdPlanGroup,
  updateAdPlanGroup,
  getAdPlanGroupInfoByPlanGroupId
} from '@/api/advertisementStep'
import mixDate from '@/mixins/initDate'
import { getAreaList } from '@/api/common'
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  mixins: [mixDate],
  data () {
    return {
      // common
      layout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 }
      },
      steps: 1,
      isAdd: false,
      editGroupId: undefined,
      adgroupform: {
        wxMiniProId: undefined, // 小程序ID
        putTargetUrl: undefined, // 投放目标地址
        impressTrackUrl: undefined, // 展示检测URL
        actionType: 1, // 交互类型
        deeplinkUrl: undefined, // 唤醒地址
        clickTrackUrl: undefined, // 点击检测URL
        placeIdList: [], // 媒体选择
        areas: [], // 地域选择
        platform: -1, // 平台
        equipment: -1, // 设备类型
        network: [], // 网络类型
        operator: [], // 运营商
        rta: 0,
        rtaPlaceId: undefined,
        rtaId: undefined,
        installApps: [], // 安装列表
        phoneBrands: [], // 手机品牌
        timeStepArr: [
          // 周一
          [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
          ],
          // 周二
          [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
          ],
          // 周三
          [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
          ],
          // 周四
          [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
          ],
          // 周五
          [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
          ],
          // 周六
          [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
          ],
          // 周日
          [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
          ]
        ],
        endDate: undefined, // 投放开始日期
        dateStart: undefined, // 投放结束日期
        rangePickerDate: ['', ''],
        dayBudget: undefined, // 日限额
        bidType: undefined, // 出价方式
        bidPrice: undefined, // 出价
        name: undefined // 广告组名称
      },
      // 媒体选择
      meidatype: '0', // 媒体类型
      allMediaData: {}, // 媒体数据
      allPlaceList: [], // 全部广告位列表
      mediaList: [], // 当前媒体类型数据
      currentPlaceList: [], // 当前广告位列表
      currentAppId: undefined, // 当前应用列表
      currentAppIndex: undefined, // 当前应用所在索引
      mediaSearchKey: undefined,
      // 地域
      areaType: '0', // 地域规则
      areaList: [], // 地域数据（包括国家）
      provinceList: [], // 省列表
      allAreaList: [], // 全部的城市列表
      currentCityeList: [], // 当前城市列表
      currentProvinceIndex: undefined, // 当前省的索引
      currentProvinceValue: undefined, // 当前省的value值
      // 安装列表
      installType: '0', // 安装列表规则
      installIsClude: 0, // 是否包括安装列表
      installAppList: [], // 安装列表数据
      // 手机品牌
      phoneType: '0', // 手机品牌规则
      phoneBrandList: [], // 手机品牌数据
      // 投放时段
      timeStepType: '0', // 投放时段规则
      isMouseDown: false, // 鼠标按下标志
      startInfo: {
        // 鼠标按下起始信息
        day: 0,
        hour: 0,
        active: 0,
        x: undefined,
        y: undefined
      },
      stepTimeKey: 1, // key
      contentDom: null, // dom
      // 投放日期
      // dateType: '0', // 投放日期类型
      dateIsClude: 2, // 投放日期是否包含
      // 日限额
      dayBudgetType: '0'
      // RTA
    }
  },
  inject: ['currentAdPlanId', 'changePlanId', 'currentAdGroupId', 'changeGroupId', 'changeLoading'],
  watch: {
    $route: {
      async handler (val) {
        this.changeLoading(true)
        this.steps = val && val.query && +val.query.steps
        this.isAdd = val.name === 'addadsteps'
        if (this.steps === 2) {
          if (!this.isAdd) {
            this.editGroupId = val && val.query && val.query.adGroupId
            const form = await this.initFormData()
            this.adgroupform = { ...this.adgroupform, ...form }
          }
          // 媒体选择
          await this.getAppPlaceTree()
          this.mediaList = this.allMediaData[this.meidatype]
          await this.getAllPlaceList()
          // 地域
          await this.getAreaList()
          await this.getAllAreaList()
          // 安装列表
          this.getDirectionalInstallAppList()
          // RTA列表
          // 手机品牌
          this.getPhoneBrandList()
          if (this.isAdd) {
            // 投放日期
            const { endDate, startDate, end, start } = this.initDate(0, 0)
            this.adgroupform.rangePickerDate = [start, end]
            this.adgroupform.dateEnd = endDate
            this.adgroupform.dateStart = startDate
          }
        }
        this.$nextTick(() => {
          this.changeLoading(false)
        })
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    this.contentDom = document.getElementsByClassName('time_step_content_right')[0]
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0]
    }),
    showAlliancePlace () {
      return this.role !== 'ADVERTISERS' && this.role !== 'AGENT_ADVERTISERS' && this.role !== 'AGENT '
    },
    // 媒体选择
    // 媒体列表comp
    mediaListComp () {
      if (this.mediaSearchKey) {
        return this.mediaList.filter((item) => item.appName.includes(this.mediaSearchKey) || item.appId.includes(this.mediaSearchKey))
      }
      return this.mediaList
    },
    // 媒体选择---应用全选indeterminate
    indeterminateAllMedia () {
      let num = 0
      let allNum = 0
      this.mediaListComp.forEach((item) => {
        item.placeList.forEach((child) => {
          this.adgroupform.placeIdList.includes(child.placeId) && num++
          allNum++
        })
      })
      return num > 0 && num !== allNum
    },
    // 媒体选择---应用全选checkAll
    checkAllMedia () {
      let num = 0
      let allNum = 0
      this.mediaListComp.forEach((item) => {
        item.placeList.forEach((child) => {
          this.adgroupform.placeIdList.includes(child.placeId) && num++
          allNum++
        })
      })
      return num === allNum
    },
    // 媒体选择---广告位全选indeterminate
    indeterminatePlace () {
      return this.currentPlaceList.some((item) => item.checked) && !this.currentPlaceList.every((item) => item.checked)
    },
    // 媒体选择---广告位全选checkAll
    checkAllPlace () {
      return this.currentPlaceList.every((item) => item.checked)
    },
    // 地域
    // 地域---选择全部地区checkAll
    checkAllCountry () {
      return this.adgroupform.areas.length === this.allAreaList.length
    },
    // 地域---选择全部地区indeterminate
    indeterminateAllCountry () {
      return this.adgroupform.areas.length > 0 && this.adgroupform.areas.length < this.allAreaList.length
    },
    // 地域---选择全部城市indeterminate
    indeterminateCity () {
      return this.currentCityeList.some((item) => item.checked) && !this.currentCityeList.every((item) => item.checked)
    },
    // 地域---选择全部城市checkAll
    checkAllCity () {
      return this.currentCityeList.every((item) => item.checked)
    },
    // 安装列表
    // 安装列表---安装列表indeterminate
    indeterminateInstallApp () {
      return this.adgroupform.installApps.length > 0 && this.installAppList.length > this.adgroupform.installApps.length
    },
    // 安装列表---安装列表checkAll
    checkAllInstallApp () {
      return this.installAppList.length === this.adgroupform.installApps.length
    },
    // 安装列表
    // 安装列表---安装列表indeterminate
    indeterminatePhoneBrand () {
      return this.adgroupform.phoneBrands.length > 0 && this.phoneBrandList.length > this.adgroupform.phoneBrands.length
    },
    // 安装列表---安装列表checkAll
    checkAllPhoneBrand () {
      return this.phoneBrandList.length === this.adgroupform.phoneBrands.length
    }
  },
  methods: {
    // 媒体选择
    // 媒体选择---清除单个广告位
    handleClearPlaceItem (id) {
      const index = this.adgroupform.placeIdList.indexOf(id)
      this.adgroupform.placeIdList.splice(index, 1)
      this.mediaList.forEach((item) => {
        this.mediaDealData(item)
      })
    },
    // 媒体选择---清除全部已选广告位
    handleClearAllMedia () {
      this.adgroupform.placeIdList = []
      this.mediaList.forEach((item) => {
        this.mediaDealData(item)
      })
    },
    // 媒体选择---广告位选择
    checkPlaceItem (item) {
      const index = this.adgroupform.placeIdList.indexOf(item.placeId)
      index !== -1 && this.adgroupform.placeIdList.splice(index, 1)
      index === -1 && this.adgroupform.placeIdList.push(item.placeId)
      this.mediaList.forEach((item) => {
        this.mediaDealData(item)
      })
    },
    // 媒体选择---广告位全选
    CheckAllPlaceChange (e) {
      if (e.target.checked) {
        const list = this.currentPlaceList.map((item) => item.placeId)
        this.adgroupform.placeIdList = Array.from(new Set([...this.adgroupform.placeIdList, ...list]))
      } else {
        this.currentPlaceList.forEach((item) => {
          const index = this.adgroupform.placeIdList.indexOf(item.placeId)
          index !== -1 && this.adgroupform.placeIdList.splice(index, 1)
        })
      }
      this.mediaList.forEach((item) => {
        this.mediaDealData(item)
      })
    },
    // 媒体选择---点击应用左侧选择框
    handleCheckedAppItemAll (item) {
      this.handleClickAppMedia(item)
      const app = this.mediaList.find((child) => child.appId === item.appId)
      if (item.checkAll) {
        app.checkAll = false
        app.indeterminate = false
        item.placeList.forEach((child, c) => {
          // 获取该项在adgroupform.placeIdList中的索引
          const i = this.adgroupform.placeIdList.indexOf(child.placeId)
          // 移除
          this.adgroupform.placeIdList.splice(i, 1)
          // 选择为false
          app.placeList[c].checked = false
        })
      } else {
        app.checkAll = true
        app.indeterminate = false
        item.placeList.forEach((child, c) => {
          const i = this.adgroupform.placeIdList.indexOf(child.placeId)
          if (i === -1) {
            app.placeList[c].checked = true
            this.adgroupform.placeIdList.push(child.placeId)
          }
        })
      }
    },
    // 媒体选择---点击应用
    handleClickAppMedia (item) {
      const curIndex = this.mediaList.findIndex((child) => child.appId === item.appId)
      this.currentAppIndex = curIndex
      this.currentAppId = item.appId
      this.currentPlaceList = item.placeList
    },
    // 媒体选择---应用全选事件
    onCheckAllMediaChange (e) {
      if (e.target.checked) {
        const placeList = []
        this.mediaList.forEach((item) => {
          item.placeList.forEach((child) => {
            placeList.push(child.placeId)
          })
        })
        this.adgroupform.placeIdList = Array.from(new Set([...this.adgroupform.placeIdList, ...placeList]))
      } else {
        this.mediaList.forEach((item) => {
          item.placeList.forEach((child) => {
            const i = this.adgroupform.placeIdList.indexOf(child.placeId)
            i !== -1 && this.adgroupform.placeIdList.splice(i, 1)
          })
        })
      }
      this.mediaList.forEach((item) => {
        this.mediaDealData(item)
      })
    },
    // 媒体选择---获取当前媒体类型下的所有广告位列表
    getAllPlaceList () {
      let placeList = []
      const allianceMediaData = this.allMediaData['1'] || []
      const appList = [...this.allMediaData['0'], ...allianceMediaData]
      appList.forEach((item) => {
        placeList = [...placeList, ...item.placeList]
      })
      this.allPlaceList = placeList
    },
    // 媒体选择---获取媒体数据
    async getAppPlaceTree () {
      const resp = await getAppPlaceTree()
      resp.data['0'].forEach((item) => {
        this.mediaDealData(item)
      })
      resp.data['1'] &&
        resp.data['1'].forEach((item) => {
          this.mediaDealData(item)
        })
      this.allMediaData = resp && resp.data
    },
    // 媒体选择---处理App数组项中的数据选中
    mediaDealData (item) {
      item.checkAll = false
      item.indeterminate = false
      let num = 0
      item.placeList.forEach((child) => {
        child.checked = false
        if (this.adgroupform.placeIdList.includes(child.placeId)) {
          child.checked = true
          num++
        }
      })
      num > 0 && num !== item.placeList.length && (item.indeterminate = true)
      num === item.placeList.length && (item.checkAll = true)
    },
    // 媒体选择---修改自有媒体和联盟媒体
    onChangeMedia (e) {
      this.mediaList = this.allMediaData[e.target.value]
      this.getAllPlaceList()
      this.currentAppId = undefined
      this.currentAppIndex = undefined
      this.currentPlaceList = []
      this.mediaList.forEach((item) => {
        this.mediaDealData(item)
      })
    },
    // 地域
    // 地域---省份选择框勾选事件
    handleCheckedProviceItemAll (item, index) {
      this.handleClickProviceItemLabel(item, index)
      if (item.checked) {
        this.provinceList[index].checked = false
        this.provinceList[index].indeterminate = false
        item.children.forEach((child, c) => {
          const i = this.adgroupform.areas.indexOf(child.value)
          this.adgroupform.areas.splice(i, 1)
          this.provinceList[index].children[c].checked = false
        })
      } else {
        this.provinceList[index].checked = true
        this.provinceList[index].indeterminate = false
        item.children.forEach((child, c) => {
          const i = this.adgroupform.areas.indexOf(child.value)
          if (i === -1) {
            this.adgroupform.areas.push(child.value)
            this.provinceList[index].children[c].checked = true
          }
        })
      }
    },
    // 地域---城市全选框勾选事件
    CheckAllCityChange (e) {
      if (e.target.checked) {
        const list = this.currentCityeList.map((item) => item.value)
        this.adgroupform.areas = Array.from(new Set([...this.adgroupform.areas, ...list]))
      } else {
        this.currentCityeList.forEach((item) => {
          const index = this.adgroupform.areas.indexOf(item.value)
          index !== -1 && this.adgroupform.areas.splice(index, 1)
        })
      }
      this.provinceList.forEach((item) => {
        this.areaDealData(item)
      })
    },
    // 地域---省份label点击事件
    handleClickProviceItemLabel (item, index) {
      this.currentCityeList = item.children
      this.currentProvinceIndex = index
      this.currentProvinceValue = item.value
    },
    // 地域---区域全选
    checkAllAreaChecked (e) {
      if (e.target.checked) {
        this.adgroupform.areas = this.allAreaList.map((item) => item.value)
      } else {
        this.adgroupform.areas = []
      }
      this.provinceList.forEach((item) => {
        this.areaDealData(item)
      })
    },
    // 地域---获取地域列表
    async getAreaList () {
      const resp = await getAreaList()
      // 改造value
      const CVal = resp.data[0].value
      resp.data[0].children.forEach((item, index) => {
        const pVal = item.value
        item.children.forEach((child) => {
          child.value = `${CVal}-${pVal}-${child.value}`
        })
      })
      resp.data[0].children.forEach((item) => {
        this.areaDealData(item)
      })
      this.areaList = resp.data || []
      this.provinceList = this.areaList[0].children
    },
    // 地域---处理地域数据中的全选
    areaDealData (item) {
      let num = 0
      item.indeterminate = false
      item.checked = false
      item.children.forEach((child) => {
        child.checked = false
        this.adgroupform.areas.includes(child.value) && (child.checked = true) && num++
      })
      num > 0 && num !== item.children.length && (item.indeterminate = true)
      num === item.children.length && (item.checked = true)
    },
    // 地域---获取全部城市数据
    getAllAreaList () {
      let allAreaList = []
      const provinceList = this.areaList[0].children
      provinceList
        .map((item) => item.children)
        .forEach((child) => {
          allAreaList = [...allAreaList, ...child]
        })
      this.allAreaList = allAreaList
    },
    // 地域---勾选城市事件
    checkCityItem (item) {
      const index = this.adgroupform.areas.indexOf(item.value)
      index !== -1 && this.adgroupform.areas.splice(index, 1)
      index === -1 && this.adgroupform.areas.push(item.value)
      this.provinceList.forEach((item) => {
        this.areaDealData(item)
      })
    },
    // 地域---获取指定id的地域名称
    getAreasLabel (id) {
      const info = this.allAreaList.find((item) => item.value === id)
      return info ? info.label : ''
    },
    // 地域---删除单个选中的地域
    handleDeleteCityItem (id, index) {
      this.adgroupform.areas.splice(index, 1)
      this.provinceList.forEach((item) => {
        this.areaDealData(item)
      })
    },
    // 地域---清除选中地域
    handleClearAllCity () {
      this.adgroupform.areas = []
      this.provinceList.forEach((item) => {
        this.areaDealData(item)
      })
    },
    // 网络类型
    // 网络类型---不限选择事件
    handleChangeNetwork (e) {
      if (e.target.checked) {
        this.adgroupform.network = []
      }
    },
    // 运营商
    // 运营商---不限选择事件
    handleChangeOperator (e) {
      if (e.target.checked) {
        this.adgroupform.operator = []
      }
    },
    // RTA
    // 安装列表
    // 安装列表---获取安装列表
    async getDirectionalInstallAppList () {
      const resp = await getDirectionalInstallAppList()
      this.installAppList = resp.data || []
    },
    // 安装列表---单选
    checkInstallAppItem (e, item) {
      e.target.checked && this.adgroupform.installApps.push(item.id)
      !e.target.checked &&
        (this.adgroupform.installApps = this.adgroupform.installApps.filter((child) => child !== item.id))
    },
    // 安装列表---全选
    handleCheckInstallApp (e) {
      e.target.checked && (this.adgroupform.installApps = this.installAppList.map((item) => item.id))
      !e.target.checked && (this.adgroupform.installApps = [])
    },
    // 安装列表---删除单个选中应用
    handleDeleteInstallAppItem (index) {
      this.adgroupform.installApps.splice(index, 1)
    },
    // 安装列表---清除全部选中应用
    handleClearAllInstallApp () {
      this.adgroupform.installApps = []
    },
    // 手机品牌
    // 手机品牌---获取手机品牌列表
    async getPhoneBrandList () {
      const resp = await getPhoneBrandList()
      this.phoneBrandList = resp.data || []
    },
    // 手机品牌---单选
    checkPhoneBrandItem (e, item) {
      e.target.checked && this.adgroupform.phoneBrands.push(item.name)
      !e.target.checked &&
        (this.adgroupform.phoneBrands = this.adgroupform.phoneBrands.filter((child) => child !== item.name))
    },
    // 手机品牌---全选
    handleCheckPhoneBrand (e) {
      e.target.checked && (this.adgroupform.phoneBrands = this.phoneBrandList.map((item) => item.name))
      !e.target.checked && (this.adgroupform.phoneBrands = [])
    },
    // 手机品牌---删除单个选中品牌
    handleDeletePhoneBrandItem (index) {
      this.adgroupform.phoneBrands.splice(index, 1)
    },
    // 手机品牌---清除全部选中品牌
    handleClearAllPhoneBrand () {
      this.adgroupform.phoneBrands = []
    },
    // 投放时段
    // 投放时段---时间段按下事件
    timeStepMouseDown (day, hour, active, e) {
      this.isMouseDown = true
      this.startInfo = {
        day,
        hour,
        active,
        x: e.clientX,
        y: e.clientY
      }
    },
    // 投放时段---时间段抬起事件
    timeStepMouseUp (day, hour, active) {
      if (!this.isMouseDown) {
        return
      }
      const endInfo = {
        day,
        hour,
        active
      }
      const targetActive = this.startInfo.active === 1 ? 0 : 1
      const startX = this.startInfo.hour > endInfo.hour ? endInfo.hour : this.startInfo.hour
      const startY = this.startInfo.day > endInfo.day ? endInfo.day : this.startInfo.day
      const endX = this.startInfo.hour < endInfo.hour ? endInfo.hour : this.startInfo.hour
      const endY = this.startInfo.day < endInfo.day ? endInfo.day : this.startInfo.day
      for (let hour = startX; hour <= endX; hour++) {
        for (let day = startY; day <= endY; day++) {
          this.adgroupform.timeStepArr[day][hour] = targetActive
        }
      }
      this.stepTimeKey = new Date().getTime()
      this.isMouseDown = false
    },
    // 投放时段---投放时间清空按钮
    initStepTime () {
      this.adgroupform.timeStepArr.forEach((item, i) => {
        item.forEach((child, c) => {
          this.adgroupform.timeStepArr[i][c] = 0
        })
      })
      this.stepTimeKey = new Date().getTime()
    },
    // 投放时段---朦层抬起事件
    dragMouseUp (e) {
      const dragDom = document.getElementsByClassName('drag_dom')[0]
      dragDom.style.display = 'none'
      this.isMouseDown = false
    },
    // 投放时段---朦层移动事件
    dragMousemove (e) {
      if (!this.isMouseDown) {
        return
      }
      let dragDom = document.getElementsByClassName('drag_dom')[0]
      if (dragDom) {
        dragDom.style.width = '0px'
        dragDom.style.height = '0px'
      } else {
        dragDom = document.createElement('div')
        dragDom.className = 'drag_dom'
        dragDom.style.background = 'aqua'
        dragDom.style.opacity = '0.3'
        dragDom.style.zIndex = '9999'
        dragDom.style.position = 'absolute'
        dragDom.style.display = 'none'
        this.contentDom.appendChild(dragDom)
      }
      const relativeTop = this.contentDom.getBoundingClientRect().top
      const relativeLeft = this.contentDom.getBoundingClientRect().left
      const width = Math.abs(e.clientX - this.startInfo.x)
      const height = Math.abs(e.clientY - this.startInfo.y)
      dragDom.style.width = width - 4 + 'px'
      dragDom.style.height = height - 4 + 'px'
      const startPlaceX = e.clientX - this.startInfo.x > 0 ? this.startInfo.x - relativeLeft : e.clientX - relativeLeft
      const startPlaceY = e.clientY - this.startInfo.y > 0 ? this.startInfo.y - relativeTop : e.clientY - relativeTop
      dragDom.style.left = startPlaceX + 2 + 'px'
      dragDom.style.top = startPlaceY + 2 + 'px'
      dragDom.style.display = 'inline-block'
    },
    // 投放日期
    // 投放日期---时间修改
    ChangeDate (date, dateString) {
      this.adgroupform.dateEnd = dateString[1]
      this.adgroupform.dateStart = dateString[0]
      this.adgroupform.rangePickerDate = date
    },
    // 投放日期---不可选时间
    disabledDate (current) {
      return current < this.initDate(0, 1).end
    },
    // 日限额
    // 日限额---输入框事件
    changedayBudget (e) {
      if (!+e.target.value && +e.target.value !== 0) {
        this.adgroupform.dayBudget = undefined
      }
      if (e.target.value < 0) {
        this.adgroupform.dayBudget = undefined
      }
    },
    // 出价
    // 出价---输入框事件
    changebidPrice (e) {
      if (!+e.target.value) {
        this.adgroupform.bidPrice = undefined
      }
      if (e.target.value <= 0) {
        this.adgroupform.bidPrice = undefined
      }
    },
    handleSubmitAdGroup () {
      return new Promise((resolve) => {
        this.$refs.adgroupform.validate(async (valid) => {
          if (valid) {
            const query = this.initSubmitQuery()
            if (this.isAdd) {
              this.changeLoading(true)
              const resp = await addAdPlanGroup(query)
              resp.code === 200 && this.changeGroupId(resp.data.id)
              resolve(resp)
              setTimeout(() => {
                this.changeLoading(false)
              }, 1000)
            } else {
              this.changeLoading(true)
              query.id = this.editGroupId
              query.newPlaceIdList = query.placeIdList
              query.placeIdList = query.oldPlaceIdList
              delete query.oldPlaceIdList
              const resp = await updateAdPlanGroup(query)
              resolve(resp)
              setTimeout(() => {
                this.changeLoading(false)
              }, 1000)
            }
          }
        })
      })
    },
    initSubmitQuery () {
      const query = {
        sts: !this.isAdd ? this.adgroupform.sts : undefined,
        planId: this.currentAdPlanId() || this.adgroupform.planId, // 新增 || 编辑
        actionType: this.adgroupform.actionType,
        placeIdList: this.adgroupform.placeIdList,
        // 编辑保存旧广告位列表
        oldPlaceIdList: this.adgroupform.oldPlaceIdList,
        wxMiniProId: this.adgroupform.wxMiniProId,
        putTargetUrl: this.adgroupform.putTargetUrl,
        deeplinkUrl: this.adgroupform.deeplinkUrl,
        impressTrackUrl: this.adgroupform.impressTrackUrl,
        clickTrackUrl: this.adgroupform.clickTrackUrl,
        // 日限额为0时即为不限，传-1
        dayBudget: this.dayBudgetType === '1' ? +this.adgroupform.dayBudget : 10000000,
        bidType: this.adgroupform.bidType,
        bidPrice: this.adgroupform.bidPrice,
        name: this.adgroupform.name
      }
      const adPlanGroupRuleLinkVoList = []
      // 地域
      if (this.adgroupform.areas.length > 0 && this.areaType === '1') {
        const areaObj = {
          ruleId: 2,
          ruleType: 0,
          ruleContentList: this.adgroupform.areas
        }
        adPlanGroupRuleLinkVoList.push(areaObj)
      }
      // 平台
      if (this.adgroupform.platform !== -1) {
        const platformObj = {
          ruleId: 3,
          ruleType: 0,
          ruleContentList: [this.adgroupform.platform]
        }
        adPlanGroupRuleLinkVoList.push(platformObj)
      }
      // 设备类型
      if (this.adgroupform.equipment !== -1) {
        const equipmentObj = {
          ruleId: 4,
          ruleType: 0,
          ruleContentList: [this.adgroupform.equipment]
        }
        adPlanGroupRuleLinkVoList.push(equipmentObj)
      }
      // 网络
      if (this.adgroupform.network.length > 0) {
        const networkObj = {
          ruleId: 5,
          ruleType: 0,
          ruleContentList: this.adgroupform.network
        }
        adPlanGroupRuleLinkVoList.push(networkObj)
      }
      // 运营商
      if (this.adgroupform.operator.length > 0) {
        const operatorObj = {
          ruleId: 6,
          ruleType: 0,
          ruleContentList: this.adgroupform.operator
        }
        adPlanGroupRuleLinkVoList.push(operatorObj)
      }
      // RTA
      if (+this.adgroupform.rta !== 0) {
        const rtaObj = {
          ruleId: 10,
          ruleType: this.adgroupform.rta,
          ruleContentList: [this.adgroupform.rtaPlaceId + ',' + this.adgroupform.rtaId]
        }
        adPlanGroupRuleLinkVoList.push(rtaObj)
      }
      // 安装列表
      if (this.adgroupform.installApps.length > 0 && this.installType === '1') {
        const installAppsObj = {
          ruleId: 8,
          ruleType: this.installIsClude,
          ruleContentList: this.adgroupform.installApps
        }
        adPlanGroupRuleLinkVoList.push(installAppsObj)
      }
      // 手机品牌
      if (this.adgroupform.phoneBrands.length > 0 && this.phoneType === '1') {
        const phoneBrandsObj = {
          ruleId: 7,
          ruleType: 0,
          ruleContentList: this.adgroupform.phoneBrands
        }
        adPlanGroupRuleLinkVoList.push(phoneBrandsObj)
      }
      // 投放时段
      if (this.timeStepType === '1') {
        const timeStepObj = {
          ruleId: 1,
          ruleType: 0,
          timeRuleContentVoList: this.adgroupform.timeStepArr
        }
        adPlanGroupRuleLinkVoList.push(timeStepObj)
      }
      // 投放日期
      let dateList = []
      const { startDate } = this.initDate(0, 0)
      if (this.isAdd) {
        // 添加保存
        dateList = this.dateIsClude ? [startDate] : [this.adgroupform.dateStart, this.adgroupform.dateEnd]
      } else {
        // 编辑保存
        dateList = this.dateIsClude
          ? [this.startDateTemp ? this.startDateTemp : startDate] // 判断回显时是否是 从今天开始
          : [this.adgroupform.dateStart, this.adgroupform.dateEnd]
      }
      const dateObj = {
        ruleId: 9,
        ruleType: this.dateIsClude,
        ruleContentList: dateList
      }
      adPlanGroupRuleLinkVoList.push(dateObj)
      query.adPlanGroupRuleLinkVoList = adPlanGroupRuleLinkVoList
      console.log(query.wxMiniProId, 123)
      return query
    },
    async initFormData () {
      const resp = await getAdPlanGroupInfoByPlanGroupId({ id: this.editGroupId })
      // simple
      const { adPlanGroup, adPlanGroupRuleLinkVoList, adPlanPutOnPlaceResponseVoMap } = resp.data
      const form = {
        sts: adPlanGroup.sts,
        planId: adPlanGroup.planId,
        actionType: adPlanGroup.actionType,
        wxMiniProId: adPlanGroup.wxMiniProId,
        putTargetUrl: adPlanGroup.putTargetUrl,
        deeplinkUrl: adPlanGroup.deeplinkUrl,
        impressTrackUrl: adPlanGroup.impressTrackUrl,
        clickTrackUrl: adPlanGroup.clickTrackUrl,
        // 日限额为0时即为不限，传-1
        dayBudget: +adPlanGroup.dayBudget === 10000000 ? undefined : +adPlanGroup.dayBudget,
        bidType: adPlanGroup.bidType,
        bidPrice: adPlanGroup.bidPrice,
        name: adPlanGroup.name
      }
      this.dayBudgetType = adPlanGroup.dayBudget === 10000000 ? '0' : '1'
      form.placeIdList = []
      adPlanPutOnPlaceResponseVoMap[0].forEach((item) => {
        item.placeList.forEach((child) => {
          child.isChoosed && form.placeIdList.push(child.placeId)
        })
      })
      adPlanPutOnPlaceResponseVoMap[1] &&
        adPlanPutOnPlaceResponseVoMap[1].forEach((item) => {
          item.placeList.forEach((child) => {
            child.isChoosed && form.placeIdList.push(child.placeId)
          })
        })
      form.oldPlaceIdList = JSON.parse(JSON.stringify(form.placeIdList))
      // difficult
      adPlanGroupRuleLinkVoList.forEach((item) => {
        // 投放时段
        if (+item.ruleId === 1) {
          form.timeStepArr = item.timeRuleContentVoList
          this.timeStepType = '1'
        }
        // 地域
        if (+item.ruleId === 2) {
          form.areas = item.ruleContentList
          this.areaType = '1'
        }
        // 平台
        if (+item.ruleId === 3) {
          form.platform = item.ruleContentList[0]
        }
        // 设备类型
        if (+item.ruleId === 4) {
          form.equipment = item.ruleContentList[0]
        }
        // 网络类型
        if (+item.ruleId === 5) {
          form.network = item.ruleContentList
        }
        // 运营商
        if (+item.ruleId === 6) {
          form.operator = item.ruleContentList
        }
        // RTA
        if (+item.ruleId === 10) {
          const rtaContent = item.ruleContentList.splice(',')
          form.rtaPlaceId = rtaContent[0]
          form.rtaId = rtaContent[1]
          form.rta = +item.ruleType
        }
        // 手机品牌
        if (+item.ruleId === 7) {
          form.phoneBrands = item.ruleContentList.map((item) => item)
          this.phoneType = '1'
        }
        // 安装列表
        if (+item.ruleId === 8) {
          form.installApps = item.ruleContentList
          this.installIsClude = +item.ruleType
          this.installType = '1'
        }
        // 投放日期
        if (+item.ruleId === 9) {
          this.dateIsClude = +item.ruleType
          this.dateIsClude === 2 && (this.startDateTemp = item.ruleContentList[0])
          form.dateStart = item.ruleContentList[0]
          this.adgroupform.rangePickerDate[0] = moment(item.ruleContentList[0], 'YYYY-MM-DD')
          this.dateIsClude === 0 && item.ruleContentList[1] && (form.dateEnd = item.ruleContentList[1])
          this.dateIsClude === 0 &&
            item.ruleContentList[1] &&
            (this.adgroupform.rangePickerDate[1] = moment(item.ruleContentList[1], 'YYYY-MM-DD'))
          !this.dateIsClude === 0 &&
            (this.adgroupform.rangePickerDate[1] = moment(item.ruleContentList[0], 'YYYY-MM-DD'))
        }
      })
      return form
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';
.ad-group-form-container {
  .form_item {
    padding-left: 15px;
    margin-bottom: 20px;
    .form_item_label {
      margin-bottom: 10px;
      display: inline-block;
    }
  }
  .weight_label {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    vertical-align: top;
    margin-right: 10px;
    display: inline-block;
    padding: 15px;
  }
  // 媒体选择
  .media_wrapper {
    width: 800px;
    height: 250px;
    padding: 5px;
    display: flex;
    .app_box,
    .place_box {
      width: 250px;
      border: 1px solid #e6e6e6;
      margin-right: 10px;
      box-sizing: border-box;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      .box_header {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e6e6e6;
        background: #fafafa;
        padding-left: 10px;
        font-size: 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .search-box{
        border-bottom: 1px solid #e6e6e6;
        padding: 0 10px;
        height: 30px;
        line-height: 30px;
        input{
          border: none;
          outline: none;
          width: 100%;
          &:focus{
            border: none;
            outline: none;
          }
        }
      }
      .checked_box {
        flex-grow: 1;
        height: 0;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          box-shadow: inset 0 0 5px fade(@primary-color, 3%);
          background: fade(@primary-color, 20%);
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px fade(@primary-color, 3%);
          border-radius: 0;
          background: rgba(0, 0, 0, 0.1);
        }
        .checked_item {
          height: 30px;
          line-height: 30px;
          padding-left: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          &.app_checked_item {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            .desc {
              font-size: 12px;
              cursor: pointer;
              flex-grow: 1;
              padding: 0 10px;
              display: flex;
              justify-content: space-between;
              &.active {
                color: @primary-color;
                font-weight: 600;
              }
              .name {
                flex-grow: 1;
                line-height: 30px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
    .hasChecked_box {
      flex-grow: 1;
      box-sizing: border-box;
      border: 1px solid #e6e6e6;
      border-radius: 3px;
      width: 0;
      display: flex;
      flex-direction: column;
      .hasChecked_header {
        background: #fafafa;
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e6e6e6;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        .clear_all {
          cursor: pointer;
          color: @primary-color;
        }
      }
      .checked_list {
        flex-grow: 1;
        height: 0;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        &::-webkit-scrollbar {
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          box-shadow: inset 0 0 5px fade(@primary-color, 3%);
          background: fade(@primary-color, 20%);
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px fade(@primary-color, 3%);
          border-radius: 0;
          background: rgba(0, 0, 0, 0.1);
        }
        .checked_item {
          height: 30px;
          line-height: 30px;
          margin: 3px 0;
          font-size: 12px;
          padding: 0 10px;
          display: flex;
          justify-content: space-between;
          background: fade(@primary-color, 2%);
          border-radius: 5px;
          .name {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .close {
            cursor: pointer;
          }
        }
      }
    }
  }
  // 地域
  .area_wrapper {
    user-select: none;
    height: 250px;
    width: 850px;
    padding: 5px;
    display: flex;
    .country_box,
    .province_box,
    .city_box {
      width: 200px;
      border: 1px solid #e6e6e6;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
    }
    .checked_area_box {
      width: 0;
      flex-grow: 1;
      border: 1px solid #e6e6e6;
      display: flex;
      flex-direction: column;
      .box_header {
        display: flex;
        justify-content: space-between;
        .close {
          color: @primary-color;
          cursor: pointer;
        }
      }
      .check_list {
        padding: 0 5px;
        .check_item {
          margin: 3px 0;
          height: 30px;
          line-height: 30px;
          display: flex;
          background: fade(@primary-color, 2%);
          border-radius: 5px;
          padding: 0 5px;
          font-size: 12px;
          justify-content: space-between;
          .close {
            cursor: pointer;
          }
        }
      }
    }
    .box_header {
      background: #fafafa;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      border-bottom: 1px solid #e6e6e6;
      font-size: 12px;
    }
    .check_list {
      flex-grow: 1;
      height: 0;
      overflow-y: auto;
      padding: 0 10px;
      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        background: fade(@primary-color, 20%);
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
      .country_item {
        display: flex;
        height: 30px;
        line-height: 30px;
        margin: 3px 0;
        font-size: 12px;
        font-weight: 600;
        color: @primary-color;
        .name {
          flex-grow: 1;
          width: 0;
          padding-left: 5px;
          display: flex;
          justify-content: space-between;
        }
      }
      .province_item {
        display: flex;
        height: 30px;
        line-height: 30px;
        margin: 3px 0;
        font-size: 12px;
        .desc {
          flex-grow: 1;
          width: 0;
          padding: 0 5px;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          &.active {
            color: @primary-color;
            font-weight: 600;
          }
        }
      }
      .city_item {
        height: 30px;
        line-height: 30px;
        margin: 3px 0;
      }
    }
  }
  // 安装列表
  .install_wrapper {
    user-select: none;
    width: 800px;
    height: 250px;
    padding: 5px;
    display: flex;
    font-size: 12px;
    .list_box,
    .check_box {
      border: 1px solid #e6e6e6;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      .box_header {
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        background: #fafafa;
        border-bottom: 1px solid #e6e6e6;
        .clear_all {
          color: @primary-color;
          cursor: pointer;
        }
      }
      .box_list {
        flex-grow: 1;
        height: 0;
        overflow-y: auto;
        padding: 0 10px;
        &::-webkit-scrollbar {
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          box-shadow: inset 0 0 5px fade(@primary-color, 3%);
          background: fade(@primary-color, 20%);
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px fade(@primary-color, 3%);
          border-radius: 0;
          background: rgba(0, 0, 0, 0.1);
        }
        .install_item {
          height: 30px;
          line-height: 30px;
        }
        .checked_item {
          height: 30px;
          line-height: 30px;
          padding: 0 5px;
          display: flex;
          justify-content: space-between;
          background: fade(@primary-color, 2%);
          border-radius: 5px;
          margin: 3px 0;
          .close {
            cursor: pointer;
          }
        }
      }
    }
    .list_box {
      width: 480px;
      margin-right: 10px;
    }
    .check_box {
      flex-grow: 1;
      .box_header {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  // 投放时段
  .time_step_wrapper {
    user-select: none;
    border: 1px solid;
    height: 259px;
    width: 100%;
    max-width: 850px;
    display: flex;
    flex-direction: column;
    color: #e6e6e6;
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    font-size: 12px;
    .time_step_header {
      height: 25px;
      border-bottom: 1px solid;
      display: flex;
      justify-content: flex-end;
      .time_step_header_items {
        display: flex;
        .time_step_header_item {
          display: flex;
          margin: 0 5px;
          justify-content: center;
          align-items: center;
          color: #333;
          div {
            width: 30px;
            margin: 0 5px;
            height: 15px;
            border: 1px solid #e6e6e6;
            border-radius: 10px;
          }
          &:first-child>div{
            background-color: @primary-color;
          }
        }
      }
    }
    .time_step_title {
      height: 50px;
      border-bottom: 1px solid;
      display: flex;
      .time_step_title_left {
        width: 120px;
        border-right: 1px solid;
      }
      .time_step_title_right {
        flex-grow: 1;
        .time_step_title_right_top {
          height: 25px;
          border-bottom: 1px solid;
          display: flex;
          .time_step_title_right_top_item {
            line-height: 25px;
            height: 25px;
            flex: 1;
            text-align: center;
            border-color: #e6e6e6;
            color: #333;
            &:first-child {
              border-right: 1px solid #e6e6e6;
            }
          }
        }
        .time_step_title_right_bottom {
          display: flex;
          div {
            flex: 1;
            border-left: 1px solid;
            line-height: 25px;
            text-align: center;
            border-color: #e6e6e6;
            color: #333;
            &:first-child {
              border: none;
            }
          }
        }
      }
    }
    .time_step_content {
      flex-grow: 1;
      display: flex;
      ::after,
      ::before {
        box-sizing: border-box;
      }
      .time_step_content_left {
        width: 120px;
        border-right: 1px solid;
        display: flex;
        flex-direction: column;
        div.week_day {
          border-top: 1px solid;
          height: 0;
          line-height: 20px;
          flex: 1 1 auto;
          border-color: #e6e6e6;
          color: #333;
          text-align: center;
          &:first-child {
            border: none;
          }
        }
      }
      .time_step_content_right {
        position: relative;
        flex: 1 1 auto;
        .time_step_content_right_box {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          .time_step_day {
            border-top: 1px solid;
            flex: 1 1 auto;
            box-sizing: border-box;
            display: flex;
            &:first-child {
              border: none;
            }
            .time_step_hour {
              position: relative;
              flex: 1 1 auto;
              resize: none;
              cursor: default !important;
              box-sizing: border-box;
              border-left: 1px solid;
              &:first-child {
                border: none;
              }
              &:hover {
                background: fade(@primary-color, 10%);
              }
              &.active {
                background: @primary-color;
              }
            }
          }
        }
      }
    }
    .time_step_footer {
      height: 25px;
      border-top: 1px solid;
      line-height: 25px;
      display: flex;
      color: #333;
      border-color: #e6e6e6;
      position: relative;
      justify-content: center;
      .clear_step_time {
        position: absolute;
        right: 10px;
        cursor: pointer;
        color: @primary-color;
      }
    }
  }
  // 网络
  .network_wrapper,
  .operator_wrapper {
    display: flex;
    flex-wrap: nowrap;
    .check_item {
      width: 100px;
      height: 36px;
      border: 1px solid #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      &.active {
        background: #dde8fc;
        border: 1px solid @primary-color;
      }
      &.all_check_item {
        border-radius: 8px 0 0 8px;
      }
      &:not(.all_check_item):last-child {
        border-radius: 0 8px 8px 0;
      }
    }
    .checked_group {
      display: flex;
      flex-wrap: nowrap;
    }
  }
}
::v-deep.ad-group-form-container {
  .aRadioBtn();
}
// RTA
::v-deep.ad-group-form-container {
  .rta-ardio-group .ant-radio-button-wrapper {
    height: 36px;
    line-height: 36px;
    color: #666 !important;
  }
}
</style>
