<template>
  <div class="advertise-child-container">
    <div class="advertise-container">
      <div class="advertise-part-wrapper">
        <div class="advertise-part-search" v-if="+data.accessType === 0">
          <div>
            <m-select-one
              style="width: 220px"
              label="应用"
              :searchById="true"
              hasOs
              :hasPosition="false"
              :allData="appList"
              v-model="query.appId"
              @change="changeApp"
            />
            <m-select-one
              style="width: 230px; margin: 0 5px"
              label="广告位"
              :searchById="true"
              hasOs
              :hasPosition="true"
              :allData="placeList"
              v-model="query.placeId"
              @change="handleChangePlace"
            />
          </div>
          <div>
            <span class="appId" v-if="dataList?.length > 0">
              <span @click="handleClickUpdatePlace(sohaObj, 3)">
                应用ID：{{ sohaObj?.platAppId }} <a-icon
                  type="edit"
                /></span>
            </span>
            <a-dropdown-button
              :trigger="['click']"
              type="primary"
              style="border-radius: 0px !important"
              @click="AddSource(0)"
              @visibleChange="visibleChange"
            >
              <a-icon type="plus" />
              广告源
              <a-menu slot="overlay" v-if="showMenu">
                <a-menu-item style="width: 130px" key="2" @click="handleBatchAddSource">
                  <a-icon type="plus-square" />批量添加
                </a-menu-item>
                <a-menu-item style="width: 130px" key="4" @click="handleBatchExport">
                  <a-icon type="export" />批量导入
                </a-menu-item>
              </a-menu>
            </a-dropdown-button>
          </div>
        </div>
        <div class="advertise-left-search" v-if="+data.accessType === 1">
          <div>
            <a-button
              style="border-radius: 5px;margin-right: 10px;"
              type="primary"
              :loading="downLoading"
              @click="downloadReport"
            ><a-icon type="download" /> 导出</a-button
            >
            <a-button
              style="border-radius: 5px"
              type="primary"
              @click="AddSource(1)"
            ><a-icon type="plus" /> 广告源</a-button
            >
          </div>
        </div>
        <!--  table -->
        <div style="background: #f7f7ff" class="item-table">
          <a-table
            :pagination="false"
            rowKey="id"
            :columns="columns"
            :data-source="dataList"
            :loading="loading"
            bordered
          >
            <template slot="position" slot-scope="text, record">
              <div v-if="+data.accessType === 0">
                <span v-if="record.settleType === 0">
                  {{ record.bidfloor }}
                </span>
                <span v-else>
                  {{ record.settleType === 1 ? '服务端竞价' : '客户端竞价' }}
                </span>
              </div>
              <div v-else>{{ 'RTB竞价' }}</div>
            </template>
            <template slot="platPlaceIdTitle">
              <span>{{ getTitle(query.platId) }}</span>
            </template>
            <div class="action-slot" slot="action" slot-scope="text, record">
              <span class="btn" v-if="+data.accessType === 0" @click="EditSDKAdsource(record)">编辑</span>
              <template v-else>
                <span class="btn" @click="EditAPIAdsource(record)">编辑</span>
              </template>
              <template v-if="Number(record.isAdmin) !== 0 && +data.accessType === 0">
                <span class="line">|</span>
                <span class="btn" @click="handleClickUpdatePlace(record, 2)">删除</span>
              </template>
              <template v-if="Number(record.isAdmin) !== 0 && +data.accessType === 1">
                <span class="line">|</span>
                <a-popconfirm
                  title="确认删除该广告源?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="handleDelete(record)"
                >
                  <span class="btn">删除</span>
                </a-popconfirm>
              </template>
              <template v-if="Number(record.isAdmin) !== 0 && +data.accessType === 1">
                <span class="line">|</span>
                <span class="btn" @click="BindingPlace(record)">绑定广告位</span>
              </template>
            </div>
          </a-table>
          <m-empty style="height: 35vh;background:#f7f7ff" v-if="dataList?.length === 0">
            <template #description> 暂时没有任何数据 </template>
          </m-empty>
          <a-pagination
            class="pagination"
            v-if="dataList?.length > 0"
            show-size-changer
            :current="query.page"
            :total="total"
            :show-total="(total) => `共 ${total} 条`"
            @change="handleChangePage"
            @showSizeChange="onShowSizeChange"
            :pageSize="query.limit"
          />
        </div>
      </div>
    </div>
    <ChildModal
      v-if="modelVisible"
      :isEdit="isEdit"
      :addQuery="query"
      :visible="modelVisible"
      :childSourceInfo="childSourceInfo"
      @modalCancel="modelVisible = false"
      @changeEdit="handleSerach"
      @changeEdits="changeEdits"
    />
    <!-- API广告源弹窗 -->
    <APISourceModal
      :brandList="brandList"
      :installAppList="installAppList"
      v-if="APIaddSourceVisible"
      :visible="APIaddSourceVisible"
      :sourceInfo="isAdd === 1 ? undefined : sourceInfo"
      @modalCancel="APIaddSourceVisible = false"
      :isAdd="isAdd"
      :query="query"
      @changeSource="handleSerach"
      :specifiecPlat="true"
      :specifiecData="data"
    />
    <!-- SDK广告源弹窗 -->
    <SDKSourceModal
      v-bind="$attrs"
      :positionId="+query.positionId"
      v-if="SDKaddSourceVisible"
      :visible="SDKaddSourceVisible"
      :sourceInfo="isAdd ? undefined : sourceInfo"
      @modalCancel="SDKaddSourceVisible = false"
      :isAdd="isAdd"
      :query="query"
      @changeSource="SDKhandleSearch"
    />
    <!-- 批量导入 -->
    <ChildBatchExportModal
      v-bind="$attrs"
      v-if="batchExportVisible"
      :visible="batchExportVisible"
      @modalCancel="batchExportVisible = false"
      :query="query"
      :appName="appName"
      :placeName="query.placeName"
      @batchExportSuccess="handleSerach"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getAppList } from '@/api/media'
import { platformLinkList } from '@/api/aggregate'
import ChildModal from './ChildModal/index'
import { getAdsourcePager, getAccountList } from '@/api/advertisement'
import { getPlaceListByAppIds, deleteRtbItem, getBrandList, getDirectionalSettingAppList } from '@/api/common'
import excel from '@/utils/excel'
import request from '@/utils/request'
const adNone = require('@/assets/images/ad-none.png')
const SDKSourceModal = () => import(/* webpackChunkName: "dialog" */ './ChildModal/SDKSourceModal')
const APISourceModal = () => import(/* webpackChunkName: "dialog" */ '@/views/aggregate/RtbConfig/RtbConfigTable/components/sourceModal')
const ChildBatchExportModal = () => import(/* webpackChunkName: "dialog" */ './ChildModal/ChildBatchExportModal')

const columns = [
  {
    dataIndex: 'name',
    title: '广告源名称',
    align: 'left'
  },
  // {
  //   dataIndex: 'platAppId',
  //   title: '应用ID',
  //   align: 'left'
  // },
  {
    dataIndex: 'platPlaceId',
    align: 'left',
    scopedSlots: { customRender: 'platPlaceId', title: 'platPlaceIdTitle' }
  },
  {
    dataIndex: 'position',
    title: '排序价格',
    align: 'left',
    scopedSlots: { customRender: 'position' }
  },
  {
    dataIndex: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'left'
  }
]

export default {
  name: 'AdverChild',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: { ChildModal, ChildBatchExportModal, SDKSourceModal, APISourceModal },
  data () {
    return {
      query: {
        page: 1,
        limit: 10,
        accountId: 0,
        accountInfoId: '',
        platId: 0,
        appId: '',
        positionId: undefined,
        platAppId: '',
        placeId: '',
        accountName: '',
        placeName: ''
      },
      // 添加广告源
      SDKaddSourceVisible: false,
      APIaddSourceVisible: false,
      // 批量导入
      batchExportVisible: false,
      positionId: undefined,
      dspId: undefined,
      fromPage: undefined,
      sourceInfo: undefined,
      dataList: [],
      total: 0,
      columns,
      isAdd: true,
      appList: [],
      placeList: [],
      platList: [],
      allPlatList: [],
      modelVisible: false,
      loading: false,
      childSourceInfo: {},
      sohaObj: {},
      adNone,
      brandList: [],
      installAppList: [],
      downLoading: false
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title
    }),
    // 下拉菜单展示
    showMenu () {
      if (+this.data.accessType === 0 && this.placeList.length === 0) {
        return false
      } else {
        return true
      }
    },
    // 应用
    appName () {
      const list = this.appList.find((item) => item.id === this.query.appId)
      return list ? list.name : ''
    }
  },
  async created () {
    this.currentApp = [+this.data.platId]
    this.query.platId = +this.data.platId
    this.query.accountName = this.data.name
    this.query.accountId = +this.data.id
    this.data.accessType === '0' && this.getData()
    this.data.accessType === '1' && this.getAdsourcePager(this.query)
    this.getAllPlat()
    this.getBrandList()
    this.getDirectionalSettingAppList()
  },
  methods: {
    // 报表数据导出
    exportToExcel (query) {
      return request.post('/plat/downAdsourcePager', query, {
        responseType: 'blob'
      })
    },
    async downloadReport () {
      this.downLoading = true
      try {
        const res = await this.exportToExcel(this.query)
        await excel(res, `广告源报表.xlsx`)
      } catch (e) {
        console.log(e, '导出excel错误信息')
      }
      this.downLoading = false
    },
    // 安装列表
    async getDirectionalSettingAppList () {
      const { data = [] } = await getDirectionalSettingAppList()
      this.installAppList = data
    },
    async getBrandList () {
      const resp = await getBrandList()
      resp.data &&
        resp.data.forEach((item) => {
          item.id = item.name
        })
      this.brandList = Object.freeze(resp.data || [])
    },
    // 广告平台列表
    async getAllPlat () {
      const resp = await platformLinkList()
      if (resp.code === 200) {
        this.allPlatList = resp.data || []
      }
    },
    // 添加广告源
    AddSource (type) {
      if (+this.data.accessType === 0 && this.placeList.length === 0) {
        this.$message.error('请先去“媒体管理”，创建应用和广告位')
        return
      }
      if (type === 0) {
        this.isAdd = true
        this.SDKaddSourceVisible = true
        // this.query.platAppId = this.sohaObj?.platAppId
      } else {
        this.isAdd = 1
        this.query.accountId = this.data.id
        this.APIaddSourceVisible = true
        // this.query.platAppId = this.sohaObj?.platAppId
      }
    },
    // 下拉菜单按钮
    visibleChange (visible) {
      if (visible && +this.data.accessType === 0 && this.placeList.length === 0) {
        this.$message.error('请先去“媒体管理”，创建应用和广告位')
      }
    },
    // Api列表编辑
    EditAPIAdsource (record) {
      this.isAdd = 2
      this.sourceInfo = record
      this.APIaddSourceVisible = true
    },
    // SDK编辑
    EditSDKAdsource (record) {
      this.isAdd = false
      this.SDKaddSourceVisible = true
      this.sourceInfo = record
      this.query.platAppId = this.sohaObj.platAppId
    },
    // API删除
    async handleDelete (record) {
      const resp = await deleteRtbItem({ id: record.id })
      if (resp.code === 200) {
        this.$message.success('删除成功！')
        this.getAdsourcePager(this.query)
      }
    },
    // 绑定广告位
    BindingPlace (record) {
      this.$router.push({
        name: 'RtbConfig',
        query: {
          platId: this.data.platId,
          dspId: record.id,
          platAccountId: record.platAccountId
        }
      })
    },
    // 广告位ID备注
    getTitle (data) {
      let title = ''
      if (data === 1 || data === 6) {
        title = '代码位ID'
      } else if (data === 7 || data === 11) {
        title = '广告版位ID'
      } else {
        title = '广告位ID'
      }
      return title
    },
    // 改变广告位
    handleChangePlace (id) {
      this.query.placeId = id
      this.query.placeName = this.placeList.find((x) => x.id === this.query.placeId)?.name
      this.query.positionId = this.placeList.find((x) => x.id === this.query.placeId)?.position
      this.handleSerach()
    },
    // 切换应用
    changeApp (key) {
      this.getPlaceListByAppIds([key])
    },
    // 通过应用id（数组）查广告位列表
    async getPlaceListByAppIds (ids = []) {
      const resp = await getPlaceListByAppIds(ids)
      if (resp.code === 200) {
        this.query.placeId = resp.data.length > 0 ? resp.data[0].id : ''
        this.query.positionId = resp.data.length > 0 ? resp.data[0].position : undefined
        // 广告位列表
        this.placeList = resp.data || []
        this.query.placeName = this.placeList.find((x) => x.id === this.query.placeId)?.name
        this.getAdsourcePager(this.query)
      }
    },
    // 修改应用ID
    changeEdits () {
      this.query.page = 1
      this.currentApp = [+this.query.platId]
      // this.getData()
      this.getAdsourcePager(this.query)
    },
    // 搜索
    handleSerach () {
      this.query.page = 1
      this.getAdsourcePager(this.query)
    },
    // SDK回调
    SDKhandleSearch () {
      this.query.page = 1
      // 回调替换修改
      this.$emit('getData')
      this.getAdsourcePager(this.query)
    },
    // 操作按钮
    handleClickUpdatePlace (data, type) {
      // type === 1 编辑  2  删除 3 修改应用ID 4添加
      this.isEdit = type
      this.modelVisible = true
      this.childSourceInfo = data
      this.query.platAppId = data.platAppId
    },
    // API弹窗
    handleAPIClickUpdatePlace (arr, type) {
      // type === 1 编辑  2  删除 3 修改应用ID 4添加
      this.isAdd = type
      this.APIaddSourceVisible = true
      this.sourceInfo = arr
      this.query.platAppId = arr.platAppId
    },
    // 批量添加跳转
    handleBatchAddSource () {
      this.$router.push({
        name: 'batchAddSource',
        query: {
          appId: this.query.appId,
          platAccountId: this.query.accountId,
          placeId: this.query.placeId,
          groupId: 'default',
          testId: 'default',
          positionId: this.query.positionId,
          fromPage: 'sonfig',
          platId: this.query.platId
        }
      })
    },
    // 批量导入
    handleBatchExport () {
      this.query.platAppId = this.sohaObj?.platAppId
      this.batchExportVisible = true
    },
    // 左侧广告平台列表
    async getData () {
      this.loading = true
      const resp = await getAccountList()
      if (resp.code === 200) {
        // 广告平台列表
        this.platList = resp.data || []
        // 应用平台列表
        this.getAppList()
        this.loading = false
      }
    },
    // 获取全部应用列表
    async getAppList () {
      const resp = await getAppList()
      this.appList = resp.data || []
      if (this.appList && this.appList?.length > 0) {
        this.getPlaceListByAppIds([this.appList[0].id])
        this.query.appId = this.appList[0].id
      } else {
        this.query.appId = ''
        this.placeList = []
        this.query.placeId = ''
        this.getAdsourcePager(this.query)
      }
    },
    // 获取列表
    async getAdsourcePager (data) {
      this.loading = true
      data.platAppId = ''
      const resp = await getAdsourcePager(data)
      if (resp.code === 200) {
        this.dataList = resp.data.items || []
        this.total = resp.data.total
        this.sohaObj = this.dataList[0]
        this.loading = false
      }
    },
    // 分页
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getAdsourcePager(this.query)
    },
    // 点击页码
    handleChangePage (page) {
      this.query.page = page
      this.getAdsourcePager(this.query)
    }
  }
}
</script>

<style lang="less" scoped>
.advertise-child-container {
  padding: 10px;
  background: #f7f7ff;
  .advertise-container {
    display: flex;
    background: #f7f7ff;
    justify-content: space-between;
    flex-wrap: nowrap;
    .advertise-part-wrapper {
      border: 1px sol;
      border-radius: 5px;
      flex-grow: 1;
      background: #f7f7ff;
      padding: 20px 0 20px 0;
      .advertise-part-search {
        background: #f7f7ff;
        display: flex;
        justify-content: space-between;
        margin: 0 15px 20px 15px;
        .ant-select {
          margin-right: 10px;
          width: 200px;
        }
        .appId {
          margin: 5px 20px 0 5px;
        }
      }
      .advertise-left-search {
        background: #f7f7ff;
        align-items: center;
        display: flex;
        justify-content: flex-end;
        margin: -12px 15px 12px 0;
        .ant-select {
          margin-right: 10px;
          width: 200px;
        }
        .appId {
          margin: 5px 20px 0 5px;
        }
      }
    }
  }
  .action-slot {
    .btn {
      color: @primary-color;
      cursor: pointer;
    }
    .line {
      color: #ccc;
    }
    span {
      margin-right: 10px;
    }
  }
  .pagination {
    margin-left: 15px;
    padding: 10px 10px 0 10px;
    margin-bottom: -6px;
  }
}
::v-deep.cust-select-one-container .select_box {
  background: #f7f7ff;
}
::v-deep {
  .item-table {
      padding:0 15px;
    .ant-table-thead > tr > th {
      background: #f7f7ff !important;
    }
    .ant-table-tbody > tr > td {
       background: #f7f7ff !important;
    }
    .ant-table-content {
      background: #f7f7ff;
    }
  }
}
::v-deep.advertise-child-container {
  .ant-btn.ant-btn-primary {
    border-radius: 5px 0 0 5px;
    background: @primary-color;
    height: 36px;
  }

  .ant-btn.ant-btn-primary.ant-dropdown-trigger {
    border-radius: 0 5px 5px 0;
    height: 36px;
  }
}
</style>
