<template>
  <div class="advertiser_con">
    <div class="sonfig_operate">
      <a-button type="primary" @click="handleClickAdd(1)" class="operate_btn"> 开通广告主 </a-button>
      <div class="sonfig_search">
        <span class="label">选择日期</span>
        <a-range-picker
          v-model="rangePickerDate"
          @change="ChangeDate"
          format="YYYY-MM-DD"
          :placeholder="['开始时间', '结束时间']"
          :disabledDate="disabledDate"
          :ranges="{
            今日: [initDate(0, 0).start, initDate(0, 0).end],
            昨日: [initDate(1, 1).start, initDate(1, 1).end],
            最近一周: [initDate(6, 0).start, initDate(6, 0).end],
            最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
          }"
        >
          <!-- <a-icon slot="suffixIcon" type="calendar"/> -->
        </a-range-picker>
        <a-cascader
          :fieldNames="fieldNames"
          v-model="pid"
          allowClear
          :options="industry"
          placeholder="请选择行业"
          @change="changeTradeId"
          class="search_item"
        />
        <a-button class="search_item" @click="handleSearch" type="primary"><a-icon type="search" />搜索</a-button>
      </div>
    </div>
    <div class="sonfig_tables">
      <a-table
        class="m_table_cust"
        :pagination="false"
        :columns="columns"
        :data-source="dataList"
        :rowKey="
          (record, index) => {
            return index
          }
        "
        :loading="loading"
        bordered
      >
        <div slot="action" style="display: flex; width: 120px" slot-scope="text, record">
          <span class="btn" @click="handleClickUpdatePlace(record)">修改</span>
          <span class="line">|</span>
          <a-popconfirm title="确认删除该广告主?" ok-text="是" cancel-text="否" @confirm="handleDelete(record)">
            <span class="btn">删除</span>
          </a-popconfirm>
        </div>
      </a-table>
      <a-pagination
        class="pagination"
        v-if="dataList.length > 0"
        show-size-changer
        :current="searchquery.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
      />
      <m-empty v-if="!loading && dataList.length == 0" style="height: 70vh; width: 100%">
        <template #description>暂无数据！</template>
      </m-empty>
    </div>
    <PlacesModal
      v-if="addPlaceVisible"
      :placeInfo="placeInfo"
      :visible="addPlaceVisible"
      @modalCancel="addPlaceVisible = false"
      @changePlace="handleSearch"
      :isAdd="this.advertisers"
    />
  </div>
</template>

<script>
import { tradeList, deletes, advertiserListPage } from '@/api/system'
import PlacesModal from '../../components/placesModal'
import mixDate from '@/mixins/initDate'
import { getDuration } from '@/utils/duration'
const columns = [
  {
    dataIndex: 'advertiserCompany',
    title: '公司名称',
    key: 'advertiserCompany',
    ellipsis: true
  },

  {
    dataIndex: 'advertiserName',
    title: '广告主名称',
    key: 'advertiserName',
    ellipsis: true
  },
  {
    dataIndex: 'tradeName',
    title: '所属行业',
    key: 'tradeName'
  },
  {
    dataIndex: 'advertiserCategory',
    title: '广告分类',
    key: 'advertiserCategory',
    customRender: (text, record, index) => {
      if (record.advertiserCategory === '1') {
        text = '品牌'
      } else if (record.advertiserCategory === '2') {
        text = '效果'
      }
      return text
    }
  },
  {
    dataIndex: 'agentName',
    title: '代理公司',
    key: 'agentName'
  },
  {
    dataIndex: 'username',
    title: '客户经理',
    key: 'username'
  },
  {
    dataIndex: 'addTime',
    title: '创建日期',
    key: 'addTime',
    width: 180
  },
  {
    dataIndex: 'advertiserSts',
    title: '账号状态',
    key: 'advertiserSts',
    width: 120,
    customRender: (text, record, index) => {
      if (record.advertiserSts === '0') {
        text = '待审核'
      } else if (record.advertiserSts === '1') {
        text = '通过'
      } else if (record.advertiserSts === '-1') {
        text = '拒绝'
      }
      return text
    }
  },
  {
    dataIndex: 'action',
    title: '操作',
    width: 120,
    scopedSlots: { customRender: 'action' }
  }
]
export default {
  mixins: [mixDate],
  components: { PlacesModal },
  data () {
    return {
      searchquery: {
        page: 1,
        limit: 10
      },
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      columns,
      dataList: [],
      pid: [],
      addPlaceVisible: false,
      placeInfo: {},
      advertisers: 0,
      industry: [],
      advertiserIndustry: '',
      rangePickerDate: undefined,
      loading: false
    }
  },
  created () {
    this.getAdvertiserListPage()
    this.getIndustrySelect()
  },
  methods: {
    // 获取tablist
    async getAdvertiserListPage () {
      this.loading = true
      const res = await advertiserListPage(this.searchquery)
      if (res.code === 200) {
        this.loading = false
        this.dataList = res.data.list || []
        this.total = res.data.total
      }
    },
    // 行业option
    async getIndustrySelect () {
      const res = await tradeList()
      if (res.code === 200) {
        this.industry = res.data
      }
    },
    // 删除
    handleDelete (record) {
      const pts = {
        id: record.id
      }
      deletes(pts).then((resp) => {
        if (resp.code === 200) {
          this.$message.success('删除成功')
          this.timer = setTimeout(() => {
            this.getAdvertiserListPage()
          }, 1000)
        }
      })
    },
    // 选择时间
    ChangeDate (date, dateString) {
      const [startTime, endTime] = dateString
      this.searchquery = {
        page: 1,
        limit: 10,
        endTime,
        startTime,
        advertiserIndustry: this.advertiserIndustry
      }
    },
    // 分页
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getAdvertiserListPage()
    },
    // 点击页码
    handleChangePage (page) {
      this.searchquery.page = page
      this.getAdvertiserListPage()
    },
    // 选择行业
    changeTradeId (val) {
      this.advertiserIndustry = val.length > 0 ? val[1] : ''
      this.searchquery.advertiserIndustry = this.advertiserIndustry
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    // 搜索
    handleSearch () {
      this.searchquery.page = 1
      this.getAdvertiserListPage()
    },
    // 修改
    handleClickUpdatePlace (record) {
      this.advertisers = 2
      this.placeInfo = record
      this.addPlaceVisible = true
    },
    // 新增广告主
    handleClickAdd () {
      this.advertisers = 1
      this.addPlaceVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.advertiser_con {
  padding: 20px;
  border-radius: 5px;
  background: #fff;
  .sonfig_operate {
    display: flex;
    justify-content: space-between;
    // max-width: 90%;
    .sonfig_search {
      margin-bottom: 20px;
      .label {
        margin-right: 10px;
        color: #333;
      }
      .search_item {
        margin-left: 10px;
      }
      .ant-calendar-picker {
        width: 270px;
        .ant-calendar-picker-icon {
          display: block;
          color: #b3b3b3;
        }
      }
    }
  }
  .sonfig_tables {
    overflow: hidden;
    min-height: 70vh;
    .pagination {
      padding-top: 20px;
    }
    span.btn {
      font-size: 14px;
      margin-right: 10px;
      color: @primary-color;
    }
    span.line{
      color: #ccc;
      margin-right: 10px;
    }
  }
}
</style>
