<template>
  <div class="ad-plan-form-container">
    <a-form-model ref="adPlanForm" :model="adplanform" v-bind="layout">
      <a-form-model-item prop="adType" class="adtype_radio" style="border-bottom: 1px solid #ccc">
        <div class="weight_label" style="">推广目标</div>
        <a-radio-group v-model="adplanform.adType">
          <a-radio :value="1" :class="{ adtype_radio_item: true, active: adplanform.adType === 1 }">
            <div class="adtype_radio_box">
              <div class="left">
                <span class="title">品牌广告</span>
                <span class="desc">重点优化产品曝光</span>
                <span class="desc">如展示、点击</span>
              </div>
              <div class="right">
                <img style="width: 70px" :src="imgUrl1" alt="" />
              </div>
            </div>
          </a-radio>
          <a-radio :value="2" :class="{ adtype_radio_item: true, active: adplanform.adType === 2 }">
            <div class="adtype_radio_box">
              <div class="left">
                <span class="title">效果广告</span>
                <span class="desc">重点优化转化行为</span>
                <span class="desc">如激活、购买</span>
              </div>
              <div class="right">
                <img style="width: 70px" :src="imgUrl2" alt="" />
              </div>
            </div>
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item>
        <span class="weight_label" style="padding-top: 0; padding-bottom: 0">计划设置</span>
      </a-form-model-item>
      <div class="form_item">
        <span class="form_item_label">广告主</span>
        <a-form-model-item prop="userId" :rules="[{ required: true, message: '请选择广告主', trigger: 'change' }]">
          <a-select style="width: 400px" placeholder="请选择广告主" v-model="adplanform.userId" allowClear>
            <a-select-option v-for="item in advertiserLists" :key="item.id" :value="item.id">{{
              item.username
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </div>
      <div class="form_item">
        <span class="form_item_label">计划名称</span>
        <a-form-model-item prop="name" :rules="[{ required: true, message: '请输入计划名称', trigger: 'blur' }]">
          <a-input style="width: 400px" v-model.trim="adplanform.name" placeholder="请输入计划名称" allowClear></a-input>
        </a-form-model-item>
      </div>
      <div class="form_item">
        <span class="form_item_label budget">日预算金额</span>
        <a-radio-group style="margin-bottom: 10px; display: block" v-model="budgetType">
          <a-radio-button value="0">不限</a-radio-button>
          <a-radio-button value="1">指定金额</a-radio-button>
        </a-radio-group>
        <a-form-model-item
          :rules="[{ required: true, message: '请输入日预算金额', trigger: 'blur' }]"
          prop="budget"
          v-if="budgetType === '1'"
        >
          <a-input
            @change="changeBudget"
            style="width: 400px"
            v-model.number.trim="adplanform.budget"
            placeholder="请输入日预算金额"
            allowClear
            addon-after="元/天"
          ></a-input>
        </a-form-model-item>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { advertiserList } from '@/api/advertisement'
import { addAdPlan, getAdPlanList, updateAdplan } from '@/api/advertisementStep'

export default {
  inject: ['currentAdPlanId', 'changePlanId', 'currentAdGroupId', 'changeGroupId', 'changeLoading'],
  data () {
    return {
      layout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 }
      },
      imgUrl1: require('@/assets/images/step_plan_radio1.png'),
      imgUrl2: require('@/assets/images/step_plan_radio2.png'),
      steps: 1,
      isAdd: false,
      adplanform: {
        adType: 1,
        userId: undefined,
        name: undefined,
        budget: undefined,
        sts: 0
      },
      advertiserLists: [], // 广告主列表
      budgetType: '0'
    }
  },
  watch: {
    $route: {
      handler (val) {
        this.steps = val && val.query && +val.query.steps
        this.isAdd = val.name === 'addadsteps'
        if (!this.isAdd) {
          const adPlanId = val && val.query && val.query.adPlanId
          this.getAdPlanList(adPlanId)
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    this.getAdvertiserList()
  },
  methods: {
    async getAdPlanList (id) {
      const resp = await getAdPlanList()
      this.adplanform =
        resp.data &&
        resp.data.find((item) => {
          return +id === +item.id
        })
      this.budgetType = +this.adplanform.budget === 10000000 ? '0' : '1'
      this.adplanform.budget = +this.adplanform.budget === 10000000 ? undefined : this.adplanform.budget
    },
    // 获取广告主列表
    async getAdvertiserList () {
      const resp = await advertiserList()
      this.advertiserLists = resp && resp.data
    },
    // 日预算金额
    changeBudget (e) {
      if (!+e.target.value && +e.target.value !== 0) {
        this.adplanform.budget = undefined
      }
      if (e.target.value < 0) {
        this.adplanform.budget = undefined
      }
    },
    handleSubmitAdPlan () {
      return new Promise((resolve) => {
        this.$refs.adPlanForm.validate(async (valid) => {
          if (valid) {
            const query = {
              ...this.adplanform,
              // 日预算金额为0时即为不限，传10000000
              budget: this.budgetType === '1' ? this.adplanform.budget : 10000000
            }
            if (this.isAdd) {
              this.changeLoading(true)
              const resp = await addAdPlan(query)
              resp.code === 200 && this.changePlanId(resp.data.id)
              resolve(resp)
              setTimeout(() => {
                this.changeLoading(false)
              }, 1000)
            } else {
              this.changeLoading(true)
              const resp = await updateAdplan(query)
              resolve(resp)
              setTimeout(() => {
                this.changeLoading(false)
              }, 1000)
            }
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';
.ad-plan-form-container {
  .form_item {
    padding-left: 15px;
    margin-bottom: 20px;
    .form_item_label {
      margin-bottom: 10px;
      display: inline-block;
    }
  }
  .weight_label {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    vertical-align: top;
    margin-right: 10px;
    padding: 0 15px;
  }
  //   .simple_lable {
  //     font-size: 16px;
  //     color: #333;
  //     padding: 0 15px;
  //   }
  .adtype_radio_item {
    margin-right: 20px;
    transition: all 0.8s;
    &:hover {
      transform: scale(1.03);
    }
    &.active,
    :hover {
      color: @primary-color;
      .adtype_radio_box {
        background: rgba(65, 95, 255, 0.06);
        border: 1px solid @primary-color;
        .left {
          span {
            color: @primary-color !important;
          }
        }
      }
    }
    .adtype_radio_box {
      width: 250px;
      height: 100px;
      border-radius: 10px;
      border: 1px solid;
      display: flex;
      margin-left: 15px;
      .left {
        width: 150px;
        padding: 10px 0 0 30px;
        display: flex;
        flex-direction: column;
        span {
          font-size: 14px;
          line-height: 25px;
          &.title {
            color: #333;
            font-weight: 600;
          }
          &.desc {
            color: #999;
          }
        }
      }
      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
      }
    }
  }
}
::v-deep.ad-plan-form-container {
  .adtype_radio span.ant-radio {
    opacity: 0;
  }
  .aRadioBtn();
}
</style>
