<template>
  <div class="advertiser_container">
    <div class="sonfig_operate">
      <div>
        <a-button v-if="!show" type="primary" @click="handleClickAdd(2)" class="operate_btn"> 开通代理商 </a-button>
      </div>
      <div class="sonfig_search" style="min-height: 56px">
        <span class="label">选择日期</span>
        <a-range-picker
          v-model="rangePickerDate"
          @change="ChangeDate"
          format="YYYY-MM-DD"
          :placeholder="['开始时间', '结束时间']"
          :disabledDate="disabledDate"
          :ranges="{
            今日: [initDate(0, 0).start, initDate(0, 0).end],
            昨日: [initDate(1, 1).start, initDate(1, 1).end],
            最近一周: [initDate(6, 0).start, initDate(6, 0).end],
            最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
          }"
        >
          <!-- <a-icon slot="suffixIcon" type="calendar"/> -->
        </a-range-picker>
        <a-button class="search_item" @click="handleSearch" type="primary"><a-icon type="search" />搜索</a-button>
      </div>
    </div>
    <div class="sonfig_tables">
      <a-table
        class="m_table_cust"
        :pagination="false"
        :columns="columns"
        :data-source="dataList"
        :rowKey="
          (record, index) => {
            return index
          }
        "
        :loading="loading"
        bordered
      >
        <div class="action-slot" slot="action" slot-scope="text, record">
          <span @click="handleClickUpdatePlace(record)" class="btn">修改</span>
          <span class="line">|</span>
          <a-popconfirm title="确认删除该代理商?" ok-text="是" cancel-text="否" @confirm="handleDelete(record)">
            <span class="btn">删除</span>
          </a-popconfirm>
        </div>
      </a-table>
      <a-pagination
        class="pagination"
        v-if="dataList.length > 0"
        show-size-changer
        :current="searchquery.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
      />
      <m-empty v-if="!loading && dataList.length == 0" style="height: 70vh; width: 100%">
        <template #description>暂无数据！</template>
      </m-empty>
    </div>
    <PlacesModal
      v-if="addPlaceVisible"
      :placeInfo="placeInfo"
      :visible="addPlaceVisible"
      @modalCancel="addPlaceVisible = false"
      :isAdd="this.advertisers"
      @changePlace="handleSearch"
    />
  </div>
</template>

<script>
import { agentListDelete, agentListPage } from '@/api/system'
import PlacesModal from '../../components/placesModal'
import mixDate from '@/mixins/initDate'
import { mapState } from 'vuex'
import { getDuration } from '@/utils/duration'
const columns = [
  {
    dataIndex: 'agentCompany',
    title: '公司名称',
    key: 'agentCompany',
    width: 200,
    ellipsis: true
  },
  {
    dataIndex: 'agentName',
    title: '代理商名称',
    key: 'agentName'
  },
  {
    dataIndex: 'username',
    title: '客户经理',
    key: 'username'
  },
  {
    dataIndex: 'addTime',
    title: '创建日期',
    key: 'addTime'
  },
  {
    dataIndex: 'agentSts',
    title: '账号状态',
    key: 'agentSts',
    customRender: (text, record, index) => {
      if (record.agentSts === '0') {
        text = '待审核'
      } else if (record.agentSts === '1') {
        text = '审核通过'
      } else if (record.agentSts === '-1') {
        text = '审核不通过'
      }
      return text
    }
  },
  {
    dataIndex: 'action',
    title: '操作',
    width: 200,
    scopedSlots: { customRender: 'action' }
  }
]
export default {
  mixins: [mixDate],
  components: { PlacesModal },
  data () {
    return {
      searchquery: {
        page: 1,
        limit: 10
      },
      columns,
      placeInfo: {},
      advertisers: '',
      addPlaceVisible: false,
      loading: false,
      rangePickerDate: undefined,
      dataList: []
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0]
    }),
    show () {
      return this.role === 'AGENT'
    }
  },
  created () {
    this.getAdvertiserListPage()
  },
  methods: {
    // 获取tablist
    async getAdvertiserListPage () {
      this.loading = true
      const res = await agentListPage(this.searchquery)
      if (res.code === 200) {
        this.loading = false
        this.dataList = res.data.list || []
        this.total = res.data.total
      }
    },
    // 选择时间
    ChangeDate (date, dateString) {
      const [startTime, endTime] = dateString
      this.searchquery = {
        page: 1,
        limit: 10,
        endTime,
        startTime
      }
    },
    // 删除
    handleDelete (record) {
      const pts = {
        id: record.id
      }
      agentListDelete(pts).then((resp) => {
        if (resp.code === 200) {
          this.$message.success('删除成功')
          this.timer = setTimeout(() => {
            this.getAdvertiserListPage()
          }, 1000)
        }
      })
    },
    // 分页
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getAdvertiserListPage()
    },
    // 点击页码
    handleChangePage (page) {
      this.searchquery.page = page
      this.getAdvertiserListPage()
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    // 搜索
    handleSearch () {
      this.searchquery.page = 1
      this.getAdvertiserListPage()
    },
    // 新增代理商
    handleClickAdd () {
      this.advertisers = 3
      this.addPlaceVisible = true
    },
    // 修改
    handleClickUpdatePlace (record) {
      this.advertisers = 4
      this.placeInfo = record
      this.addPlaceVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.advertiser_container {
  padding: 20px;
  width: 100%;
  border-radius: 5px;
  background: #fff;
  .sonfig_operate {
    display: flex;
    justify-content: space-between;
    .sonfig_search {
      // text-align: right;
      .label {
        margin-right: 10px;
        color: #333;
      }
      .search_item {
        margin-left: 10px;
      }
      .ant-calendar-picker {
        width: 270px;
        .ant-calendar-picker-icon {
          display: block;
          color: #b3b3b3;
        }
      }
    }
  }
  .sonfig_tables {
    overflow: hidden;
    min-height: 70vh;
    .pagination {
      padding-top: 20px;
    }
    .action-slot{
    span{
      margin-right: 10px;
    }
    .btn{
      cursor: pointer;
      color: @primary-color;
    }
    .line{
      color: #ccc;
    }
  }
  }
}
</style>
