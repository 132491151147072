<template>
  <div class="advertiser_con">
    <div class="sonfig_operate">
      <div>
        <a-button style="margin-right: 10px" type="primary" @click="handleClickAddPlatBtn">添加广告平台</a-button>
      </div>
      <div class="sonfig_search">
        <a-input
          placeholder="请输入账号名称"
          allowClear
          style="width: 160px; margin-right: 10px"
          class="search_item"
          v-model.trim="query.name"
        />
        <a-select
          allowClear
          showSearch
          style="width: 200px"
          placeholder="请选择账户"
          v-model="query.userId"
          :filter-option="filterOption"
          v-if="role === 'SUPER_ADMIN'"
          @change="changeuser"
        >
          <a-select-option v-for="item in adminList" :key="item.id" :value="item.id">{{
            item.company
          }}</a-select-option>
        </a-select>
        <m-select-more
          style="width: 200px"
          class="search_item"
          :showSelectedPart="true"
          :allData="platList"
          v-model="query.plat"
          label="广告平台"
          @change="changePlat"
        />
        <a-select
          class="search_item"
          allowClear
          style="width: 200px"
          placeholder="请选择接入方式"
          v-model="query.accessType"
        >
          <a-select-option :value="1">API</a-select-option>
          <a-select-option :value="0">SDK</a-select-option>
        </a-select>
        <a-button class="search_item" @click="handleSearch" type="primary"><a-icon type="search" />搜索</a-button>
      </div>
    </div>
    <div class="sonfigs_tables">
      <a-table
        class="m_table_cust"
        :pagination="false"
        :columns="columns"
        :data-source="dataList"
        :rowKey="
          (record, index) => {
            return record.id
          }
        "
        :customRow="customRow"
        :expanded-row-keys.sync="expandedRowKeys"
        :childrenColumnName="null"
        :expandIconColumnIndex="-1"
        :expandIconAsCell="false"
        :loading="loading"
        bordered
      >
        <template slot="openApiStatus" slot-scope="openApiStatus">
          {{ openApiStatus ? '已开通' : '未开通' }}
        </template>
        <template slot="sts" slot-scope="sts">
          {{ sts === 'A' ? '开启' : '关闭' }}
        </template>
        <template slot="accessType" slot-scope="accessType">
          {{ accessType === '0' ? 'SDK' : accessType === '1' ? 'API' : accessType === '2' ? '自定义' : '--' }}
        </template>
        <div class="action-slot" slot="action" slot-scope="record">
          <span class="btn" @click.stop="handleClickEditPlatBtn(record)">修改</span>
          <template v-if="+record.accessType === 1">
            <span class="line">|</span>
            <span class="btn" @click.stop="handleClickImpowerBtn(record)">{{
              record.authSts === 0 ? '授权' : record.authSts === 1 ? '审核中' : '已授权'
            }}</span>
          </template>
        </div>
        <template slot="unitRevenue" slot-scope="unitRevenue">
          {{ unitRevenue ? '￥' + numFormat(unitRevenue, 3, '') : '--' }}
        </template>
        <template slot="expandedRowRender" slot-scope="record">
          <AdverChild :data="record" @getData="getData"></AdverChild>
        </template>
        <!-- 图标 -->
        <template slot="icon" slot-scope="record">
          <div v-if="record.isExpand">
            <a-button size="small" type="link"> {{ '展开' }} </a-button>
            <img class="ExpandIcon" src="../../../../assets/images/UpSvg.png" alt="" />
          </div>
          <div v-else>
            <a-button size="small" type="link"> {{ '收起' }} </a-button>
            <img class="ExpandIcon" src="../../../../assets/images/DownSvg.png" alt="" />
          </div>
        </template>
      </a-table>
      <a-pagination
        class="pagination"
        v-if="dataList.length > 0"
        show-size-changer
        :current="query.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
        :pageSize="query.limit"
      />
      <m-empty v-if="!loading && dataList.length == 0" style="height: 70vh; width: 100%">
        <template #description> 暂无数据 </template>
      </m-empty>
    </div>
    <PlatModal
      :isAdd="isAdd"
      :visible="platVisible"
      v-if="platVisible"
      :platInfo="platInfo"
      @modalCancel="platVisible = false"
      :platList="platformList"
      @changePlat="getPagerList"
    />
  </div>
</template>

<script>
import PlatModal from './components/platModal'
import AdverChild from './components/AdverChild'
import getListMix from '@/mixins/getList'
import { mapState } from 'vuex'
import { getPlatPager, platformListForCreative, updateAuthSts } from '@/api/advertisement'
import { getPlatFormList } from '@/api/aggregate'
import { numFormat } from '@/utils/dealNumber'
const columns = [
  {
    title: '广告平台',
    dataIndex: 'platName',
    width: 100
  },
  {
    title: '账号名称',
    width: 180,
    dataIndex: 'name'
  },
  {
    title: '所属账户',
    width: 160,
    dataIndex: 'username'
  },
  {
    title: '报表API',
    width: 100,
    dataIndex: 'openApiStatus',
    scopedSlots: { customRender: 'openApiStatus' }
  },
  {
    title: '状态',
    width: 120,
    dataIndex: 'sts',
    scopedSlots: { customRender: 'sts' }
  },
  {
    title: '接入方式',
    width: 100,
    dataIndex: 'accessType',
    scopedSlots: { customRender: 'accessType' }
  },
  {
    title: '操作',
    width: 100,
    scopedSlots: { customRender: 'action' }
  },
  {
    title: '昨日收益',
    dataIndex: 'unitRevenue',
    width: 100,
    scopedSlots: { customRender: 'unitRevenue' }
  },
  {
    title: '',
    align: 'left',
    width: 100,
    key: 'icon',
    scopedSlots: { customRender: 'icon' }
  }
]
export default {
  name: 'AlliancesAdvertise',
  components: { PlatModal, AdverChild },
  mixins: [getListMix],
  data () {
    return {
      demoList: [
        {
          id: '',
          creativeSts: ''
        }
      ],
      query: {
        plat: [],
        userId: undefined,
        name: undefined,
        openApiStatus: undefined,
        sts: undefined,
        page: 1,
        limit: 10
      },
      total: 0,
      columns,
      currentPlatInfo: undefined,
      expandedRowKeys: [],
      isAdd: false,
      back: false,
      isExpand: true,
      platVisible: false,
      platInfo: {},
      platformList: [],
      dataList: [],
      loading: false,
      searchquery: {
        adx: false,
        ad: false
      }
    }
  },
  watch: {
    dataList: {
      handler (val) {
        val.forEach((item) => {
          // 添加展开属性(根据是否有子表判断状态)
          this.expandedRowKeys.includes(item.id) ? (item.isExpand = false) : (item.isExpand = true)
        })
      }
    }
  },
  async mounted () {
    await this.getAlliancePlatList()
    await this.getAdminList()
    const { query = {} } = this.$route
    const { accountId } = query
    if (accountId) {
      this.query.platAccountId = +accountId
    }
    this.getPagerList()
    this.getPlatformListForCreative()
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      // 权限开启=1
      creative: (state) => state.user.info.creative,
      // 超管
      roleName: (state) => state.user.info.roleName,
      // 超管子账户
      adminUserId: (state) => state.user.info.adminUserId
    })
  },
  methods: {
    // 回调更新替换函数
    async getData () {
      this.back = true
      const resp = await getPlatPager(this.query)
      if (resp.code === 200) {
        const list = resp.data.items || []
        this.platInfo = list.filter((key) => key.id === this.currentPlatInfo.id)[0]
        // 回调最新数据
        this.currentPlatInfo = this.platInfo
      }
    },
    // 账号搜索
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 素材弹窗
    async getPlatformListForCreative () {
      const resx = await platformListForCreative()
      if (resx.code === 200) {
        this.demoList = resx.data || []
      }
    },
    // 选中后
    onChange (e, event) {
      if (event.target.checked) {
        this.demoList[e].creativeSts = 'A'
      } else {
        this.demoList[e].creativeSts = 'S'
      }
    },
    // 行内点击事件
    customRow (record) {
      return {
        on: {
          // 点击
          click: () => {
            if (this.expandedRowKeys.includes(record.id)) {
              this.expandedRowKeys = this.expandedRowKeys.filter((key) => key !== record.id)
              record.isExpand = true
            } else {
              this.expandedRowKeys.push(record.id)
              record.isExpand = false
            }
          }
        }
      }
    },
    numFormat,
    // 获取广告平台列表
    async getAlliancePlatList (data) {
      const resp = await getPlatFormList(this.searchquery)
      this.platList = resp.data || []
      this.platformList = JSON.parse(JSON.stringify(this.platList))
    },
    // 添加广告平台
    handleClickAddPlatBtn () {
      this.isAdd = true
      this.platVisible = true
    },
    // 修改广告平台
    handleClickEditPlatBtn (record) {
      this.platInfo = this.back ? this.currentPlatInfo : record
      this.currentPlatInfo = this.platInfo
      this.isAdd = false
      this.platVisible = true
    },
    handleClickImpowerBtn (record) {
      if (record.authSts === 0) {
        this.authSts1(record)
      }
    },
    authSts1 (record) {
            const _this = this
      this.$confirm({
        title: '提示',
        maskClosable: true,
        content: () => {
          return (
            <div class="method-modal" style="height:140px;display:flex;padding: 10px 30px">
              <div>
                <a-icon
                  style="color: #fa8b15;font-size: 22px;margin-right: 20px;line-height:80px"
                  type="exclamation-circle"
                />
              </div>
              <div>
                <div class="desc">
                  {`平台帮助有充足预算的开发者实现更多预算消耗，可通过本平台购买流量，该功能授权后，请联系商务经理进行预算审核，合同签署及预充值流程。`}
                </div>
                <div style='line-height: 40px'>是否开启当前授权？</div>
                <div class="modal-contact-wrapper">
                  <div class="contact-item">
                    <span class="label">商务联系人：</span>
                    <a-popover>
                      <a class="value name">王慧</a>
                      <template slot="content">
                        <img style='width:200px;' src={require('@/assets/images/contacts/contact-wx.jpg')}/>
                      </template>
                    </a-popover>
                  </div>
                  <div class="contact-item">
                    <span class="label">联系电话：</span>
                    <span class="value">18911552435</span>
                  </div>
                </div>
              </div>
            </div>
          )
        },
        okText: '授权',
        cancelText: '取消',
        onOk: () => {
          updateAuthSts({ id: record.id }).then((res) => {
            _this.$message.success('授权成功')
            _this.getPagerList()
          })
        },
        onCancel: () => {},
        icon: () => {
          return ''
        },
        class: 'method-modal-wrapper',
        width: 600
      })
    },
    // 账户修改回调
    changeuser (userId) {
      if (userId) {
        this.searchquery.adminIdList = [userId]
        this.getAlliancePlatList()
      } else {
        delete this.searchquery.adminIdList
        this.getAlliancePlatList()
      }
    },
    changePlat (platId) {},
    // 搜索
    handleSearch () {
      this.query.page = 1
      this.getPagerList()
    },
    // 获取数据
    async getPagerList () {
      this.loading = true
      const resp = await getPlatPager(this.query)
      if (resp.code === 200) {
        this.dataList = resp.data.items || []
        this.total = resp.data.total
        this.loading = false
      }
      if (this.query.platAccountId) {
        this.query.platAccountId = undefined
      }
    },
    handleChangePage (page) {
      this.query.page = page
      this.getPagerList()
    },
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getPagerList()
    }
  }
}
</script>

<style lang="less" scoped>
.permission-checkbox-group {
  height: 150px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  .checkbox-item {
    margin-left: 10px;
    overflow: hidden;
    margin-top: 10px;
    > span:not([class]) {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: top;
    }
  }
}
.ExpandIcon {
  width: 8%;
  margin: -4px 0 0 -3px;
}
.advertiser_con {
  padding: 20px;
  border-radius: 5px;
  background: #fff;
  .sonfig_operate {
    display: flex;
    justify-content: space-between;
    .sonfig_search {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 20px;
      .label {
        margin-right: 10px;
        color: #333;
      }
      .search_item {
        margin-left: 10px;
        margin-bottom: 10px;
      }
    }
  }
  .sonfigs_tables {
    width: 100%;
    overflow: hidden;
    min-height: 70vh;
    text-overflow: ellipsis;
    white-space: nowrap;
    .action-slot {
      .btn {
        color: @primary-color;
        cursor: pointer;
      }
      .line {
        color: #ccc;
      }
      span {
        margin-right: 10px;
      }
    }
    .pagination {
      padding-top: 20px;
    }
  }
}
</style>
<style lang="less">
.modal-contact-wrapper {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
  .contact-item {
    margin-right: 16px;
    font-size: 13px;
    .label {
      font-weight: 600;
    }
    .value{
        &.name{
          cursor: pointer;
        }
    }
  }
}
</style>
