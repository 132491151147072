<template>
  <div class="ad-plan-view-container">
    <div class="title">广告计划</div>
    <div class="prop_item">
      <span class="label">推广目标</span>
      <span class="value">{{ adPlanInfo.adType === 1 ? '品牌广告' : '效果广告' }}</span>
    </div>
    <div class="prop_item">
      <span class="label">广告主</span>
      <span class="value">{{ advertiserName }}</span>
    </div>
    <div class="prop_item">
      <span class="label">计划名称</span>
      <span class="value">{{ adPlanInfo.name }}</span>
    </div>
    <div class="prop_item">
      <span class="label">预算金额</span>
      <span class="value">{{ adPlanInfo.budget !== 10000000 ? adPlanInfo.budget : '不限' }}</span>
    </div>
  </div>
</template>

<script>
import { advertiserList } from '@/api/advertisement'
export default {
  data () {
    return {
      advertiserLists: []
    }
  },
  computed: {
    advertiserName () {
      const advertiser = this.advertiserLists.find((item) => item.id === this.adPlanInfo.userId)
      if (advertiser) {
        return advertiser.username
      } else {
        return ''
      }
    }
  },
  mounted () {
    this.getAdvertiserList()
  },
  props: {
    adPlanInfo: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    // 获取广告主列表
    async getAdvertiserList () {
      const resp = await advertiserList()
      this.advertiserLists = resp && resp.data
    }
  }
}
</script>

<style lang="less" scoped>
.ad-plan-view-container {
  margin-bottom: 30px;
  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .prop_item {
    height: 40px;
    line-height: 40px;
    .label {
      display: inline-block;
      width: 90px;
    }
    .value {
      font-size: 13px;
    }
  }
}
</style>
