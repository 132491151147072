export const stsOptions = [
  {
    label: '所有未删除',
    value: '8'
  },
  {
    label: '启用中',
    value: '0'
  },
  {
    label: '暂停中',
    value: '1'
  },
  {
    label: '计划暂停',
    value: '2'
  },
  {
    label: '达广告组限额',
    value: '3'
  },
  {
    label: '未到投放日期',
    value: '4'
  },
  {
    label: '未到投放时间段',
    value: '5'
  },
  {
    label: '投放已结束',
    value: '6'
  },
  {
    label: '已删除',
    value: '7'
  }
]
export const stsColumns = {
  title: '开关',
  width: 130,
  key: 'sts',
  scopedSlots: { customRender: 'sts' },
  dataIndex: 'sts',
  checked: false,
  fixed: true
}
export const actiColumns = {
  title: '操作',
  key: 'action',
  width: 160,
  dataIndex: 'action',
  fixed: true,
  checked: false,
  scopedSlots: { customRender: 'action' }
}
export const adGroupNameColumns = {
  title: '广告组名称',
  key: 'adGroupName',
  width: 220,
  dataIndex: 'adGroupName',
  fixed: true,
  checked: false,
  scopedSlots: { customRender: 'adGroupName' }
}
export const actionColumns = [
  {
    title: '广告组名称',
    width: 220,
    dataIndex: 'adGroupName',
    scopedSlots: { customRender: 'adGroupName' },
    key: 'adGroupName',
    checked: false,
    fixed: true
  },
  {
    title: '开关',
    key: 'sts',
    scopedSlots: { customRender: 'sts' },
    dataIndex: 'sts',
    checked: false,
    fixed: true
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    fixed: true,
    checked: false,
    scopedSlots: { customRender: 'action' }
  }
]
export const columns = [
  {
    title: '广告计划名称',
    width: 200,
    dataIndex: 'adPlanName',
    scopedSlots: { customRender: 'adPlanName' },
    key: 'adPlanName',
    checked: false
  },
  {
    title: '状态',
    key: 'stsDesc',
    dataIndex: 'stsDesc',
    scopedSlots: { customRender: 'stsDesc' },
    checked: false,
    width: 130
  },
  {
    width: 240,
    key: 'putStartDate',
    title: '投放时间',
    checked: false,
    scopedSlots: { customRender: 'putStartDate' },
    dataIndex: 'putStartDate'
  },
  {
    width: 150,
    key: 'budget',
    title: '限额(元/天)',
    checked: false,
    sorter: true,
    slots: { title: 'budgetTitle' },
    scopedSlots: { customRender: 'budget' },
    dataIndex: 'budget'
  },
  {
    width: 150,
    title: '曝光量',
    key: 'impress',
    checked: false,
    sorter: true,
    disabled: true,
    dataIndex: 'impress'
  },
  {
    title: '点击量',
    width: 150,
    key: 'click',
    checked: false,
    sorter: true,
    dataIndex: 'click'
  },
  {
    title: '点击率',
    width: 150,
    key: 'clickRate',
    checked: false,
    sorter: true,
    dataIndex: 'clickRate',
    customRender: (text, record, index) => {
      return record.clickRate + '%'
    }
  },
  {
    width: 200,
    key: 'ecpm',
    title: '千元展示均价(元)',
    slots: { title: 'ecpmTitle' },
    checked: false,
    sorter: true,
    dataIndex: 'ecpm'
  },
  {
    width: 150,
    key: 'ecpc',
    title: '点击均价(元)',
    checked: false,
    sorter: true,
    slots: { title: 'ecpcTitle' },
    dataIndex: 'ecpc'
  },
  {
    title: '花费',
    width: 150,
    key: 'income',
    checked: true,
    sorter: true,
    dataIndex: 'income'
  }
]

export const arrAttributes = [
  {
    title: '广告组名称',
    width: 220,
    dataIndex: 'adGroupName',
    checked: false,
    key: 'adGroupName',
    scopedSlots: { customRender: 'adGroupName' },
    isChecked: false,
    disabled: true,
    fixed: true
  },
  {
    title: '状态',
    key: 'stsDesc',
    checked: false,
    dataIndex: 'stsDesc',
    scopedSlots: { customRender: 'stsDesc' },
    isChecked: false,
    width: 130
  },
  {
    width: 150,
    title: '限额(天/元)',
    key: 'budget',
    isChecked: false,
    checked: false,
    sorter: true,
    slots: { title: 'budgetTitle' },
    scopedSlots: { customRender: 'budget' },
    dataIndex: 'budget'
  },
  {
    title: '广告计划名称',
    width: 200,
    dataIndex: 'adPlanName',
    scopedSlots: { customRender: 'adPlanName' },
    key: 'adPlanName',
    checked: false
  },
  {
    width: 240,
    key: 'putStartDate',
    title: '投放时间',
    checked: false,
    scopedSlots: { customRender: 'putStartDate' },
    dataIndex: 'putStartDate'
  },
  {
    width: 150,
    title: '曝光量',
    key: 'impress',
    checked: false,
    sorter: true,
    disabled: true,
    dataIndex: 'impress'
  },
  {
    title: '点击量',
    width: 150,
    key: 'click',
    checked: false,
    sorter: true,
    dataIndex: 'click'
  },
  {
    title: '点击率',
    width: 150,
    key: 'clickRate',
    checked: false,
    sorter: true,
    dataIndex: 'clickRate',
    customRender: (text, record, index) => {
      return record.clickRate + '%'
    }
  },
  {
    slots: { title: 'ecpmTitle' },
    width: 200,
    title: '千元展示均价(元)',
    key: 'ecpm',
    checked: true,
    sorter: true,
    dataIndex: 'ecpm'
  },
  {
    width: 150,
    key: 'ecpc',
    checked: false,
    sorter: true,
    title: '点击均价(元)',
    slots: { title: 'ecpcTitle' },
    dataIndex: 'ecpc'
  },
  {
    title: '花费',
    width: 150,
    key: 'income',
    checked: false,
    sorter: true,
    dataIndex: 'income'
  }
]

export const basicAttributes = [
  {
    title: '广告组名称',
    width: 220,
    dataIndex: 'adGroupName',
    checked: false,
    key: 'adGroupName',
    scopedSlots: { customRender: 'adGroupName' },
    disabled: true,
    fixed: true
  },
  {
    title: '状态',
    key: 'stsDesc',
    dataIndex: 'stsDesc',
    scopedSlots: { customRender: 'stsDesc' },
    checked: false,
    width: 130
  },
  {
    width: 150,
    key: 'budget',
    title: '限额(元/天)',
    checked: false,
    sorter: true,
    slots: { title: 'budgetTitle' },
    scopedSlots: { customRender: 'budget' },
    dataIndex: 'budget'
  },
  {
    title: '广告计划名称',
    width: 260,
    dataIndex: 'adPlanName',
    scopedSlots: { customRender: 'adPlanName' },
    key: 'adPlanName',
    checked: false
  },
  {
    width: 240,
    key: 'putStartDate',
    title: '投放时间',
    checked: false,
    scopedSlots: { customRender: 'putStartDate' },
    dataIndex: 'putStartDate'
  }
]
export const disAttributes = [
  {
    width: 150,
    title: '曝光量',
    key: 'impress',
    checked: false,
    sorter: true,
    disabled: true,
    dataIndex: 'impress'
  },
  {
    title: '点击量',
    width: 150,
    key: 'click',
    checked: false,
    sorter: true,
    dataIndex: 'click'
  },
  {
    title: '点击率',
    width: 150,
    key: 'clickRate',
    checked: false,
    sorter: true,
    dataIndex: 'clickRate',
    customRender: (text, record, index) => {
      return record.clickRate + '%'
    }
  },
  {
    width: 200,
    key: 'ecpm',
    title: '千元展示均价(元)',
    slots: { title: 'ecpmTitle' },
    checked: true,
    sorter: true,
    dataIndex: 'ecpm'
  },
  {
    width: 150,
    key: 'ecpc',
    checked: false,
    title: '点击均价(元)',
    slots: { title: 'ecpcTitle' },
    sorter: true,
    dataIndex: 'ecpc'
  },
  {
    title: '花费',
    width: 150,
    key: 'income',
    checked: false,
    sorter: true,
    dataIndex: 'income'
  }
]

export function clearName (nameList) {
  return nameList.filter((item) => {
    return item !== '开关' && item !== '操作'
  })
}

export function clearCode (codesList) {
  return codesList.filter((item) => {
    return item !== 'action' && item !== 'sts'
  })
}
