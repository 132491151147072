<template>
  <div class="advertisingPlan_container">
    <!-- 搜索、操作按钮 -->
    <div class="search">
      <div class="serachBox">
        <a-range-picker
          :allowClear="false"
          class="m-r-10 m-b-10"
          style="width: 250px"
          format="YYYY-MM-DD"
          :value="rangePickerDate"
          :placeholder="['开始日期', '结束日期']"
          @change="handleChangeTime"
          :disabledDate="disabledDate"
          :ranges="{
            今日: [initDate(0, 0).start, initDate(0, 0).end],
            昨日: [initDate(1, 1).start, initDate(1, 1).end],
            最近一周: [initDate(6, 0).start, initDate(6, 0).end],
            最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
          }"
        >
        </a-range-picker>
        <a-select class="m-r-10" style="width: 200px" v-model="searchFormData.sts" placeholder="请选择计划状态">
          <a-select-option v-for="pitem in stsOptions" :key="pitem.id" :value="pitem.value">
            {{ pitem.label }}
          </a-select-option>
        </a-select>
        <a-input
          placeholder="请输入广告计划名称"
          class="m-r-10"
          v-model.trim="searchFormData.keyWords"
          style="width: 300px"
          :allowClear="true"
        />
        <a-button type="primary" class="btn" @click="handleSerach">查询</a-button>
      </div>
      <div class="allianceSerachForm m-t-20">
        <div>
          <a-button class="m-r-10" type="primary" @click="handleShowModel">新建广告</a-button>
          <el-popover placement="bottom-start" v-model="popoverVisible">
            <div style="padding: 5px">
              <a-radio style="font-weight: bold; color: #333" v-model="radioValue">状态</a-radio>
              <div style="display: flex; margin-top: 10px">
                <div v-for="item of batchOption" :key="item.id">
                  <el-tooltip
                    effect="dark"
                    :disabled="!btnDisabled"
                    content="不能对选中的广告计划执行该操作"
                    placement="right-start"
                  >
                    <span>
                      <el-button plain class="btnHover" @click="batchOprate(item)" :disabled="btnDisabled">{{
                        item.name
                      }}</el-button>
                    </span>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <a-button
              slot="reference"
              :disabled="!selectedRowKeys.length > 0"
              class="m-r-10"
            >批量操作<a-icon
              type="down"
            /></a-button>
          </el-popover>
        </div>
        <div class="serachBox">
          <a-button type="link" @click="handleClickCustTagsBtn">自定义列表</a-button>
          <a-button @click="exportReport" :loading="downLoading" type="link">导出列表</a-button>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <div class="sonfig_tables m-t-20">
      <a-table
        :loading="loadings"
        class="m_table_cust"
        size="middle"
        ref="tableSort"
        :data-source="data"
        :columns="columns"
        :rowKey="(record) => record.planId"
        :scroll="{ x: true }"
        tableLayout="auto"
        :pagination="false"
        :customRow="customRow"
        @change="handleSort"
        :rowClassName="rowClassName"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
          getCheckboxProps: getCheckboxProps,
          columnWidth: 70
        }"
        bordered
      >
        <!-- 广告计划名称  -->
        <template slot="adPlanName" slot-scope="adPlanName, record">
          <span
            class="m-r-10 pointer"
            :class="[record.type !== 'total' ? 'fontColor' : '']"
            @click="goGroupName(record)"
          >{{ adPlanName }}</span
          >
          <i
            v-show="record.showIcon && record.sts !== '3'"
            class="el-icon-edit"
            @click="editName(record, '计划名称')"
          ></i>
        </template>
        <!-- 开关 -->
        <!-- 开传0关传1 -->
        <template v-if="record.sts !== '3' && record.type !== 'total'" slot="sts" slot-scope="sts, record">
          <a-switch size="small" @change="handleCheckSts(record)" :checked="record.sts !== '1'"></a-switch>
        </template>
        <!-- 操作 -->
        <div v-if="record.sts !== '3' && record.type !== 'total'" class="action-slot" slot="action" slot-scope="action, record">
          <span class="btn" @click="handleEdit(record)"> 编辑</span>
          <span class="line">|</span>
          <span class="btn" @click="handleDetle(record)">删除</span>
        </div>
        <!-- 状态 -->
        <template slot="stsDesc" slot-scope="stsDesc, record">
          <!-- 暂停 = 红色 启用中 = 绿色  其他 || stsDesc === '投放已结束' 都是灰色 getColor(record) -->
          <span :class="[getColor(record)]" style="margin-right: 5px">{{ stsDesc }}</span>
        </template>
        <!-- 限额 -->
        <template v-if="record.type !== 'total'" slot="budget" slot-scope="budget, record">
          <span style="margin-right: 5px">{{ budget !== 10000000 ? numFormat(budget, 3, '') : '不限' }}</span>
          <i
            v-show="record.showIcon && record.sts !== '3'"
            class="el-icon-edit"
            @click="editName(record, '日限额')"
          ></i>
        </template>
      </a-table>
      <m-empty v-if="data.length === 1" style="height: 70vh; width: 100%">
        <template #description>暂无数据！</template>
      </m-empty>
      <a-pagination
        class="pagination"
        show-size-changer
        :current="querData.page"
        :show-total="(total) => `共 ${total} 条`"
        :total="total"
        @change="handleCurrentPage"
        @showSizeChange="handleChangePageSize"
        :pageSizeOptions="['10', '20', '40', '50', '80', '100']"
      />
    </div>
    <!-- 修改弹窗 -->
    <Model
      v-if="advertisVisible"
      :placeInfo="placeInfo"
      type="1"
      :modificationType="modificationType"
      :visible="advertisVisible"
      @getlist="getlist"
      @modalCancel="advertisVisible = false"
    />
    <!-- 自定义 -->
    <CustomListModal
      v-if="custTagsVisible"
      type="1"
      :visible="custTagsVisible"
      :disAttributes="disAttributes"
      :basicAttributes="basicAttributes"
      :sureArr="allcheckArr"
      @modalCancel="custTagsVisible = false"
      @changeCustTags="changeCustTags"
    />
    <!-- 批量操作删除提示弹窗 -->
    <a-modal v-model="popconfirmVisible" title="提示" :confirm-loading="confirmLoading" @ok="batchOperationSts">
      <div class="batchModel">
        <a-icon class="icon" type="exclamation-circle" />
        <p>删除广告计划将同步删除该计划下的所有广告组及广告，且无法恢复，是否仍要删除？</p>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { adplanpagerList, batchOperationSts, updateAdplan } from '@/api/advertisement'
import Model from '../component/nameModel'
import CustomListModal from '@/components/common/CustomList'
import {
  stsOptions,
  columns,
  actionColumns,
  stsColumns,
  actiColumns,
  adPlanNameColumns,
  arrAttributes,
  basicAttributes,
  disAttributes
} from './list'
import { clearName, clearCode } from '../AdvertisingGroup/list.js'
import mixDate from '@/mixins/initDate'
import { showDay, numFormat } from '@/utils/dealNumber'
import request from '@/utils/request'
import excel from '@/utils/excel'
import { getDuration } from '@/utils/duration'
import localDataName from '@/utils/localDataName'
export default {
  name: 'Alliance',
  components: { Model, CustomListModal },
  mixins: [mixDate],
  data () {
    return {
      adPlanStorageName: localDataName.adPlanStorageName,
      loadings: false,
      searchFormData: {
        sts: '4'
      },
      stsOptions,
      adPlanNameColumns,
      columns,
      actionColumns,
      disAttributes,
      basicAttributes,
      arrAttributes,
      sureArrs: [],
      radioValue: true,
      data: [], // list data
      querData: {
        page: 1,
        limit: 10
      },
      popoverVisible: false, // 批量操作弹窗
      batchData: {
        // 批量操作参数
        sts: '',
        idList: [], // 批量操作选中的idlist
        operationObject: 1 // 1: 计划 2: 广告组 3: 广告 4: 素材
      },
      batchOption: [
        // 批量操作弹窗操作项
        { id: '0', name: '启用' },
        { id: '1', name: '暂停' },
        { id: 'D', name: '删除' }
      ],
      popconfirmVisible: false, // 删除确认弹窗
      total: 0,
      custTagsVisible: false, // 自定义指标弹窗
      rangePickerDate: null, // 日期
      advertisVisible: false, // 修改名称、限额弹窗
      placeInfo: {}, // 修改名称、限额弹窗传参
      modificationType: '', // 编辑 计划名称  限额
      selectedRowidList: [],
      selectedRowKeys: [], // 选中的数据
      allcheckArr: [],
      confirmLoading: false,
      downLoading: false
    }
  },
  computed: {
    btnDisabled () {
      // 若选中列表中有已删除状态，则不能批量操作，批量弹窗按钮置灰
      return this.selectedRowidList.some((item) => item.sts === '3')
    }
  },
  created () {
    const { endDate, startDate } = this.initDate(0, 0)
    this.rangePickerDate = [startDate, endDate]
    this.querData.dateStart = startDate
    this.querData.dateEnd = endDate
    this.columns = [...this.actionColumns, ...this.columns]
    this.getlist()
    this.initTargetArr()
  },
  methods: {
    numFormat,
    // 汇总行禁选
    getCheckboxProps (record) {
      return {
        props: {
          disabled: record.type === 'total'
        }
      }
    },
    getColor (record) {
      if (record.stsDesc === '投放已结束') return 'grey'
      return record.sts === '1' ? 'red' : record.sts === '0' ? 'green' : 'grey'
    },
    // 点击选择columns
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowidList = selectedRows
      this.selectedRowKeys = selectedRowKeys
      this.batchData.idList = selectedRowKeys
    },
    // 汇总行不展示复选框
    rowClassName (record, index) {
      if (index === 0) {
        return 'all_col'
      }
    },
    // 导出表格接口函数
    downloadReport (query) {
      return request.post('/admanage/downloadAdplanReport', query, {
        responseType: 'blob'
      })
    },
    //   导出报表
    async exportReport () {
      this.downLoading = true
      const codesList = []
      const namesList = []
      this.columns.map((item) => {
        namesList.push(item.title)
        codesList.push(item.key)
      })
      const { dateStart, dateEnd, sortBy = '', sortOrder = '' } = this.querData || {}
      const { sts, keyWords = '' } = this.searchFormData || {}
      const parmars = {
        dateStart,
        dateEnd,
        sortBy,
        sortOrder,
        codeList: clearCode(codesList),
        nameList: clearName(namesList),
        sts,
        keyWords
      }
      try {
        const res = await this.downloadReport(parmars)
        await excel(res, `广告计划列表-${showDay(new Date())}.xls`)
        this.downLoading = false
      } catch (e) {
        console.log(e, '导出excel错误信息')
      }
    },
    getlist () {
      // 获取tablist
      this.adPlanPagerFn({
        ...this.querData,
        ...this.searchFormData
      })
    },
    // 获取指标列表数组
    initTargetArr () {
      const arr =
        (localStorage.getItem(this.adPlanStorageName) &&
          JSON.parse(localStorage.getItem(this.adPlanStorageName) || [])) ||
        []
      const ifArr = localStorage.getItem(this.adPlanStorageName)
      // sureArrs 已选列表，如缓存中有弹窗中显示缓存，没有展示全部 = arrAttributes
      this.allcheckArr = ifArr ? arr : this.arrAttributes
      const arrCopy = JSON.parse(JSON.stringify(arr)).filter((item) => item.key !== 'adPlanName')
      arrCopy.splice(0, 0, adPlanNameColumns, stsColumns, actiColumns)
      this.columns = ifArr ? arrCopy : this.columns
      this.dealColums()
    },
    // 处理大屏下不出现空白列
    dealColums () {
      this.columns.filter((item) => !item.fixed)[0].width = undefined
    },
    // 自定义指标
    handleClickCustTagsBtn () {
      this.custTagsVisible = true
    },
    // 自定义弹窗保存后触发改事件
    changeCustTags (dragArr, targetArr) {
      this.allcheckArr = dragArr
      const arrCopy = JSON.parse(JSON.stringify(dragArr)).filter((item) => item.key !== 'adPlanName')
      arrCopy.splice(0, 0, adPlanNameColumns, stsColumns, actiColumns)
      this.columns = arrCopy
      this.dealColums()
    },
    // table行方法
    customRow (record, index) {
      return {
        on: {
          // 鼠标移入
          mouseenter: (event) => {
            record.showIcon = true
          },
          // 移除 清除id 还原样式
          mouseleave: (event) => {
            record.showIcon = false
          }
        }
      }
    },
    // 点击批量操作弹窗操作按钮
    batchOprate (data) {
      this.batchData.sts = data.id
      this.popoverVisible = false // 操作弹窗
      if (data.id === 'D') {
        this.popconfirmVisible = true // 删除提示弹窗
        return
      }
      this.batchOperationSts()
    },
    // 批量操作接口
    async batchOperationSts () {
      const res = await batchOperationSts(this.batchData)
      if (res.code === 200) {
        this.$message.success('修改成功')
        this.querData.sortOrder = ''
        this.querData.sortBy = ''
        this.getlist()
        this.selectedRowKeys = [] // 多选清空
        this.popconfirmVisible = false
      }
    },
    // 点击列表删除
    handleDetle (record) {
      this.batchData = {
        sts: 'D',
        idList: [record.planId],
        operationObject: 1
      }
      this.popconfirmVisible = true
    },
    // 删除提示弹窗
    handleCancel () {
      this.popconfirmVisible = false
    },
    // 点击批量操作
    batchOperation () {
      this.popoverVisible = true
    },
    // 列表字段排序
    handleSort (pagination, filters, sorter) {
      // 0=限额，1=曝光量，2=点击量，3=点击率，4=千次展示均价，5=点击均价，6=花费
      const list = {
        budget: 0,
        impress: 1,
        click: 2,
        clickRate: 3,
        ecpm: 4,
        ecpc: 5,
        cost: 6
      }
      const status = {
        descend: 'desc', // 降序
        ascend: 'asc' // 升序
      }
      const { field, order } = sorter
      this.querData.sortOrder = status[order]
      this.querData.sortBy = list[field]
      this.getlist()
    },
    // 点击图标 编辑名称/限额
    editName (record, prop) {
      // prop ==> adPlanName === 名称  budget === 编辑限额
      this.placeInfo = record
      this.modificationType = prop
      this.advertisVisible = true
    },
    // 获取tablist
    async adPlanPagerFn (data) {
      const res = await adplanpagerList(data)
      this.loadings = true
      if (res.code === 200 && res.data) {
        const tempData = res.data.items || []
        tempData.length !== 0 &&
          tempData.forEach((item, index) => {
            item.showIcon = false
          })
        const obj = {
          type: 'total',
          adPlanName: '总计：' + res.data.total + '条记录',
          planId: Math.random()
        }
        this.data = [...[{ ...obj, ...res.data.aggregate }], ...tempData]
      }
      this.selectedRowKeys = [] // 多选清空
      this.total = res.data && res.data.total
      this.loadings = false
    },
    // 点击name 跳转到广告组
    goGroupName (record) {
      this.$router.push({
        path: `/advertisement/directpassenger?target=adgroup&planId=${record.planId}`
      })
    },
    // 点击开关
    handleCheckSts (record, checked) {
      const stss = record.sts === '1' ? 0 : 1
      const { planId = '' } = record
      this.updateAdPlanStsFn({ id: planId, sts: stss })
    },
    // 广告计划开关按钮修改
    async updateAdPlanStsFn (data) {
      const res = await updateAdplan(data)
      if (res.code === 200) {
        this.$message.success('修改成功')
        this.querData.sortOrder = ''
        this.querData.sortBy = ''
        this.getlist()
      }
    },
    // 列表编辑
    async handleEdit (record) {
      const routeUrl = this.$router.resolve({
        name: 'editadsteps',
        query: {
          steps: 1,
          adPlanId: record.planId
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    // 新建广告
    handleShowModel (type, record) {
      const routeUrl = this.$router.resolve({
        name: 'addadsteps',
        query: {
          steps: 1
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    // 选择时间
    handleChangeTime (date, dateString) {
      this.rangePickerDate = dateString
      const [dateStart, dateEnd] = dateString
      this.querData.dateStart = dateStart
      this.querData.dateEnd = dateEnd
    },
    // 搜索
    handleSerach () {
      this.querData.page = 1
      this.getlist()
    },
    // 分页
    handleCurrentPage (page) {
      this.querData.page = page
      this.getlist()
    },
    handleChangePageSize (current, size) {
      this.querData.page = 1
      this.querData.limit = size
      this.getlist()
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';
.m-t-20 {
  margin-top: 20px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-r-10 {
  margin-right: 10px;
}
.p-0 {
  padding: 0;
}
.pointer {
  cursor: pointer;
}
// .btnColor {
//   color: #333;
// }
::v-deep {
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background-color: fade(@primary-color, 10%);
  }
  .all_col .ant-table-selection-column span {
    display: none;
  }
  .ant-modal {
    top: 22%;
    .ant-modal-body {
      .batchModel {
        padding: 30px;
        display: flex;
        align-items: center;
      }
      .icon {
        color: #fa8b15;
        font-size: 22px;
        margin-right: 20px;
      }
      p {
        margin-bottom: 0;
        line-height: 24px;
      }
    }
    .ant-modal-footer {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      border-top: none;
      padding: 14px;
    }
    .ant-btn,
    .ant-btn-primary {
      width: 90px;
    }
  }
  .input[type='checkbox']:first-child {
    display: none;
  }
}
::v-deep {
  .btnHover {
    border: none;
    box-shadow: none;
    &:hover {
      color: @primary-color;
      font-weight: 600;
      // background-color: #ecefff;
      // border-radius: 16px;
    }
  }
  .el-btn[disabled] {
    background: #fff;
  }
  .m_table_cust table tbody tr:first-child {
    color: #999999;
    background: #fafafa;
  }
}
.advertisingPlan_container {
  padding: 20px;
  .search {
    .btn {
      background: fade(@primary-color, 10%);
      border: 1px solid @primary-color;
      color: @primary-color;
    }
  }
  .allianceSerachForm {
    display: flex;
    justify-content: space-between;
    position: relative;
    .statusBox {
      display: flex;
      flex-direction: row;
    }
  }
  .sonfig_tables {
    height: 100%;
    .action-slot{
      .btn{
        color: @primary-color;
        cursor: pointer;
      }
      .line{
        color: #ccc;
      }
      span{
        margin-right: 10px;
      }
    }
    .m_table_cust {
      th.is-leaf {
        border-bottom: none;
      }
    }
    .red {
      color: #f83366;
    }
    .green {
      color: #15ad31;
    }
    .grey {
      color: #999999;
    }
    .pagination {
      margin-top: 20px;
    }
  }
}
</style>
