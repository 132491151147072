<template>
  <a-modal
    v-model="platVisible"
    :title="(isAdd ? '添加' : '修改') + '广告平台'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="940"
    dialogClass="add_plat_modal"
  >
    <div class="add_plat_wrapper">
      <a-form-model ref="ruleForm" class="plat_form" :model="formquery" :rules="rules" v-bind="layout">
        <!-- 广告平台  accessType === 2 => 自定义广告平台  -->
        <template v-if="!isAdd && currentPlat.accessType === '2'"></template>
        <template v-else>
          <a-form-model-item
            label="广告平台"
            prop="platId"
            :rules="[{ required: true, message: '请输入广告平台', trigger: 'change' }]"
          >
            <a-select
              v-if="isAdd"
              placeholder="请选择广告平台"
              v-model="formquery.platId"
              @change="changePlat($event, 1)"
              :filter-option="filterOption"
              showSearch
            >
              <a-select-option v-for="item in platListWithin" :key="item.id" :value="item.id">{{
                item.name
              }}</a-select-option>
            </a-select>
            <span v-else>{{ formquery.platName }}</span>
          </a-form-model-item>
        </template>
        <!-- 广告平台名称 -->
        <a-form-model-item
          label="广告平台名称"
          prop="platName"
          v-if="currentPlat.id === '01234' || (currentPlat.accessType === '2' && !isAdd)"
          :rules="[{ required: true, message: '广告平台名称', trigger: 'blur' }]"
        >
          <a-input placeholder="请输入广告平台名称" :maxLength="maxPlatName" v-model.trim="formquery.platName">
            <span slot="suffix">{{ formquery.platName ? formquery.platName.length : 0 }}/{{ maxPlatName }}</span>
          </a-input>
        </a-form-model-item>
        <!-- 账户名称 -->
        <a-form-model-item
          label="账户名称"
          prop="name"
          :rules="[{ required: true, message: '账户名称', trigger: 'blur' }]"
        >
          <a-input placeholder="请输入名称以区分不同账号" :maxLength="30" v-model.trim="formquery.name">
            <span slot="suffix">{{ formquery.name ? formquery.name.length : 0 }}/30</span>
          </a-input>
        </a-form-model-item>
        <!-- 请求环境 -->
        <a-form-model-item
          label="请求环境"
          prop="isDebug"
          v-if="
            currentPlat.accessType !== '2' &&
              currentPlat.id !== '01234' &&
              (currentPlat.id === '5' || (+currentPlat.id >= 900 && currentPlat.id !== '989'))
          "
        >
          <a-radio-group name="radioGroup" v-model="formquery.isDebug">
            <a-radio value="1"> 测试 </a-radio>
            <a-radio value="0"> 正式 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <!-- 报表API -->
        <a-form-model-item label="报表API" prop="openApiStatus" v-if="showOpenApiStatus(currentPlat)">
          <a-radio-group name="radioGroup" v-model="formquery.openApiStatus">
            <a-radio :value="1"> 已开通 </a-radio>
            <a-radio :value="0"> 未开通 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <!-- 自动化创建广告源 -->
        <a-form-model-item prop="isAutoCreate" v-if="showIsAutoCreate(currentPlat.id)">
          <template slot="label">
            <m-tips
              v-if="+currentPlat.id !== 1061"
              :content="`开启自动创建广告源功能后，当您在${title}后台创建广告源时，${title}会自动在【${
                +currentPlat.id === 1
                  ? '穿山甲'
                  : +currentPlat.id === 2
                    ? '优量汇'
                    : +currentPlat.id === 3
                      ? '快手'
                      : +currentPlat.id === 6
                        ? '百度联盟'
                        : ''
              }】后台创建【${
                +currentPlat.id === 1
                  ? '代码位'
                  : +currentPlat.id === 2
                    ? '广告位'
                    : +currentPlat.id === 3
                      ? '广告位'
                      : +currentPlat.id === 6
                        ? '代码位'
                        : ''
              }】`"
              title="自动创建广告源"
              :width="200"
            ></m-tips>
            <!-- 百青藤 -->
            <template v-else>自动创建广告源</template>
          </template>
          <a-radio-group name="radioGroup" v-model="formquery.isAutoCreate">
            <a-radio :value="1"> 是 </a-radio>
            <a-radio :value="0"> 否 </a-radio>
          </a-radio-group>
          <a-alert
            v-if="+currentPlat.id === 1 && +formquery.isAutoCreate"
            style="font-size: 12px; margin-top: 10px; border-radius: 5px"
            message="️注意：使用穿山甲自动创建代码位功能，需要在穿山甲后台开通【应用代码位管理API】相关权限；"
            banner
          />
        </a-form-model-item>
        <AccountCommonForm v-model="formquery" :currentPlat="currentPlat"/>
        <a-form-model-item
          label="公钥"
          prop="signKey"
          v-if="+currentPlat.id === 6 && (+formquery.isAutoCreate === 1 || +formquery.openApiStatus === 1)"
        >
          <a-input disabled v-model.trim="formquery.signKey">
            <span @click="handleCopySignKey" v-if="!isAdd" slot="addonAfter" style="cursor: pointer;"> 复制 </span>
          </a-input>
          <a-alert
            style="font-size: 12px; margin-top: 10px; border-radius: 5px"
            message="️需要将公钥配置到百度联盟后台，若没有配置权限，请联系百度商务对接人开通。"
            banner
          />
        </a-form-model-item>
        <PlatAccoutForm :query="formquery" :platId="+currentPlat.id"></PlatAccoutForm>
        <!-- 状态 -->
        <a-form-model-item label="状态" prop="sts" v-if="!isAdd">
          <a-radio-group name="radioGroup" v-model="formquery.sts">
            <a-radio value="A"> 启动 </a-radio>
            <a-radio value="S"> 暂停 </a-radio>
          </a-radio-group>
          <a-alert
            v-if="formquery.sts === 'S'"
            message="暂停后，该广告平台下所有广告源状态都将关闭，再次启用时需要开发者把对应的广告源状态手动打开。因此请谨慎操作！"
            banner
          />
        </a-form-model-item>
        <SpecificPlatForm :labelSpan="labelSpan" :query="formquery" :platId="+currentPlat.id"></SpecificPlatForm>
        <PlatBQT1061 :isAdd="isAdd" :query="formquery" :platId="+currentPlat.id"></PlatBQT1061>
        <!-- Adapter类名 -->
        <a-form-model-item
          label="Adapter类名"
          v-if="currentPlat.id === '01234' || (currentPlat.accessType === '2' && !isAdd)"
        >
          <!-- Android -->
          <template>
            <span class="android_text">Android</span>
            <div class="android_box">
              <a-form-model-item has-feedback label="激励视频">
                <a-input v-model.trim="formquery.androidJlsp" placeholder="示例：com.mob.unit.ad.CustomAdapter" />
              </a-form-model-item>
              <a-form-model-item has-feedback label="插屏">
                <a-input v-model.trim="formquery.androidCp" placeholder="示例：com.mob.unit.ad.CustomAdapter" />
              </a-form-model-item>
              <a-form-model-item has-feedback label="横幅">
                <a-input v-model.trim="formquery.androidHf" placeholder="示例：com.mob.unit.ad.CustomAdapter" />
              </a-form-model-item>
              <a-form-model-item has-feedback label="原生">
                <a-input v-model.trim="formquery.androidYsmb" placeholder="示例：com.mob.unit.ad.CustomAdapter" />
              </a-form-model-item>
              <a-form-model-item has-feedback label="开屏">
                <a-input v-model.trim="formquery.androidKp" placeholder="示例：com.mob.unit.ad.CustomAdapter" />
              </a-form-model-item>
              <a-form-model-item has-feedback label="全屏视频">
                <a-input v-model.trim="formquery.androidQpsp" placeholder="示例：com.mob.unit.ad.CustomAdapter" />
              </a-form-model-item>
              <a-form-model-item has-feedback label="原生自渲染">
                <a-input v-model.trim="formquery.androidYszxr" placeholder="示例：com.mob.unit.ad.CustomAdapter" />
              </a-form-model-item>
            </div>
          </template>
          <!-- iOS -->
          <template>
            <span>iOS</span>
            <div class="ios_box">
              <a-form-model-item has-feedback label="激励视频">
                <a-input v-model.trim="formquery.iosJlsp" placeholder="示例：customAdapterClassName" />
              </a-form-model-item>
              <a-form-model-item has-feedback label="插屏">
                <a-input v-model.trim="formquery.iosCp" placeholder="示例：customAdapterClassName" />
              </a-form-model-item>
              <a-form-model-item has-feedback label="横幅">
                <a-input v-model.trim="formquery.iosHf" placeholder="示例：customAdapterClassName" />
              </a-form-model-item>
              <a-form-model-item has-feedback label="原生">
                <a-input v-model.trim="formquery.iosYsmb" placeholder="示例：customAdapterClassName" />
              </a-form-model-item>
              <a-form-model-item has-feedback label="开屏">
                <a-input v-model.trim="formquery.iosKp" placeholder="示例：customAdapterClassName" />
              </a-form-model-item>
              <a-form-model-item has-feedback label="全屏视频">
                <a-input v-model.trim="formquery.iosQpsp" placeholder="示例：customAdapterClassName" />
              </a-form-model-item>
              <a-form-model-item has-feedback label="原生自渲染">
                <a-input v-model.trim="formquery.iosYszxr" placeholder="示例：customAdapterClassName" />
              </a-form-model-item>
            </div>
          </template>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import {
  showIsAutoCreate,
  showOpenApiStatus
} from '@/utils/autoLabel'
import { mapState } from 'vuex'
import { copy } from '@/utils/string'
import { updatePlat, addPlat, addDefinedPlat } from '@/api/advertisement'
import PlatAccoutForm from '@/components/AccountModal/PlatForm'
import SpecificPlatForm from '@/components/AccountModal/SpecificPlatForm'
import PlatBQT1061 from '@/components/AccountModal/PlatBQT1061'
import AccountCommonForm from '@/components/AccountModal/CommonForm'
const labelSpan = 6
export default {
  components: { PlatAccoutForm, SpecificPlatForm, PlatBQT1061, AccountCommonForm },
  data () {
    return {
      labelSpan,
      layout: {
        labelCol: { span: labelSpan },
        wrapperCol: { span: 18 }
      },
      formquery: {
        platId: undefined,
        name: undefined,
        mid: undefined,
        keyId: undefined,
        signKey: undefined,
        isDebug: '0',
        requestUrl: undefined,
        openApiStatus: 1,
        accountId: undefined,
        accessKey: undefined,
        secretKey: undefined,
        sts: 'A',
        customPlatformName: undefined, // 广告平台名称
        plactAccountName: undefined, // 账号名称
        androidJlsp: undefined, // 安卓激励视频
        androidKp: undefined, // 安卓开屏
        androidCp: undefined, // 安卓插屏
        androidYsmb: undefined, // 安卓原生模板
        androidYszxr: undefined, // 安卓原生自渲染
        androidHf: undefined, // 安卓横幅
        androidQpsp: undefined, // 安卓全屏视频
        iosKp: undefined, // ios开屏
        iosCp: undefined, // ios插屏
        iosJlsp: undefined, // ios激励视频
        iosYsmb: undefined, // ios原生模板
        iosYszxr: undefined, // ios原生自渲染
        iosHf: undefined, // ios横幅
        iosQpsp: undefined, // ios全屏视频
        isAutoCreate: 0
      },
      currentPlat: {
        id: undefined
      },
      rules: {
        // os: [{ required: true, message: '请选择系统平台', trigger: 'change' }],
        // name: [{ required: true, message: '请输入应用名称', trigger: 'blur' }],
        // bundle: [{ required: true, message: '请输入应用包名', trigger: 'blur' }]
      },
      platListWithin: this.platList,
      maxPlatName: 30
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    platInfo: {
      default: () => ({}),
      type: Object
    },
    platList: {
      default: () => [],
      type: Array
    }
  },
  watch: {
    platInfo: {
      handler (val) {
        if (this.isAdd) return
        this.formquery = JSON.parse(JSON.stringify(val))
        this.formquery.isAutoCreate = +this.formquery.isAutoCreate
        this.changePlat(this.formquery.platId)
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    platVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title
    })
  },
  mounted () {
    // 下拉选项 添加自定义项
    const customObj = {
      name: '自定义广告平台',
      id: '01234'
    }
    this.platListWithin = [...this.platListWithin, customObj] || []
  },
  methods: {
    showIsAutoCreate,
    showOpenApiStatus,
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    changePlat (val, isSelected) {
      this.currentPlat = this.platListWithin.find((item) => {
        return item.id === val
      })
      !this.currentPlat &&
        (this.currentPlat = {
          name: '自定义广告平台',
          id: '01234'
        })
      this.$refs.ruleForm && this.$refs.ruleForm.clearValidate()
      this.formquery.isDebug = '0'
      isSelected && (this.formquery.openApiStatus = 0)
      // 修改时,跟随platInfo参数走
      if (!this.isAdd) this.formquery.isDebug = this.platInfo.isDebug
      if (+val === 1061) {
        // 百青藤处理
        this.$set(this.formquery, 'key', 'JSON')
        this.$set(this.formquery, 'encryptType', 'ECB')
        this.$set(this.formquery, 'encryptParameter', '128')
        this.$set(this.formquery, 'codingType', 'Base64')
      } else if (+val === 1083) {
        // 汇川处理
        this.$set(this.formquery, 'key', undefined)
        this.$set(this.formquery, 'encryptType', '1')
        this.$set(this.formquery, 'encryptParameter', '1')
        this.$set(this.formquery, 'codingType', undefined)
      } else {
        this.$set(this.formquery, 'key', undefined)
        this.$set(this.formquery, 'encryptType', undefined)
        this.$set(this.formquery, 'encryptParameter', undefined)
        this.$set(this.formquery, 'codingType', undefined)
      }
    },
    handleCancel () {
      this.platVisible = false
    },
    handleCopySignKey () {
      const result = copy(this.formquery.signKey)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (!this.isAdd) {
            const resp = await updatePlat(this.formquery)
            if (resp.code === 200) {
              this.$message.success('修改成功!')
              this.$emit('changePlat')
              this.platVisible = false
            }
          } else {
            /***
             * 新增广告平台 包括 已有平台和自定义
             * platId === '01234' 自定义广告平台
             * addDefinedPlat 自定义广告平台接口
             ***/
            const addDefined = this.formquery.platId === '01234' || false
            addDefined && delete this.formquery.platId
            const resp = addDefined ? await addDefinedPlat(this.formquery) : await addPlat(this.formquery)
            if (resp.code === 200) {
              this.$message.success('添加成功!')
              this.$emit('changePlat')
              this.platVisible = false
            }
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less">
.add_plat_modal .ant-modal-body {
  .add_plat_wrapper {
    height: 60vh;
    padding: 10px 100px 10px 10px;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 5px;
    border-left: 10px solid #fff;
    border-top: 10px solid #fff;
    border-bottom: 10px solid #fff;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      background: fade(@primary-color, 20%);
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
    .plat_form {
      padding: 20px;
      .android_text {
        font-weight: 600;
      }
      .android_box,
      .ios_box {
        background: #eee;
        border-radius: 5px;
        padding: 20px;
        .ant-form-item {
          margin-bottom: 20px;
          .ant-form-item-label {
            margin-right: 10px;
          }
        }
        .ant-form-item-control-wrapper {
          display: flex;
          .ant-form-item-control {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
