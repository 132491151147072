<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-16 15:20:57
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-18 18:43:16
 * @FilePath: /mediatom-web/src/views/advertisement/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="advertisement-container fixed_container">
    <a-menu v-model="current" mode="horizontal" class="menu_group fixed_menu" v-if="!otherPath.includes(current[0])">
      <!-- <a-menu-item key="/advertisement/alliance" v-if="allRoutes.includes('alliance')">
        <router-link :to="{ name: 'alliance' }">创建联盟广告</router-link>
      </a-menu-item> -->
      <a-menu-item key="/advertisement/directpassenger" v-if="allRoutes.includes('directpassenger')">
        <router-link :to="{ name: 'directpassenger' }">直投广告计划</router-link>
      </a-menu-item>
      <a-menu-item key="/advertisement/sonfig" v-if="allRoutes.includes('sonfig')">
        <router-link :to="{ name: 'sonfig' }">广告主管理</router-link>
      </a-menu-item>
    </a-menu>
    <route-view></route-view>
  </div>
</template>
<script>
import { RouteView } from '@/layouts'
import { mapState } from 'vuex'
export default {
  name: 'Advertisement',
  components: {
    RouteView
  },
  data () {
    return {
      current: '',
      otherPath: ['/advertisement/steps', '/advertisement/advertisingSource']
    }
  },
  computed: {
    ...mapState({
      // 权限路由集合
      allRoutes: (state) => state.permission.allRoutes
    })
  },
  watch: {
    $route: {
      handler (val) {
        const routes = val.matched.concat()
        this.current = [routes.pop().path]
      },
      immediate: true
    }
  }
}
</script>
<style lang="less" scoped>
.advertisement-container {
  // min-width: 1280px;
  position: relative;
}
::v-deep {
  .ant-menu-inline {
    border-right: none;
  }
}
</style>
