<template>
  <div class="advertising_container">
    <div class="advertising_top">
      <div
        v-for="(item, itemIndex) in advertisingTab"
        :key="item.key"
        :class="['top_item', current === item.key ? 'act' : '']"
        @click="handleClickMenu(item, itemIndex)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="advertising_bottom">
      <AdvertisingPlan v-if="current[0] === '1'" />
      <AdvertisingGroup v-if="current[0] === '2'" />
      <Advertisement v-if="current[0] === '3'" />
    </div>
  </div>
</template>
<script>
import AdvertisingPlan from './AdvertisingPlan'
import AdvertisingGroup from './AdvertisingGroup'
import Advertisement from './Advertisement'
export default {
  name: 'Advertising',
  components: { AdvertisingPlan, AdvertisingGroup, Advertisement },
  data () {
    return {
      current: '1',
      advertisingTab: [
        { name: '广告计划', key: '1' },
        { name: '广告组', key: '2' },
        { name: '广告', key: '3' }
      ]
    }
  },
  watch: {
    $route: {
      handler (val) {
        const query = val.query
        if (query.target === 'adplan') {
          this.current = '1'
        } else if (query.target === 'adgroup') {
          this.current = '2'
        } else if (query.target === 'ad') {
          this.current = '3'
        }
      },
      immediate: true
    }
  },
  created () {},
  methods: {
    handleClickMenu (item, itemIndex) {
      this.$router.push({
        name: 'directpassenger',
        query: {
          target: item.key === '1' ? 'adplan' : item.key === '2' ? 'adgroup' : item.key === '3' ? 'ad' : ''
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.text-right {
  text-align: right;
}
.m-b-0 {
  margin-bottom: 0px;
}
.m-t-20 {
  margin-top: 20px;
}

.m-r-10 {
  margin-right: 10px;
}
.p-0 {
  padding: 0;
}
.advertising_container {
  margin: 0 10px;
  border-radius: 5px;
  background: #fff;
  .advertising_top {
    user-select: none;
    padding: 20px;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    flex-direction: row;
    .top_item {
      font-size: 14px;
      color: #333333;
      margin: 8px 8px 0;
      padding: 0 14px;
      border-radius: 5px;
      height: 30px;
      line-height: 30px;
      cursor: pointer;
    }
    .act {
      color: @primary-color;
      background: fade(@primary-color, 10%);
    }
  }
  .advertising_bottom {
    min-height: 70vh;
  }
}
</style>
