<template>
  <div class="ad-steps-container">
    <leftStepVue />
    <div class="right-steps-wrapper">
      <div class="right-steps-content">
        <a-spin :spinning="isLoading" style="width: 100%; height: 100%; box-sizing: border-box">
          <StepAdPlan @handleIsSelect="handleIsSelect" ref="StepAdPlan" />
          <StepAdGroup v-if="steps > 1" @handleIsSelect="handleIsSelect" ref="StepAdGroup" />
          <StepAdCreative v-if="steps === 3" ref="StepAdCreative" />
        </a-spin>
      </div>
      <div class="right-steps-footer">
        <a-button @click="handleCencel">取消</a-button>
        <!-- 非选择已有按钮 -->
        <span v-if="!isSelect">
          <!-- 添加按钮 -->
          <span v-if="isAdd">
            <a-button
              @click="onlyAdd"
              v-if="(steps === 1 || steps === 2) && isAdd"
            >仅创建{{ steps === 1 ? '广告计划' : steps === 2 ? '广告组' : steps === 3 ? '广告' : '' }}</a-button
            >
            <a-button
              type="primary"
              @click="handleSubmitAndNext"
            >保存{{ steps === 1 || steps === 2 ? '并继续' : '' }}</a-button
            >
          </span>
          <!-- 编辑按钮 -->
          <span v-else>
            <a-button type="primary" @click="UpdateSubmit">保存</a-button>
          </span>
        </span>
        <!-- 选择已有按钮 -->
        <span v-else>
          <a-button type="primary" @click="handleNext">下一步</a-button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import leftStepVue from './components/leftStep.vue'
import StepAdPlan from './components/steps/stepAdPlan'
import StepAdGroup from './components/steps/stepAdGroup'
import StepAdCreative from './components/steps/stepAdCreative'
export default {
  components: { leftStepVue, StepAdPlan, StepAdGroup, StepAdCreative },
  provide () {
    return {
      currentAdPlanId: () => {
        return this.currentAdPlanId
      },
      changePlanId: (id) => {
        this.currentAdPlanId = id
      },
      currentAdGroupId: () => {
        return this.currentAdGroupId
      },
      changeGroupId: (id) => {
        this.currentAdGroupId = id
      },
      changeLoading: (sts) => {
        this.isLoading = sts
      }
    }
  },
  data () {
    return {
      steps: 1,
      isLoading: false,
      isAdd: false,
      isSelect: false,
      currentAdPlanId: undefined,
      currentAdGroupId: undefined
    }
  },
  mounted () {
    if (this.isAdd && !this.currentAdPlanId && this.steps !== 1) {
      this.$router.push({
        name: 'addadsteps',
        query: {
          steps: 1
        }
      })
    }
  },
  watch: {
    $route: {
      handler (val) {
        this.steps = val && val.query && +val.query.steps
        this.isAdd = val.name === 'addadsteps'
        this.currentAdPlanId = val && val.query && val.query.adPlanId
        this.currentAdGroupId = val && val.query && val.query.adGroupId
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async onlyAdd () {
      const resp = await this.handleSubmit()
      if (resp.code && resp.code !== 200) {
        return
      }
      if (this.steps) {
        this.$router.push({
          path: '/advertisement/directpassenger',
          query: {
            target: this.steps === 1 ? 'adplan' : this.steps === 2 ? 'adgroup' : this.steps === 3 ? 'ad' : ''
          }
        })
      }
    },
    // isAdd 保存并继续
    async handleSubmitAndNext () {
      const resp = await this.handleSubmit()
      if (resp.code && resp.code !== 200) {
        return
      }
      //  下一步骤
      this.handleNext()
    },
    // !isAdd 保存
    async UpdateSubmit () {
      const resp = await this.handleSubmit()
      if (resp.code && resp.code !== 200) {
        return
      }
      this.$message.success('操作成功！')
      if (this.steps) {
        this.$router.push({
          path: '/advertisement/directpassenger',
          query: {
            target: this.steps === 1 ? 'adplan' : this.steps === 2 ? 'adgroup' : this.steps === 3 ? 'ad' : ''
          }
        })
      }
    },
    // 提交方法
    async handleSubmit () {
      if (this.steps === 1) {
        const res = await this.$refs.StepAdPlan.handleSubmitAdPlan()
        return res
      } else if (this.steps === 2) {
        const res = await this.$refs.StepAdGroup.handleSubmitAdGroup()
        return res
      } else if (this.steps === 3) {
        const res = await this.$refs.StepAdCreative.handleSubmitAdGroup()
        return res
      }
    },
    // 下一步骤
    handleNext () {
      if (this.steps === 1) {
        if (!this.$refs.StepAdPlan.selectPlanId) {
          this.$message.error('请选择广告计划')
          return
        }
        this.currentAdPlanId = this.$refs.StepAdPlan.selectPlanId
        this.isSelect = false
        this.$router.push({
          name: 'addadsteps',
          query: {
            steps: 2
          }
        })
      } else if (this.steps === 2) {
        if (!this.$refs.StepAdGroup.selectGroupId) {
          this.$message.error('请选择广告组')
          return
        }
        this.isSelect = false
        this.$router.push({
          name: 'addadsteps',
          query: {
            steps: 3
          }
        })
      } else if (this.steps === 3) {
        if (this.steps) {
          this.$router.push({
            path: '/advertisement/directpassenger',
            query: {
              target: 'ad'
            }
          })
        }
      }
    },
    // 是否是选择已有tab状态
    handleIsSelect (e) {
      this.isSelect = e
    },
    handleCencel () {
      this.$router.push({
        path: '/advertisement/directpassenger',
        query: {
          target: this.steps === 1 ? 'adplan' : this.steps === 2 ? 'adgroup' : this.steps === 3 ? 'ad' : ''
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ad-steps-container {
  width: calc(100% - 20px);
  // min-width: 1280px;
  margin: 0 auto;
  min-height: 84vh;
  display: flex;
  box-sizing: border-box;
  .right-steps-wrapper {
    flex-grow: 1;
    width: 0;
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
    .right-steps-content {
      padding: 20px 50px;
      flex-grow: 1;
      height: 0;
      position: relative;
      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: auto;
    }
    .right-steps-footer {
      height: 60px;
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #ddd;
      button {
        margin-right: 10px;
      }
    }
  }
}
</style>
